import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import TablePagination from '@mui/material/TablePagination';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './ProductManagement.module.scss';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Modal from 'react-modal';
import HoverableList from '../../components/HoverableList';
import LoadingButtonDots from '../../components/LoadingButtonDots';
import LoadingSpinner from '../../components/LoadingSpinner'; 

function ProductManagement() {
    useEffect(() => {
        document.title = 'Products | Qiwi';
      }, []);

      
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(0);
    const [selectedStoreToPublish, setSelectedStoreToPublish] = useState();
    const [showStorePublish, setShowStorePublish] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageInfo, setPageInfo] = useState({ next: {}, previous: {}, explored: [] });
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProductCount, setTotalProductCount] = useState(0);
    const [productTypes, setProductTypes] = useState('qiwi');
    const [direction, setDirection] = useState('');
    const [activeCardId, setActiveCardId] = useState(null);
    const [showArchived, setShowArchived] = useState(false);
    const [noActiveProducts, setNoActiveProducts] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [productImageStates, setProductImageStates] = useState({});
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [isSupplyInfoModalOpen, setIsSupplyInfoModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [allowModal, setAllowModal] = useState(true);
    const [publishing, setPublishing] = useState(false);  // Ny state för att spåra publicering
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false); // Loading state för remove-knappen
    const [productToDeleteOrders, setProductToDeleteOrders] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false); // Loading state for modal
    const [loadingExpire, setLoadingExpire] = useState(false); // Loading state for expire button

    const deleteProductModal = async (product) => {
        try {
            setLoadingModal(true); // Starta laddningsindikator för modalen
            const orders = await merchantService.productDeleteStatus(product.id);
            setProductToDeleteOrders(orders.orders);
            setIsRemoveModalOpen(true);
        } catch (error) {
            console.error('Failed to fetch product delete status:', error);
        } finally {
            setLoadingModal(false); // Avsluta laddningsindikator för modalen
        }
    };

    const deleteProduct = async () => {
        const confirmed = window.confirm("Are you sure you want to delete this product? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingRemove(true); // Starta laddningsindikator för delete-knappen
                await merchantService.deleteProduct(selectedProduct.id);
                alert("Product deleted successfully.");
                fetchProducts(); // Uppdatera produktlistan
                setIsRemoveModalOpen(false); // Stäng modalen
            } catch (error) {
                console.error('Failed to delete product:', error);
                alert('Failed to delete product');
            } finally {
                setLoadingRemove(false); // Avsluta laddningsindikator för delete-knappen
            }
        }
    };

    const expireProduct = async () => {
        const confirmed = window.confirm("Are you sure you want to set this product as expiring? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true); // Starta laddningsindikator för expiring-knappen
                await merchantService.expireProduct(selectedProduct.id);
                alert("Product set as expiring successfully.");
                fetchProducts(); // Uppdatera produktlistan
                setIsRemoveModalOpen(false); // Stäng modalen
            } catch (error) {
                console.error('Failed to set product as expiring:', error);
                alert('Failed to set product as expiring');
            } finally {
                setLoadingExpire(false); // Avsluta laddningsindikator för expiring-knappen
            }
        }
    };    

    const handleVariantSelection = (variantId) => {
        setSelectedVariants(prevState => {
            if (prevState.includes(variantId)) {
                return prevState.filter(item => item !== variantId);
            } else {
                return [...prevState, variantId];
            }
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            products.forEach(product => {
                const variantsWithImages = product.variants ? product.variants.filter(variant => variant.image) : [];
                if (variantsWithImages.length > 0 && !productImageStates[product.id]?.isPaused && activeCardId !== product.id) {
                    handleNextImage(product.id, variantsWithImages);
                }
            });
        }, 10000); // Byt bild var 10:e sekund
    
        return () => clearInterval(interval); // Rensa intervallet när komponenten demonteras
    }, [products, productImageStates, activeCardId]);

    const fetchProducts = async () => {
        try {
            setActiveCardId(false);
            setLoading(true); // Start loading
    
            let response;
            if (productTypes === 'qiwi' || productTypes === 'archived') {
                response = await merchantService.fetchUserProductsWithFilterAndPagination(currentPage, itemsPerPage, selectedStore, showArchived);
            } else {
                const formattedPageInfo = JSON.stringify(pageInfo);
                response = await merchantService.fetchAllShopifyProductsWithFilterAndPagination(selectedStore, itemsPerPage, formattedPageInfo, direction);
    
                const { next, previous, explored } = response.pageInfo;
                setPageInfo({ next, previous, explored });
    
                if (response.totalProductCount) {
                    setTotalProductCount(response.totalProductCount);
                }
            }
            if (initialLoad) {
                if (response.products.length === 0) {
                    setNoActiveProducts(true);
                } else {
                    setNoActiveProducts(false);
                }
                setInitialLoad(false);
            }
            setProducts(response.products);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch products', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setSelectedStoreToPublish(null);
        setIsPublishModalOpen(false);
        setIsSupplyInfoModalOpen(false);
        setSelectedVariants([]);
        setPageInfo({ next: {}, previous: {}, explored: [] });
        setTotalProductCount(0);

        if (currentPage !== 1) {
            setCurrentPage(1); // Om vi inte är på sida 1, ändra sidan och vänta på nästa useEffect
        } else {
            fetchProducts(); // Om vi redan är på sida 1, anropa fetchProducts direkt
        }
    }, [productTypes, selectedStore]);

    useEffect(() => {
        fetchProducts();
    }, [currentPage, itemsPerPage]);

    const fetchStores = async () => {
        try {
            const storesResponse = await merchantService.getStores();
            setStores(storesResponse);
        } catch (error) {
            console.error("Error fetching stores: ", error);
        }
    };

    useEffect(() => {
        fetchStores();
    }, []);

    useEffect(() => {
        if (selectedStoreToPublish !== undefined) {
        }
    }, [selectedStoreToPublish]);

    const handleChangePage = (event, newPage) => {
        if (productTypes === 'shopify') {
            if (newPage + 1 > currentPage) {
                setDirection("next");
            } else {
                setDirection("previous");
            }
        }
        setCurrentPage(newPage + 1);
    };

    const handleStoreChange = (event) => {
        setSelectedStore(event.target.value);
        setPageInfo({ next: {}, previous: {}, explored: [] });
        setCurrentPage(1);
    };

    const handleSelectedStoreChange = (event) => {
        setSelectedStoreToPublish(event.target.value);
    };

    const toggleCard = (id) => {
        setActiveCardId(prevId => {
            if (prevId === id) {
                // Om samma kort klickas igen, avaktivera det
                pauseCarousel(id, false); // Avpausa karusellen
                return null;
            } else {
                // Om ett annat kort klickas, avaktivera det tidigare kortet och aktivera det nya
                if (prevId !== null) {
                    pauseCarousel(prevId, false); // Avpausa föregående aktiva korts karusell
                }
                pauseCarousel(id, true); // Pausa nya aktiva kortets karusell
                setSelectedProduct(products.find(p => p.id === id) || null);
                setSelectedStoreToPublish(null); // Återställ publikationsval
                setShowStorePublish(null); // Återställ publiceringsvyn
                return id;
            }
        });
    };

    const togglePage = (value) => {
        setSelectedProduct(null);
        setActiveCardId(null);
        setProductTypes(value);
        setInitialLoad(true);
        if (value === 'qiwi') {
            setShowArchived(false);
            setAllowModal(true);
        } else if (value === 'archived') {
            setShowArchived(true);
            setAllowModal(true);
        } else {
            setAllowModal(false);
        }
    };

    const getProductInfo = (product) => {
        let productInfoContent = [];

        if (product.supplier) {
            productInfoContent.push(<p key="supplier">Supplier: {product.supplier}</p>);
        }
    
        if (product.Stores && product.Stores.length > 0) {
            productInfoContent.push(
                <div key="stores" className={styles.storeInfo}>
                    Store: &nbsp;
                    <HoverableList list={
                        (product.Stores || []).map(store => {
                            const storeName = store.shop.replace('.myshopify.com', '');
                            return storeName + (store.expiring && !product.expiring ? ' (Expiring)' : '');
                        })
                    } wantRight={true}>
                    </HoverableList>
                </div>
            );
        }
    
        if (product.variants && product.variants.length > 0 && product.variants[0].title !== "Default Title") {
            productInfoContent.push(
            <div key="variants">Total variants:&nbsp; 
            <HoverableList 
                list={
                    product.variants.map(variant => 
                        variant.categoryVariants && variant.categoryVariants.length > 0 
                        ? variant.categoryVariants.map(cv => cv.value).join(' / ')
                        : variant.title
                    )
                }
            />
            </div>);
        }
    
        if (productTypes === 'qiwi') {
            return (
                <>
                    <div className={styles.productInfoContainer}>
                        {productInfoContent}
                        <button className={styles.supplyInfo} onClick={() => {
                            setIsSupplyInfoModalOpen(true);
                        }}>Information</button>
                    </div>
                    {!product.expiring ?
                        <div className={styles.productButtons}>
                            <button onClick={() => deleteProductModal(product)}>Remove</button>
                            <button onClick={() => {
                                setIsPublishModalOpen(true);
                            }}>Add to Store</button>
                        </div>
                        :
                    <div className={styles.expiringProduct}><span className="material-symbols-outlined">warning</span>Product expiring</div>
                    }
                </>
            );
        }
        return <div className={styles.productInfo}>
            <div className={styles.productInfoContainer}>
            {productInfoContent}
            </div>
            </div>
    }

    const getImages = (product) => {
        const productState = productImageStates[product.id] || { index: 0, isPaused: false };
        const currentImageIndex = productState.index;

        if (product.noVariant && product.noVariant.image) {
            return <img src={product.noVariant.image} alt={product.name} className={styles.productImage} />;
        }

        const uniqueImages = new Set();
        const variantsWithImages = product.variants ? product.variants.filter(variant => {
            if (variant.image && !uniqueImages.has(variant.image)) {
                uniqueImages.add(variant.image);
                return true;
            }
            return false;
        }) : [];
    
        if (variantsWithImages.length === 0) {
            if (product.image) {
                return <img src={product.image} alt={product.name} className={styles.productImage} />;
            }
            return <img src="https://seetruetechnology.com/wp-content/uploads/2022/02/BG-7.jpg" alt="No image available" className={styles.productImage} />;
        }
    
        if (variantsWithImages.length === 1) {
            return <img src={variantsWithImages[0].image} alt={product.name} className={styles.productImage} />;
        }
    
        const currentImage = variantsWithImages[currentImageIndex] ? variantsWithImages[currentImageIndex].image : variantsWithImages[0].image;
        const imageUrl = currentImage;
    
        return (
            <div className={styles.imageCarousel}>
                <img src={imageUrl} alt={`Variant ${currentImageIndex}`} className={styles.productImage} />
                <button onClick={() => { pauseCarousel(product.id); handlePrevImage(product.id, variantsWithImages); }} className={styles.prevButton}>{"<"}</button>
                <button onClick={() => { pauseCarousel(product.id); handleNextImage(product.id, variantsWithImages); }} className={styles.nextButton}>{">"}</button>
            </div>
        );
    };
    
    const handleNextImage = (productId, variantsWithImages) => {
        if (!variantsWithImages || variantsWithImages.length === 0) return;
    
        setProductImageStates((prevState) => {
            const currentIndex = prevState[productId]?.index || 0;
            const newIndex = (currentIndex + 1) % variantsWithImages.length;
            return {
                ...prevState,
                [productId]: { index: newIndex, isPaused: prevState[productId]?.isPaused }
            };
        });
    };
    
    const handlePrevImage = (productId, variantsWithImages) => {
        if (!variantsWithImages || variantsWithImages.length === 0) return;
    
        setProductImageStates((prevState) => {
            const currentIndex = prevState[productId]?.index || 0;
            const newIndex = (currentIndex - 1 + variantsWithImages.length) % variantsWithImages.length;
            return {
                ...prevState,
                [productId]: { index: newIndex, isPaused: prevState[productId]?.isPaused }
            };
        });
    };
    
    const pauseCarousel = (productId, shouldPause = true) => {
        setProductImageStates((prevState) => ({
            ...prevState,
            [productId]: { ...prevState[productId], isPaused: shouldPause }
        }));
    
        if (shouldPause) {
            setTimeout(() => {
                setProductImageStates((prevState) => ({
                    ...prevState,
                    [productId]: { ...prevState[productId], isPaused: false }
                }));
            }, 30000);
        }
    };
    

    const publishNoVariant = async (productId, storeId) => {
        try {
            const storeToUse = storeId || selectedStoreToPublish;
            if (!storeToUse) {
                console.error('No store selected for publishing.');
                return;
            }

            setPublishing(true);  // Starta publiceringsladdning
            await merchantService.publishNoVariantProduct(null, productId, storeToUse);
            fetchProducts();
            setIsPublishModalOpen(false);

        } catch (error) {
            console.error('Failed to publish no variant product:', error);
        } finally {
            setPublishing(false);  // Avsluta publiceringsladdning
        }
    };

    const publishWithVariants = async (productId, storeId, variantIds) => {
        try {
            const storeToUse = storeId || selectedStoreToPublish;
            setPublishing(true);  // Starta publiceringsladdning
            await merchantService.publishProductWithVariants(null, productId, storeToUse, variantIds);
            fetchProducts();
            setIsPublishModalOpen(false);
        } catch (error) {
            console.error('Failed to publish product with variants:', error);
        } finally {
            setPublishing(false);  // Avsluta publiceringsladdning
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Product Management</h1>
            </div>
            <div className={styles.productOption}>
                <a onClick={() => togglePage('qiwi')} className={productTypes === 'qiwi' ? styles.active : ''}>
                    My qiwi products
                </a>
                <a onClick={() => togglePage('shopify')} className={productTypes === 'shopify' ? styles.active : ''}>
                    My shopify products
                </a>
                <a onClick={() => togglePage('archived')} className={productTypes === 'archived' ? styles.active : ''}>
                    My archived qiwi products
                </a>
            </div>
            <div className={styles.storeSelect}>
                <FormControl variant="outlined" className={styles.storeSelect} size="small" disabled={noActiveProducts}>
                    <InputLabel>Store</InputLabel>
                    <Select value={selectedStore} onChange={handleStoreChange} label="Store">
                        <MenuItem value={0}>All</MenuItem>
                        {stores.map((store) => (
                            <MenuItem key={store.id} value={store.id}>
                                {store.shop.replace('.myshopify.com', '')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {loading ? 
            <div className={styles.placeHolderContainer}>
            {Array.from({ length: itemsPerPage }).map((_, index) => (
                <div key={index} className={`${styles.shimmer} ${styles.cardContainer}`}>
                </div>
            ))}
            </div>
            :  
            !products.length > 0 ? (
            loading ? 
            <div className={styles.placeHolderContainer}>
            {Array.from({ length: itemsPerPage }).map((_, index) => (
                <div key={index} className={`${styles.shimmer} ${styles.cardContainer}`}>
                </div>
            ))}
            </div>
            :
            <p className={styles.noActiveProducts}>
            No {productTypes === 'qiwi' ? "active" : productTypes === 'archived' ? "archived" : "shopify"} products found {!noActiveProducts && ", check filtering"}
            </p>
            ) : (
                <>
                    <div className={styles.gridContainer}>
                        {products.map((product, index) => (
                            <div key={product.id} className={`${styles.cardContainer} ${activeCardId === product.id ? styles.activeCard : ''}`}>
                                <div className={styles.card}>
                                    <div className={styles.imgContainer}>
                                        {getImages(product)}
                                    </div>
                                </div>
                                <div className={`${styles.cardContent} ${showStorePublish === product.id ? styles.storePublishcontainer : ''}`}>
                                    <div className={styles.contentHeader}>
                                        <h2>{product.name}</h2>
                                        {activeCardId === product.id ? (
                                            <span className="material-symbols-outlined" onClick={() => toggleCard(null)}>
                                                keyboard_arrow_down
                                            </span>
                                        ) : (
                                            <span className="material-symbols-outlined" onClick={() => toggleCard(product.id)}>
                                                keyboard_arrow_up
                                            </span>
                                        )}
                                    </div>
                                    {getProductInfo(product)}
                                </div>
                            </div>
                        ))}
                    </div>
                    {productTypes === 'qiwi' || productTypes === 'archived' ? (
                        <PaginationComponent
                            itemsPerPage={itemsPerPage}
                            setItemsPerPage={setItemsPerPage}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handlePageChange={handleChangePage}
                            
                        />
                    ) : (
                        <div className={styles.pags}>
<TablePagination
    component="div"
    count={totalProductCount}
    page={currentPage - 1}
    onPageChange={handleChangePage}
    rowsPerPage={itemsPerPage}
    onRowsPerPageChange={() => {}} // 
    rowsPerPageOptions={[]}
    labelRowsPerPage="" 
    disabled={itemsPerPage === 10 && totalPages === 1}
/>


                        </div>
                    )}
                </>
            )}

{allowModal &&
<>
<Modal
    isOpen={isPublishModalOpen}
    onRequestClose={() => setIsPublishModalOpen(false)}
    contentLabel="Publish to Store"
    className={styles.modal}
    overlayClassName={styles.overlay}
>
    <h2>Publish to Store</h2>
    {selectedProduct && (
    <div>
        {stores.length === 0 ? (
            <div>
                <p>No stores available. Please add a store first to publish the product.</p>
                <a href="./store-management">Go to Store Management</a>
            </div>
        ) : (
            <>
                <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel>Choose Store</InputLabel>
                    <Select
                        label="Choose Store"
                        value={selectedStoreToPublish || ""}
                        onChange={handleSelectedStoreChange}
                    >
                        {stores.map((store, index) => {
                            const isDisabled = selectedProduct?.Stores?.some(pStore => pStore.id === store.id);
                            return (
                                <MenuItem 
                                    key={index} 
                                    value={store.id}
                                    disabled={isDisabled}
                                >
                                    {store.shop.replace('.myshopify.com', '')} {isDisabled ? "(Already Published)" : ""}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {selectedProduct?.variants && selectedProduct.variants.length > 0 && (
                    <div className={styles.variantsList}>
                        {selectedProduct.variants.map((variant, index) => (
    <div key={index} className={styles.variantRow}>
        <label>
            <input 
                type="checkbox" 
                value={variant.id} 
                onChange={() => handleVariantSelection(variant.id)} 
            />
            {variant.SKU} - {(variant.categoryVariants || []).map((cv) => (
                cv.value
            )).join('/ ')}
        </label>
    </div>
))}

                    </div>
                )}
                <div className={styles.buttons}>
                    {selectedProduct?.variants && selectedProduct.variants.length > 0 ? (
                        <button 
                            onClick={() => publishWithVariants(selectedProduct.id, selectedStoreToPublish, selectedVariants)} 
                            className={styles.publishButton}
                            disabled={!selectedStoreToPublish || selectedVariants.length === 0}
                        >
                            {publishing ? <LoadingButtonDots /> : 'Publish Selected To Store'}
                        </button>
                    ) : (
                        <button 
                            onClick={() => publishNoVariant(selectedProduct.id, selectedStoreToPublish)} 
                            className={styles.publishButton}
                            disabled={!selectedStoreToPublish}
                        >
                            {publishing ? <LoadingButtonDots /> : 'Publish To Store'}
                        </button>
                    )}
                    <button onClick={() => setIsPublishModalOpen(false)} className={styles.closeButton}>Close</button>
                </div>
            </>
        )}
    </div>
)}

</Modal>
<Modal
    isOpen={isSupplyInfoModalOpen}
    onRequestClose={() => setIsSupplyInfoModalOpen(false)}
    contentLabel="Supply Information"
    className={styles.modal}
    overlayClassName={styles.overlay}
>
    <h2>Supply Information</h2>
    {selectedProduct && (
        <div className={styles.supplyInfoContent}>
            {selectedProduct.noVariant ? (
                <table>
                    <thead>
                        <tr className={styles.table_header}>
                            <th>SKU</th>
                            <th>Product Price</th>
                            <th>Shipping Price</th>
                            <th>Shipping Time</th>
                            <th>Processing Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={styles.rows}>
                            <td>{selectedProduct.noVariant.SKU}</td>
                            <td>${selectedProduct.noVariant.productPrice}</td>
                            <td>${selectedProduct.noVariant.shippingPrice}</td>
                            <td>{selectedProduct.noVariant.shippingTime} days</td>
                            <td>{selectedProduct.noVariant.processingTime} days</td>
                        </tr>
                    </tbody>
                </table>
            ) : selectedProduct.variants && selectedProduct.variants.length > 0 ? (
                <table>
                    <thead>
                        <tr className={styles.table_header}>
                            <th>SKU</th>
                            <th>Product Price</th>
                            <th>Shipping Price</th>
                            <th>Shipping Time</th>
                            <th>Processing Time</th>
                            {Array.from(new Set(selectedProduct.variants.flatMap(variant =>
                                variant.categoryVariants.map(cv => cv.category)
                            ))).map((category, idx) => (
                                <th key={idx}>{category}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {selectedProduct.variants.map((variant, index) => (
                            <tr key={index} className={styles.rows}>
                                <td>{variant.SKU}</td>
                                <td>${variant.productPrice}</td>
                                <td>${variant.shippingPrice}</td>
                                <td>{variant.shippingTime} days</td>
                                <td>{variant.processingTime} days</td>
                                {Array.from(new Set(selectedProduct.variants.flatMap(variant =>
                                    variant.categoryVariants.map(cv => cv.category)
                                ))).map((category, idx) => {
                                    const quotationCategoryVariant = variant.categoryVariants.find(cv => cv.category === category);
                                    return <td key={idx}>{quotationCategoryVariant ? quotationCategoryVariant.value : ''}</td>;
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>No supply information available.</div>
            )}
            <button onClick={() => setIsSupplyInfoModalOpen(false)} className={styles.closeButton}>Close</button>
        </div>
    )}
</Modal>
<Modal
    isOpen={isRemoveModalOpen}
    onRequestClose={() => setIsRemoveModalOpen(false)}
    contentLabel="Delete Product"
    className={styles.modaltwo}
    overlayClassName={styles.overlay}
>
    <h2>Deleting Product{selectedProduct && `: ${selectedProduct.name}`}</h2>
    {loadingModal ? (
        <div className={styles.spinnerModal}><LoadingSpinner /></div> 
    ) : productToDeleteOrders.length > 0 ? (
        <>
            <div className={styles.warningMessage}>
                <span className="material-symbols-outlined">warning</span>
                <p><strong>OBS. There are existing orders pending for this product. Your product will be expiring until fulfilled.</strong></p>
            </div>
            <p>When you delete this product, it will not be automatically removed from your Shopify store. Please ensure you manually set it to "Draft" or remove it from your store.</p>
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {productToDeleteOrders.map(order => (
                        <tr key={order.orderId}>
                            <td>{order.orderId}</td>
                            <td>{order.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={styles.buttons}>
            <button onClick={() => setIsRemoveModalOpen(false)} className={styles.closeButton}>Close</button>
            <button 
                className={styles.expiringButton} 
                onClick={expireProduct} 
                disabled={loadingExpire}
            >
                {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
            </button>
            </div>
        </>
    ) : (
        <>
            <p><strong>Are you sure you want to delete this product? This action cannot be undone.</strong></p>
            <p>When you delete this product, it will not be automatically removed from your Shopify store. Please ensure you manually set it to "Draft" or remove it from your store.</p>
            <div className={styles.buttons}>
            <button onClick={() => setIsRemoveModalOpen(false)} className={styles.closeButton}>Close</button>
            <button 
                className={styles.deleteButtonModal} 
                onClick={deleteProduct} 
                disabled={loadingRemove}
            >
                {loadingRemove ? <LoadingButtonDots /> : 'Yes, delete this product'}
            </button>
            </div>
        </>
    )}
</Modal>

</>}

        </div>
    );
}

export default ProductManagement;
