import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Payments.module.scss';
import Modal from 'react-modal';

const Payments = () => {
    const [invoices, setInvoices] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("Awaiting Payment Confirmation");
    const [dateRange, setDateRange] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [showArchived, setShowArchived] = useState(false);


    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Awaiting Payment', label: 'Awaiting Payment' },
        { value: 'Awaiting Payment Confirmation', label: 'Awaiting Payment Confirmation' },
        { value: 'Paid', label: 'Paid' },
        { value: 'Denied', label: 'Denied' }
    ];

    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setCurrentPage(1);
    };


    const handleInvoiceClick = async (invoiceId) => {
        try {
            const details = await adminService.fetchInvoiceDetails(invoiceId, showArchived);
            setInvoiceDetails(details);
            setSelectedInvoice(invoiceId);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching invoice details:', error);
        }
    };


    const fetchInvoices = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchAllInvoicesByPageAndFilters(statusFilter, currentPage, itemsPerPage, startDate, endDate, searchQuery);
            setInvoices(response.invoices || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setInvoices([]);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, [currentPage, itemsPerPage]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...invoices].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setInvoices(sortedData);
    };


    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (filter) => {
        setStatusFilter(filter);
        setCurrentPage(1); // Reset to first page when filter changes
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedInvoice(null);
        setInvoiceDetails({});
    };

    const handleConfirm = async (invoiceId) => {
        try {
            await adminService.confirmPayment(invoiceId);
            fetchInvoices();  // Uppdatera data efter bekräftelse
        } catch (error) {
            console.error("Error confirming payment: ", error);
        }
    };

    const handleDeny = async (invoiceId) => {
        try {
            await adminService.denyPayment(invoiceId);
            fetchInvoices();  // Uppdatera data efter nekande
        } catch (error) {
            console.error("Error denying payment: ", error);
        }
    };


    const applyFilters = () => {
        fetchInvoices();
    };

    return (
        <div className={styles.container}>
                        <div className={styles.header}>
            <h1>Payments</h1>
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent
                    backgroundColor='white'
                    statusFilter={statusFilter}
                    setSelectFilter={handleFilterChange}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    statusOptions={statusOptions}
                    searchPlaceholder="Search by invoice ID..."
                    includeSearch={true}
                    selectLabel="Status"
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                    applyFilters={applyFilters}
                />
            </div>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('invoiceId')}>
                                <div className={styles.thHeader}>
                                    Invoice ID
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'invoiceId' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'invoiceId' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Date Issued
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('totalAmount')}>
                                <div className={styles.thHeader}>
                                    Total Amount
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'totalAmount' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'totalAmount' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('status')}>
                                <div className={styles.thHeader}>
                                    Status
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'status' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'status' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Transaction Proof</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map(invoice => (
                            <tr className={styles.rows} key={invoice.invoiceId}>
                                <td>
                                    {invoice.invoiceId}
                                </td>
                                <td>{new Date(invoice.createdAt).toLocaleDateString()}</td>
                                <td>${invoice.totalAmount.toFixed(2)}</td>
                                <td>{invoice.status}</td>
                                <td>
                                    {invoice.transactionProof && (
                                        <a href={adminService.getTransactionProof(invoice.transactionProof)} target="_blank" rel="noopener noreferrer">
                                            View PDF
                                        </a>
                                    )}
                                </td>
                                <td>
                                        <div className={styles.buttons}>
                                            <button onClick={() => handleInvoiceClick(invoice.invoiceId)} className={styles.detailsButton}>Details</button>
                                            {invoice.status === 'Awaiting Payment Confirmation' &&
                                                <>
                                                <button 
                                                className={styles.actionButton} 
                                                onClick={() => handleConfirm(invoice.invoiceId)}
                                            >
                                                Confirm Payment
                                            </button>
                                            <button 
                                                className={styles.actionButtonTwo} 
                                                onClick={() => handleDeny(invoice.invoiceId)}
                                            >
                                                Deny Payment
                                            </button>
                                                </>
                                            }
                                        </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal
                        isOpen={showModal}
                        onRequestClose={handleCloseModal}
                        className={styles.modal}
                        overlayClassName={styles.overlay}
                    >
                        <div className={`${styles.detailModalContent}`}>
                        <h4>Invoice Details for Invoice ID: {selectedInvoice}</h4>
                        <p><strong>Total Amount:</strong> ${invoiceDetails.totalAmount?.toFixed(2)}</p>
                        <p><strong>Date Issued:</strong> {new Date(invoiceDetails.createdAt).toLocaleDateString()}</p>
                        <p><strong>Status:</strong> {invoiceDetails.status}</p>
                        <div className={styles.detailsInfo}>
                        {invoiceDetails.orders && invoiceDetails.orders.map((order, index) => (
                            <div key={index} className={styles.invoiceDetailOrder}>
                                <h5>Order ID: {order.orderId}</h5>
                                <p>Status: {order.orderStatus}</p>
                                <p>Store: {order.storeName}</p>
                                <div className={styles.invoiceDetailOrderItems}>
                                {order.items.map((item, idx) => (
                                    <div key={idx} className={styles.orderDetails}>
                                        <p>Product: {item.productName}</p>
                                        {item.Variant && item.Variant.length > 0 && 
                                        item.Variant.map((cv, catIndex) => (
                                        <p key={catIndex}>
                                            {`${cv.name}: ${cv.value}`}
                                            {catIndex !== item.Variant.length - 1}
                                        </p>
                                        ))}
                                        <p>Quantity: {item.quantity}</p>
                                        <p>Shipping: ${item.shippingPrice}</p>
                                        <p>Prodct Price: ${item.productPrice}</p>
                                        <p>Total Price: ${item.quantity * (item.productPrice + item.shippingPrice)}</p>
                                        <p>SKU: {item.SKU}</p>
                                        <p>Supplier: {item.supplierName}</p>
                                    </div>
                                    
                                ))}
                                </div>
                            </div>
                        ))}
                        </div>
                        <button onClick={handleCloseModal} className={styles.closeButton}>Close</button>
                        </div>
                    </Modal>
            <PaginationComponent
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
        </div>
    );
};

export default Payments;
