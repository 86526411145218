import React, { useEffect, useState } from 'react';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import merchantService from '../../services/merchantService';
import styles from './Orders.module.scss';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Orders = () => {
    useEffect(() => {
        document.title = 'Orders | Qiwi';
      }, []);
      
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("all");
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState([]);
    const [showDate, setShowDate] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [visibleItems, setVisibleItems] = useState({});
    const [noActiveOrders, setNoActiveOrders] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [mergedData, setMergedData] = useState([]);
    const [mergedModal, setMergedModal] = useState(false);

    const navigate = useNavigate();
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Awaiting Invoice', label: 'Awaiting Invoice' },
        { value: 'Awaiting Payment', label: 'Awaiting Payment' },
        { value: 'Awaiting Payment Confirmation', label: 'Awaiting Payment Confirmation' },
        { value: 'Awaiting Fulfillment', label: 'Awaiting Fulfillment' },
        { value: 'Partially Fulfilled', label: 'Partially Fulfilled' },
        { value: 'Fulfilled', label: 'Fulfilled' },
        { value: 'Cancel Requested', label: 'Cancel Requested'},
        { value: 'Cancelled', label: 'Cancelled' },
    ];


    const [orderInfo, setOrderInfo] = useState([]);
    const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);

    useEffect(() => {
        const fetchOrdersInfo = async () => {
            try {
                setLoadingInitialInfo(true)
                const response = await merchantService.fetchOrdersInfo();
                setOrderInfo(response);
                setLoadingInitialInfo(false)
            } catch (error) {
                console.error('Failed to fetch orders info:', error);
                setOrderInfo([]);
            }
        };
        fetchOrdersInfo();
    }, []);

    const toggleItemDetails = async (orderId) => {
        if (visibleItems[orderId]) {
            setVisibleItems(prev => ({
                ...prev,
                [orderId]: false
            }));
        } else {
            setVisibleItems(prev => ({
                ...prev,
                [orderId]: 'loading'
            }));
            try {
                const items = await merchantService.fetchMerchantOrderItems(orderId, showArchived);
                setOrders(prevOrders => prevOrders.map(order =>
                    order.orderId === orderId ? { ...order, items } : order
                ));
            } catch (error) {
                console.error('Failed to fetch order items:', error);
            } finally {
                setVisibleItems(prev => ({
                    ...prev,
                    [orderId]: true
                }));
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage, showArchived]);

    const fetchMergeData = async (orderId) => {
        try {
            const { success, mergeGroups } = await merchantService.fetchMergesFromOrderId(orderId);
            if (success && mergeGroups) {
                setMergedData(mergeGroups);
                setMergedModal(true); // Öppna modalen efter att datan har hämtats
            } else {
                console.error('No merge groups found.');
            }
        } catch (error) {
            console.error('Failed to fetch merge info:', error);
        }
    };    

    const fetchData = async () => {
        try {
            setLoading(true);
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const data = await merchantService.fetchOrdersByPageAndFilters(
                statusFilter,
                currentPage,
                itemsPerPage,
                searchQuery,
                startDate,
                endDate,
                showArchived
            );
            setOrders(data.orders);
            setTotalPages(data.totalPages);

            if (initialLoad) {
                if (!data.orders.length) {
                    setNoActiveOrders(true);
                    setFiltersApplied(false);
                } else {
                    setNoActiveOrders(false);
                    setFiltersApplied(true);
                }
                setInitialLoad(false);
            } else {
                setFiltersApplied(true);
                setNoActiveOrders(!data.orders.length);
            }
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch orders:', error);
        }
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const applyFilters = () => {
        setFiltersApplied(true);
        fetchData();
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...orders].sort((a, b) => {
            if (key === 'quantity') {
                const totalQuantityA = a.items.reduce((total, item) => total + item.quantity, 0);
                const totalQuantityB = b.items.reduce((total, item) => total + item.quantity, 0);

                return direction === 'ascending' ? totalQuantityA - totalQuantityB : totalQuantityB - totalQuantityA;
            }

            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setOrders(sortedData);
    };

    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setInitialLoad(true);
        setCurrentPage(1);
    };

    const createDetailRow = (order) => {
        return (
            <td colSpan="7"
            >
                <table>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>SKU</th>
                            <th>Shopify ID</th>
                            <th>Variant</th>
                            <th>Supplier</th>
                            <th>Fulfilled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.items.map((item, index) => (
                            <tr key={`item-${order.orderId}-${index}`} className={item.canceled_at && styles.canceledItem}>
                                <td>{item.productName}</td>
                                <td>{item.quantity}</td>
                                <td>{item.SKU}</td>
                                <td>{item.storeProductShopifyId}</td>
                                <td>
                                    {item.Variant && item.Variant.categories.length > 0 ? (
                                        item.Variant.categories.map((cv, catIndex) => (
                                            <span key={catIndex}>
                                                {`${cv.name}: ${cv.value}`}
                                                {catIndex !== item.Variant.categories.length - 1 && ', '}
                                            </span>
                                        ))
                                    ) : (
                                        'No Variants'
                                    )}
                                </td>
                                <td>{item.supplierName}</td>
                                <td>{item.isFulfilled ? (
                                            <div className={styles.checkedCircle}>
                                                <span className="material-symbols-outlined">
                                                    check_circle
                                                </span>
                                            </div>
                                        ) : (
                                            <div className={styles.unCheckedCircle}>
                                                <span class="material-symbols-outlined">
                                                cancel
                                                </span>
                                        </div>
                                        )
                                        
                                        }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Orders</h1>
            </div>
            <div className={styles.infoContainers}>
                <div className={`${styles.infoBoxWithDetails} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {! loadingInitialInfo &&
                    <>
                    <div className={styles.mainInfo}>
                    <p>Pending Orders</p>
                    <h2>{orderInfo.pendingOrders.totalOrders}</h2>
                    </div>
                    <div className={styles.sideInfoOne}>
                    <div>
                        <h3>{orderInfo.pendingOrders.awaitingInvoice}</h3><p>Awaiting Invoice</p>
                    </div>
                    <div>
                        <h3>{orderInfo.pendingOrders.awaitingMerchantPayment}</h3><p>Awaiting Payment</p>
                    </div>
                    <div>
                        <h3>{orderInfo.pendingOrders.awaitingPaymentConfirmation}</h3><p>Awaiting Payment Confirmation</p>
                    </div>
                    </div>
                    <div className={styles.sideInfoTwo}>
                    <div>
                        <h3>{orderInfo.pendingOrders.awaitingShipment}</h3><p>Awaiting Fulfillment</p>
                    </div>
                    <div>
                        <h3>{orderInfo.pendingOrders.partiallyFulfilled}</h3><p>Partially Fulfilled</p>
                    </div>
                    <div>
                    <h3 style={{ visibility: 'hidden' }}>column</h3>
                    </div>
                    </div>
                    </>
                    }
                </div>
                <div className={`${styles.infobox} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {! loadingInitialInfo &&
                    <>
                    <p>Recent Fulfillments</p>
                    <h2>{orderInfo.newlyFulfilled}</h2>
                    </>
                    }
                </div>
            </div>
            <div className={styles.top}>
                <FilterComponent
                    statusFilter={statusFilter}
                    setSelectFilter={setStatusFilter}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    statusOptions={statusOptions}
                    includeSearch={showArchived ? false : true}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder="Search Shopify Order ID..."
                    showDate={showDate}
                    setShowDate={setShowDate}
                    selectLabel="Status"
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                    applyFilters={applyFilters}
                    dataType="Orders"
                    filtersDisabled={!filtersApplied && noActiveOrders}
                />
                <button onClick={() => navigate(`${window.location.pathname}/merge-orders`)} className={styles.mergeOrderButton}>
                    <span className="material-symbols-outlined">call_merge</span>
                    <span>Merge Shipment</span>
                </button>
            </div>

            {totalPages > 0 ? (
                <>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('orderId')}>
                                        <div className={styles.thHeader}>
                                            Order ID
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Items</th>
                                    <th onClick={() => handleSort('shopifyId')}>
                                        <div className={styles.thHeader}>
                                            Shopify ID
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'shopifyId' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'shopifyId' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('status')}>
                                        <div className={styles.thHeader}>
                                            Status
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'status' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'status' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Store</th>
                                    <th onClick={() => handleSort('createdAt')}>
                                        <div className={styles.thHeader}>
                                            Date
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading ?
                                    orders.map(order => (
                                        <React.Fragment key={order.orderId}>
                                            <tr
                                                className={visibleItems[order.orderId] ? styles.droppeddown : styles.rows}
                                                >
                                                <td
                                                >{order.orderId}</td>
                                                <td>
                                                    <button
                                                        className={styles.toggleRowsButton}
                                                        onClick={() => toggleItemDetails(order.orderId)}
                                                    >
                                                        {order.itemsCount}
                                                        <span className="material-symbols-outlined">
                                                            {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                        </span>
                                                    </button>
                                                </td>
                                                <td>
                                                    <a href={`https://admin.shopify.com/store/${order.storeName}/orders/${order.shopifyOrderId}`}
                                                    target="_blank" 
                                                    rel="noopener noreferrer">
                                                        {order.shopifyOrderId}
                                                    </a>
                                                </td>

                                                <td>{order.status}
                                                </td>
                                                <td>{order.storeName}</td>
                                                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                                <td>
                                                    {order.hasMerges && 
                                                    <button className={styles.isMergedButton} onClick={()=>fetchMergeData(order.orderId)}><span className="material-symbols-outlined">call_merge</span>Merged</button>
                                                    }
                                                </td>
                                            </tr>
                                            {visibleItems[order.orderId] === 'loading' ? (
                                                <tr className={styles.detailRow} key={`details-${order.orderId}`}
                                                >
                                                    <td colSpan={7} >
                                                        <table>
                                                            <tbody>
                                                                {Array.from({ length: order.itemsCount + 1 }).map((_, index) => (
                                                                    <tr key={index} className={`${styles.rows} ${styles.placeHolderDetailRow}`}>
                                                                        <td colSpan={7}></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            ) : visibleItems[order.orderId] && order.items && (
                                                <tr className={styles.detailRow} key={`details-${order.orderId}`}>
                                                    {createDetailRow(order)}
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))
                                    :
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                            <td colSpan={7}></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    
                    <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                </>
            ) : (
                showArchived ? (
                    <p className={styles.noFound}>No archived orders found{filtersApplied && ", check filtering"}</p>
                ) : (
                    <p className={styles.noFound}>No active orders found{filtersApplied && ", check filtering"}</p>
                )
            )}
            {mergedModal && (
            <Modal
                isOpen={mergedModal}
                onRequestClose={() => setMergedModal(false)}
                contentLabel="Merged Orders"
                className={styles.modal} // Stilklasser för modalen
                overlayClassName={styles.overlay} // Stilklasser för overlay
            >
                <h2>Merged Orders</h2>
                <div className={styles.mergeGroupsContainer}>
                {mergedData.map(group => (
                    <div key={group.mergeGroupId} className={styles.mergeGroup}>
                        <p><strong>Supplier:</strong> {group.supplier}</p>
                        <table className={styles.mergeTable}>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Product Name</th>
                                    <th>Variant</th>
                                    <th>Quantity</th>
                                    <th>SKU</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group.fulfillmentOrders.map(fulfillmentOrder => (
                                    <React.Fragment key={fulfillmentOrder.fulfillmentOrderId}>
                                        {fulfillmentOrder.orderItems.map(item => (
                                            <tr key={item.id}>
                                                <td>{fulfillmentOrder.orderId}</td>
                                                <td>{item.productName}</td>
                                                <td>{item.variantDetails && item.variantDetails.categories && item.variantDetails.categories.length > 0 ? ( // Korrigerat till variantDetails
                    item.variantDetails.categories.map((cv, catIndex) => (
                      <span key={catIndex}>
                        {`${cv.name}: ${cv.value}`}
                        {catIndex !== item.variantDetails.categories.length - 1 && ', '}
                      </span>
                    ))
                  ) : (
                    '-'
                  )}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.SKU}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
                </div>
                <button onClick={() => setMergedModal(false)} className={styles.closeButton}>Close</button>
            </Modal>
        )}
        </div>
    );
};

export default Orders;
