import React from 'react';

const Homepage = () => {


    return (
        <div>
            Hej
        </div>
    );
};

export default Homepage;
