import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supplierService from '../../services/supplierService';
import styles from './Sourcing.module.scss';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import Modal from 'react-modal';
import HoverableOverflowText from '../../components/HoverableOverflowText';

const Sourcing = () => {
    const [statusFilter, setStatusFilter] = useState("all");
    const [searchQuery, setSearchQuery] = useState("");
    const [dateRange, setDateRange] = useState([]);
    const [sourcingData, setSourcingData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [responseDetail, setResponseDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null); // Ny state för vald bild
    const [comparisonData, setComparisonData] = useState([]);
    const [comparisonModal, setComparisonModal] = useState(false);
    const [expiring, setExpiring] = useState(false);
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Awaiting Response', label: 'Awaiting Response' },
        { value: 'Awaiting Merchant Confirmation', label: 'Awaiting Merchant Confirmation' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'Published In Merchant Store', label: 'Published In Merchant Store' },
        { value: 'Denied', label: 'Denied' },
    ];

    useEffect(() => {
      const storedExpiring = sessionStorage.getItem('expiring');
      if (storedExpiring !== null) {
        setExpiring(storedExpiring === 'true'); // Konvertera strängen till en boolean
      }
    }, []);
    
    const navigate = useNavigate();

    const fetchData = async () => {
        setLoading(true);
        try {
            const { requests, totalPages } = await supplierService.fetchSourcingDataByPageAndStatus({
                status: statusFilter,
                pageNumber: currentPage,
                pageAmount: itemsPerPage,
                searchQuery,
                startDate: dateRange.length ? dateRange[0].startDate : null,
                endDate: dateRange.length ? dateRange[0].endDate : null,
            });
            setSourcingData(requests);
            setTotalPages(totalPages);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setResponseDetail(null);
        setSelectedImage(null); // Stäng bildmodellen också
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleActionClick = async (action, requestId) => {
        if (action === 'Deny') {
            try {
                await supplierService.denyRequest(requestId);
                fetchData(); 
            } catch (error) {
                console.error("Error denying request: ", error);
            }
        } else if (action === 'Respond') {
            navigate(`/supplier/sourcing/create-quotation/${requestId}`);
        }
    };

    const submitFilter = async () => {
        setCurrentPage(1);
        fetchData();
    };

    const openModal = async (requestId) => {
        try {
            const data = await supplierService.getResponseByIdandSupplier(requestId);
            setResponseDetail(data);
        } catch (error) {
            console.error("Error fetching response detail: ", error);
        }
    }

    const openComparison = async (requestId) => {
        try {
            const data = await supplierService.getComparisonData(requestId);
            setComparisonData(data);
            setComparisonModal(true);
        } catch (error) {
            console.error("Error fetching comparison data: ", error);
        }
    }

    const closeComparison = () => {
        setComparisonModal(false);
        setComparisonData([]);
    }

    useEffect(() => {
        if(responseDetail) {
            setModalIsOpen(true);
        }
    }, [responseDetail]);

    // Funktion för att öppna modal med större bild
    const openImageModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setModalIsOpen(true);
    };

    const applyFilters = () => {
        fetchData();
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>Sourcing Requests</h2>
            </div>
            <FilterComponent
                dataType={'Sourcing Requests'}
                backgroundColor='white'
                statusFilter={statusFilter}
                setSelectFilter={setStatusFilter}
                selectLabel="Status"
                dateRange={dateRange}
                setDateRange={setDateRange}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                fetchData={submitFilter}
                statusOptions={statusOptions}
                searchPlaceholder="Search by product name"
                includeSearch={true}
                applyFilters={applyFilters}
            />
            {loading ? 
                <p>Loading...</p> 
                : sourcingData.length === 0 ? 
                <div>
                    <p>Currently no requests</p>
                </div>
                : 
                <>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th colSpan={1}>Id</th>
                                    <th colSpan={2}>Product Info</th>
                                    <th>Date</th>
                                    <th>Quotation Status</th>
                                    <th colSpan={2}>Feedback</th>
                                    <th>Confirmed</th>
                                    <th colSpan={2}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sourcingData.map(request => (
                                    <tr key={request.id} className={styles.rows}>
                                        <td>{request.id}</td>
                                        <td colSpan={2}>
                                            <div className={styles.productInfo}>
                                                {request.image && <img src={`/uploads/productImages/${request.image}`} alt={request.productName} className={styles.productImage} onClick={() => openImageModal(`/uploads/productImages/${request.image}`)} />}
                                                <div className={styles.productInfoText}>
                                                <span>{request.productName}</span>
                                                <span>{request.description}</span>
                                                <span>
        <a href={request.url} target="_blank" rel="noopener noreferrer">
            {request.url}
        </a>
    </span>                                                </div>
                                            </div>
                                        </td>
                                        <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                                        <td>
                                            {request.Quotations.length > 0 && request.Quotations[0].status ? request.Quotations[0].status : 'Awaiting Response'}
                                        </td>
                                        <td colSpan={2}>{request.Quotations.length > 0 && <HoverableOverflowText text={request.Quotations[0].feedback}/>}</td>
                                        <td>{request.Quotations.length > 0 && (
                                            request.Quotations[0].status === 'Confirmed' || request.Quotations[0].status === 'Published In Merchant Store' ? (
                                                <div className={styles.checkedCircle}>
                                                    <span className="material-symbols-outlined">
                                                        check_circle
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className={styles.xCircle}>
                                                    <span className="material-symbols-outlined">
                                                        cancel
                                                    </span>
                                                </div>
                                            )
                                        )}</td>
                                        <td colSpan={2}>
                                        {request.Quotations.length === 0 ? (
    <div className={styles.buttons}>
        {!expiring && (
            <>
                <button onClick={() => handleActionClick('Respond', request.id)} className={styles.respondButton}>
                    Respond
                </button>
                <button onClick={() => handleActionClick('Deny', request.id)} className={styles.denyButton}>
                    Deny
                </button>
            </>
        )}
    </div>
) : (
    <div className={styles.buttons}>
        {request.Quotations[0].status === 'Pending' && !expiring && (
            <>
                <a href={`sourcing/create-quotation/${request.id}`}>
                    <button className={styles.editButton}>
                        Edit
                    </button>
                </a>
                <button onClick={() => openComparison(request.id)} className={styles.comparisonButton}>
                    Comparison
                </button>
            </>
        )}
        <button onClick={() => openModal(request.id)} className={styles.actionsButton}>
            Quotation
        </button>
    </div>
)}

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {comparisonModal && comparisonData && (
    <Modal
        isOpen={comparisonModal}
        onRequestClose={closeComparison}
        className={styles.modalthree}
        overlayClassName={styles.overlay}
    >
        <div>
            <table className={styles.comparisonTable}>
                <thead>
                    <tr>
                        <th>Supplier</th>
                        <th>Product Price</th>
                        <th>Shipping Price</th>
                        <th>Total Price</th>
                    </tr>
                </thead>
                <tbody>
                    {comparisonData.map((item, index) => (
                        <tr
                            key={index}
                            className={styles.rows}
                        >
                            <td>{item.supplierName}</td>
                            <td>
                                {item.averageProductPrice !== undefined
                                    ? `Avg. ${item.averageProductPrice}`
                                    : item.productPrice !== undefined
                                        ? item.productPrice
                                        : '-'}
                            </td>

                            <td>
                                {item.averageShippingPrice !== undefined
                                    ? `Avg. ${item.averageShippingPrice}`
                                    : item.shippingPrice !== undefined
                                        ? item.shippingPrice
                                        : '-'}
                            </td>

                            <td>
                                {item.averageTotalPrice !== undefined
                                    ? `Avg. ${item.averageTotalPrice}`
                                    : item.totalPrice !== undefined
                                        ? item.totalPrice
                                        : '-'}
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </Modal>
)}


                    {modalIsOpen && selectedImage &&
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Image Details"
                            className={styles.modaltwo}
                            overlayClassName={styles.overlaytwo}
                        >
                                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
                        </Modal>
                    }
                    {modalIsOpen && responseDetail && 
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Quotation Details"
                            className={styles.modal}
                            overlayClassName={styles.overlay}>
                            <div className={styles.modalContainer}>
                                <div className={styles.detailContainer}>
                                    <div className={styles.detailItems}>
                                        <h3 className={styles.detailItem}>
                                            {responseDetail.productName}
                                        </h3>
                                        {responseDetail.comments.length > 0 &&
                                            <p className={styles.detailItem}>
                                                <strong>Comments:</strong> {responseDetail.comments}
                                            </p>
                                        }
                                    </div>
                                    {responseDetail.hasVariants ? (
                                        <div className={`${styles.tableContainer} ${styles.modalTableContainer}`}>
                                            <table className={styles.variantsTable}>
                                                <thead className={styles.table_header}>
                                                    <tr className={styles.table_header}>
                                                        <th>Image</th>
                                                        {responseDetail.categories.map((quotationcategory, index) => (
                                                            <th key={index}>{quotationcategory}</th>
                                                        ))}
                                                        <th>SKU</th>
                                                        <th>Product Price</th>
                                                        <th>Shipping Price</th>
                                                        <th>Shipping Time</th>
                                                        <th>Processing Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {responseDetail.variants.map((variant, index) => (
                                                        <tr key={index} className={styles.rows}>
                                                            <td>
                                                                <img src={variant.image ? `/uploads/productImages/${variant.image}` : 'https://cdn3.iconfinder.com/data/icons/design-n-code/100/272127c4-8d19-4bd3-bd22-2b75ce94ccb4-512.png'} alt={`Variant ${index + 1}`} className={styles.image}></img>
                                                            </td>
                                                            {responseDetail.categories.map((quotationcategory, catIndex) => (
                                                                <td key={catIndex}>{variant[quotationcategory]}</td>
                                                            ))}
                                                            <td>{variant.SKU}</td>
                                                            <td>${variant.productPrice}</td>
                                                            <td>${variant.shippingPrice}</td>
                                                            <td>{variant.shippingTime}</td>
                                                            <td>{variant.processingTime}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className={`${styles.tableContainer} ${styles.modalTableContainer}`}>
                                            <table className={styles.detailTable}>
                                                <thead>
                                                    <tr className={styles.table_header}>
                                                        {responseDetail.image && <th>Product Image</th>}
                                                        <th>Shipping Price</th>
                                                        <th>Product Price</th>
                                                        <th>Shipping Time</th>
                                                        <th>Processing Time</th>
                                                        <th>SKU</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className={styles.rows}>
                                                        {responseDetail.image && (
                                                            <td>
                                                                <img src={`/uploads/productImages/${responseDetail.image}`} alt={responseDetail.productName} className={styles.quotationmage} />
                                                            </td>
                                                        )}
                                                        <td>${responseDetail.shippingPrice}</td>
                                                        <td>${responseDetail.productPrice}</td>
                                                        <td>{responseDetail.shippingTime}</td>
                                                        <td>{responseDetail.processingTime}</td>
                                                        <td>{responseDetail.SKU}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Modal>
                    }

                    <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                </>
            }
        </div>
    );
};

export default Sourcing;
