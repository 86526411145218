import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;


const fetchUserTickets = async () => {
    try {
        const response = await axios.get(`${API_URL}user/fetchUserTickets/`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user tickets:', error);
        throw error;
    }
}

const createTicket = async (subject, message, mail) => {
    try {
        const response = await axios.post(`${API_URL}user/createTicket`, {
            subject,
            message,
            mail
        }, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to create support ticket:', error);
        throw error;
    }
};

export default {
    createTicket,
    fetchUserTickets
};