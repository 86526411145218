import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Stores.module.scss';
import Modal from 'react-modal';

const Stores = () => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("all");
    const [dateRange, setDateRange] = useState([]);
    const [selectedStoreToDelete, setSelectedStoreToDelete] = useState({});
    const [selectedStoreToDeleteOrders, setSelectedStoreToDeleteOrders] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [showArchived, setShowArchived] = useState(false);

    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'expiring', label: 'Expiring' }
    ];

    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setCurrentPage(1);
    };

    const deleteStoreModal = async (store) => {
        const orders = await adminService.storeDeleteStatus(store.id);
        setSelectedStoreToDelete(store);
        setSelectedStoreToDeleteOrders(orders.orders);
        setIsDeleteModalOpen(true);
    }

    const fetchStores = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchStoresByPageAndFilters(statusFilter, currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived);
            setData(response.stores || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
        }
    };

    useEffect(() => {
        fetchStores();
    }, [currentPage, itemsPerPage, showArchived]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (filter) => {
        setStatusFilter(filter);
        setCurrentPage(1); // Reset to first page when filter changes
    };

    const applyFilters = () => {
        fetchStores();
    };

    const expireStore = async (storeId) => {
        const confirmed = window.confirm("Are you sure you want to expire this Store? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.expireStore(storeId);
                alert("Store expired successfully.");
                fetchStores();
            } catch (error) {
                console.error("Error expiring Store:", error);
                alert("Failed to expire Store.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };
    
    const deleteStore = async (storeId) => {
        const confirmed = window.confirm("Are you sure you want to delete this Store? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.storeDelete(storeId);
                alert("Store deleted successfully.");
                fetchStores();
            } catch (error) {
                console.error("Error deleting Store:", error);
                alert("Failed to delete Store.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
            <h1>Stores</h1>
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent
                    backgroundColor='white'
                    statusFilter={statusFilter}
                    setSelectFilter={handleFilterChange}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    applyFilters={applyFilters}
                    statusOptions={statusOptions}
                    searchPlaceholder="Search by store..."
                    includeSearch={true}
                    selectLabel="Status"
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                />
            </div>
            <div className={styles.tableContainer}>
            <table className={styles.table}>
                                    <thead>
                        <tr>
                        <th onClick={() => handleSort('id')}>
                                <div className={styles.thHeader}>
                                    Id
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('shop')}>
                                <div className={styles.thHeader}>
                                    Store
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'shop' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'shop' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('merchant')}>
                                <div className={styles.thHeader}>
                                    Owner
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'merchant' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'merchant' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Created At
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(store => (
                            <tr key={store.id} className={styles.rows}>
                                <td>{store.id}</td>
                                <td>
                                     {store.shop.replace('.myshopify.com', '')}
                                </td>
                                <td>
                                    {store.merchant}
                                </td>
                                <td>{new Date(store.createdAt).toLocaleDateString()}</td>
                                <td>{store.expiring ? 
                                    <div className={styles.hourglass}>
                                    <span className="material-symbols-outlined">
                                    hourglass_bottom
                                    </span>
                                    </div>
                                     :
                                     <div className={styles.checked}>
                                     <span className="material-symbols-outlined">
                                     check_circle
                                     </span>
                                     </div>
                                     }</td>
                                    <td className={styles.buttons}>
                                    {!showArchived && !store.expiring && <button className={styles.deleteButton} onClick={() => deleteStoreModal(store)}>Delete</button>}
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal
    isOpen={isDeleteModalOpen}
    onRequestClose={() => setIsDeleteModalOpen(false)}
    className={styles.modal}
    overlayClassName={styles.overlay}
>
    <h2>Deleting Store</h2>
    <p>{selectedStoreToDelete.name}</p>
    
    {selectedStoreToDeleteOrders && selectedStoreToDeleteOrders.length > 0 && (
        <>
        <p><strong>OBS. There are exisiting orders pending for this Store</strong></p>
        <table>
            <thead>
                <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {selectedStoreToDeleteOrders.map(order => (
                    <tr key={order.orderId}>
                        <td>{order.orderId}</td>
                        <td>{order.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    )}
    <div className={styles.buttonGroup}>
    {selectedStoreToDeleteOrders && selectedStoreToDeleteOrders.length > 0 ? 
    <button className={styles.expiringButton} onClick={() => expireStore(selectedStoreToDelete.id)}>Set as Expiring</button> :
    <button className={styles.deleteButtonModal} onClick={() => deleteStore(selectedStoreToDelete.id)}> Delete</button>
    }
    </div>
</Modal>
            <PaginationComponent
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
        </div>
    );
};

export default Stores;
