import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import authService from '../services/authService';

const PublicRoute = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(null);
    const hasShopifyCookie = document.cookie.split('; ').find(row => row.startsWith('shopify_shop'));
    const shopifyShopValue = hasShopifyCookie ? hasShopifyCookie.split('=')[1] : null;
    
    
    useEffect(() => {
        const checkAuth = async () => {
            const auth = await authService.authenticate();
            
            setAuthenticated(auth.authenticated);
        };
        const checkLoginSkip = async () => {
            const auth = await authService.checkLoginSkip(shopifyShopValue);
            
            setAuthenticated(auth.authenticated);
        };

        if(!shopifyShopValue) {
            checkAuth();
        } else {
            checkLoginSkip();
        }
    }, []);

    if (authenticated === null) {
        return; 
    }

    return authenticated ? <Navigate to="/dashboard" /> : children;
};


export default PublicRoute;
