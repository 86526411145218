import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PrivateRoute from './security/privateRoute';
import PublicRoute from './security/publicRoute';
import StandardRoute from './security/standardRoute';

import Login from './pages/shared/Login';
import ForgotPassword from './pages/shared/ForgotPassword';
import ResetPassword from './pages/shared/ResetPassword';
import MerchantRegister from './pages/merchants/MerchantRegister';
import Unauthorized from './pages/shared/Unauthorized';
import NotFound from './pages/shared/NotFound';
import TermsOfService from './pages/shared/TermsOfService';
import PrivacyPolicy from './pages/shared/PrivacyPolicy';
import DataProtectionAgreement from './pages/shared/DataProtectionAgreement';

import MerchantLayout from './layout/MerchantLayout';
import Dashboard from './pages/merchants/Dashboard';
import Sourcing from './pages/merchants/Sourcing';
import StoreManagement from './pages/merchants/StoreManagement';
import Products from './pages/merchants/ProductManagement';
import SourcingCreateRequest from './pages/merchants/CreateRequest';
import Settings from './pages/merchants/Settings';
import Orders from './pages/merchants/Orders';
import Invoices from './pages/merchants/Invoices';
import Support from './pages/merchants/Support';
import CreateSupportTicket from './pages/merchants/CreateSupportTicket';
import QuotationList from './pages/merchants/QuotationList';
import MergeOrder from './pages/merchants/MergeOrder';

import SupplierLayout from './layout/SupplierLayout';
import SourcingSupplier from './pages/suppliers/Sourcing';
import OrdersSupplier from './pages/suppliers/Orders';
import SourcingQuotationDetail from './pages/suppliers/SourcingQuotationDetail';
import Homepage from './pages/suppliers/Homepage';
import CreateQuotation from './pages/suppliers/CreateQuotation';
import SupplierSupport from './pages/suppliers/Support';
import SupplierCreateSupportTicket from './pages/suppliers/CreateSupportTicket';

import AdminLayout from './layout/AdminLayout';
import MerchantsAdmin from './pages/admin/Merchants';
import SuppliersAdmin from './pages/admin/Suppliers';
import UsersAdmin from './pages/admin/Users';
import OrdersAdmin from './pages/admin/Orders';
import Payments from './pages/admin/Payments';
import ProductsAdmin from './pages/admin/Products';
import StoresAdmin from './pages/admin/Stores';
import CreateInvoice from './pages/admin/CreateInvoice';
import CreateSupplier from './pages/admin/CreateSupplier';
import CreateUser from './pages/admin/CreateUser';
import AdminSourcingRequests from './pages/admin/Requests';
import QuotationDetails from './pages/admin/QuotationDetails';
import AdminSupportTickets from './pages/admin/SupportTickets';

const App = () => {

  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StandardRoute />}>
          <Route path="/" element={<MerchantLayout />}>
            <Route index element={<PrivateRoute requiredRole="Merchant"><Sourcing /></PrivateRoute>} />
            <Route path="dashboard" element={<PrivateRoute requiredRole="Merchant"><Dashboard /></PrivateRoute>} />
            <Route path="sourcing" element={<PrivateRoute requiredRole="Merchant"><Sourcing /></PrivateRoute>} />
            <Route path="sourcing/create-request" element={<PrivateRoute requiredRole="Merchant" requiredNotExpired={true}><SourcingCreateRequest /></PrivateRoute>} />
            <Route path="sourcing/quotations/:requestId" element={<PrivateRoute requiredRole="Merchant"><QuotationList /></PrivateRoute>} />
            <Route path="products" element={<PrivateRoute requiredRole="Merchant"><Products /></PrivateRoute>} />
            <Route path="orders" element={<PrivateRoute requiredRole="Merchant"><Orders /></PrivateRoute>} />
            <Route path="orders/merge-orders" element={<PrivateRoute requiredRole="Merchant"><MergeOrder /></PrivateRoute>} />
            <Route path="store-management" element={<PrivateRoute requiredRole="Merchant"><StoreManagement /></PrivateRoute>} />
            <Route path="billing" element={<PrivateRoute requiredRole="Merchant"><Invoices /></PrivateRoute>} />
            <Route path="settings" element={<PrivateRoute requiredRole="Merchant"><Settings /></PrivateRoute>} />
            <Route path="support" element={<PrivateRoute requiredRole="Merchant"><Support /></PrivateRoute>} />
            <Route path="support/create-ticket" element={<PrivateRoute requiredRole="Merchant"><CreateSupportTicket /></PrivateRoute>} />
          </Route>
        </Route>

        <Route path="/supplier" element={<PrivateRoute requiredRole="Supplier"><SupplierLayout /></PrivateRoute>}>
          <Route path="sourcing" element={<PrivateRoute requiredRole="Supplier"><SourcingSupplier /></PrivateRoute>} />
          <Route path="" element={<PrivateRoute requiredRole="Supplier"><Homepage /></PrivateRoute>} />
          <Route path="orders" element={<PrivateRoute requiredRole="Supplier"><OrdersSupplier /></PrivateRoute>} />          
          <Route path="sourcing/quotation/:id" element={<PrivateRoute requiredRole="Supplier"><SourcingQuotationDetail /></PrivateRoute>} />
          <Route path="sourcing/create-quotation/:id" element={<PrivateRoute requiredRole="Supplier" requiredNotExpired={true}><CreateQuotation /></PrivateRoute>} />
          <Route path="support" element={<PrivateRoute requiredRole="Supplier"><SupplierSupport /></PrivateRoute>} />          
          <Route path="support/create-ticket" element={<PrivateRoute requiredRole="Supplier"><SupplierCreateSupportTicket /></PrivateRoute>} />
        </Route>

        <Route path="/admin" element={<PrivateRoute requiredRole="Admin"><AdminLayout /></PrivateRoute>}>
          <Route path="users/create-supplier" element={<PrivateRoute requiredRole="Admin"><CreateSupplier /></PrivateRoute>} />
          <Route path="users/create-user" element={<PrivateRoute requiredRole="Admin"><CreateUser /></PrivateRoute>} />
          <Route path="orders" element={<PrivateRoute requiredRole="Admin"><OrdersAdmin /></PrivateRoute>} />
          <Route path="products" element={<PrivateRoute requiredRole="Admin"><ProductsAdmin /></PrivateRoute>} />
          <Route path="users/all-users" element={<PrivateRoute requiredRole="Admin"><UsersAdmin /></PrivateRoute>} />
          <Route path="users/suppliers" element={<PrivateRoute requiredRole="Admin"><SuppliersAdmin /></PrivateRoute>} />
          <Route path="users/merchants" element={<PrivateRoute requiredRole="Admin"><MerchantsAdmin /></PrivateRoute>} />
          <Route path="payments/all-payments" element={<PrivateRoute requiredRole="Admin"><Payments /></PrivateRoute>} />
          <Route path="payments/create-invoice" element={<PrivateRoute requiredRole="Admin"><CreateInvoice /></PrivateRoute>} />
          <Route path="stores" element={<PrivateRoute requiredRole="Admin"><StoresAdmin /></PrivateRoute>} />
          <Route path="sourcing-requests" element={<PrivateRoute requiredRole="Admin"><AdminSourcingRequests /></PrivateRoute>} />
          <Route path="sourcing-requests/quotation-details/:requestId" element={<PrivateRoute requiredRole="Admin"><QuotationDetails /></PrivateRoute>} />
          <Route path="support-tickets" element={<PrivateRoute requiredRole="Admin"><AdminSupportTickets /></PrivateRoute>} />
       </Route>
       
  
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/data-protection-agreement" element={<DataProtectionAgreement />} />
        <Route path="/register" element={<PublicRoute><MerchantRegister /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/reset-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
