import React, { useState } from 'react';
import InfoBox from './InfoBox';
import styles from './HoverableList.module.scss';

const HoverableList = ({ list, wantRight }) => {
    const [isHovered, setIsHovered] = useState(false);

    if (list.length === 0) return null;
    if (list.length === 1) return <span>{list[0]}</span>;

    return (
        <div
            className={styles.hoverableListContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span className={styles.hoverable}>{list.length}</span>
            {isHovered && (
                <div className={styles.infoBoxWrapper}>
                    <InfoBox content={list} wantRight/>
                </div>
            )}
        </div>
    );
};

export default HoverableList;
