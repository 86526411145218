import React, { useEffect } from 'react';
import styles from './PrivacyPolicy.module.scss';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = 'Privacy Policy | Qiwi';
    }, []);  

    return (
        <div className={styles.policyContainer}>
            <h1>Privacy Policy</h1>

            <section>
                <h2>Introduction</h2>
                <p>
                    This Privacy Policy explains how Qiwi collects, uses, and shares information from merchants and their customers through our app and Shopify integration. We are committed to protecting your privacy and ensuring that all data is handled securely and in compliance with applicable laws.
                </p>
            </section>

            <section>
                <h2>Data Collection and Usage</h2>
                <p>
                    This section outlines the types of data Qiwi collects, stores, and uses through Shopify webhooks and APIs, as well as data collected directly from merchants using our app.
                </p>
                <h3>Data Collected from Shopify</h3>
                
                <h4>Store Information</h4>
                <ul>
                    <li><strong>Stored Data:</strong> Shop Name, Session ID, Access Token.</li>
                    <li><strong>Usage:</strong> This data is used to identify your store and facilitate the reading and creation of data, such as fetching orders and managing products.</li>
                </ul>

                <h4>Product Information</h4>
                <ul>
                    <li><strong>Stored Data:</strong> Shopify Product ID, Name, Description, Title, Variant ID.</li>
                    <li><strong>Usage:</strong> Product data is utilized for order processing and product creation within your store.</li>
                </ul>

                <h4>Order Information</h4>
                <ul>
                    <li><strong>Stored Data:</strong> Shopify Order ID, Order Name, Line Item ID.</li>
                    <li><strong>Usage:</strong> Order data is used to process orders, including merging shipments, fulfilling orders, and invoicing for products and shipping.</li>
                </ul>

                <h4>Customer Information</h4>
                <ul>
                    <li><strong>Stored Data:</strong> Customer ID, Full Name, Company Name.</li>
                    <li><strong>Delivery Information:</strong> Address, City, State, Postal Code, Country, Delivery Notes.</li>
                    <li><strong>Usage:</strong> Customer data is used to fulfill orders and potentially merge shipments based on customer details.</li>
                </ul>

                <h4>User-Generated Data</h4>
                <ul>
                    <li><strong>Stored Data:</strong> Full Name, Email Address, Password (encrypted).</li>
                    <li><strong>Invoice Information:</strong> VAT Number, Organization Number, Contact Person, Billing Address, Phone Number.</li>
                    <li><strong>Usage:</strong> Sensitive user-generated data is used for app functionality, such as sending invoices and authentication.</li>
                </ul>

                <p><strong>Note:</strong> Additional data may be sent through Shopify APIs and webhooks during processing. This data may be used strictly on a temporary basis for functionality and is not stored.</p>
            </section>

            <section>
                <h2>Data Sharing</h2>
                <p>We share data with suppliers to fulfill orders and with third-party services engaged by suppliers for delivery, under strict data protection standards.</p>
            </section>

            <section>
                <h2>Data Security</h2>
                <p>We ensure data security through encryption, access controls, and secure transmission protocols.</p>
            </section>

            <section>
                <h2>Data Retention</h2>
                <p>Customer data related to orders is retained for up to 2 months, while store data is managed based on uninstall and deletion requests.</p>
            </section>

            <section>
                <h2>Customer Rights</h2>
                <p>Customers have the right to request access, correction, or deletion of their personal data.</p>
            </section>

            <section>
                <h2>Contact Information</h2>
                <p>For any privacy-related inquiries, please contact us at <a href="mailto:info@qiwifulfill.com">info@qiwifulfill.com</a>.</p>

                <div className={styles.contactInfo}>
                    <p>Qiwi Support</p>
                    <p>Rådmansgatan 6A</p>
                    <p>114 25 Stockholm</p>
                    <p>Sweden</p>
                </div>
            </section>

            <p className={styles.updatedDate}><em>Last updated: 30 Aug 2024</em></p>

            <Link to="/" className={styles.homeButton}>Go to Homepage</Link>
        </div>
    );
};

export default PrivacyPolicy;
