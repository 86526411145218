import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './CreateQuotation.module.scss';
import Modal from 'react-modal';
import Switch from '@mui/material/Switch';
import supplierService from '../../services/supplierService';
import { useParams, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const CreateQuotation = () => {
    const { id: requestId } = useParams();
    const navigate = useNavigate();
    const [hasVariants, setHasVariants] = useState(false);
    const [formData, setFormData] = useState({
        shippingPrice: '',
        processingTime: '',
        productPrice: '',
        shippingTime: '',
        image: null,
        SKU: '',
        comments: ''
    });
    const [variantFormData, setVariantFormData] = useState({
        DefaultValues: {
            shippingPrice: '',
            processingTime: '',
            productPrice: '',
            shippingTime: '',
            comments: ''
        },
        Variants: {
            VariantCategories: [],
            UniquePricesAndTimes: []
        }
    });
    const [newVariantName, setNewVariantName] = useState('');
    const [newVariantValue, setNewVariantValue] = useState('');
    const [newVariants, setNewVariants] = useState([]);
    const [variantList, setVariantList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showUniqueModal, setShowUniqueModal] = useState(false);
    const [uniqueData, setUniqueData] = useState({
        productPrice: 0,
        shippingPrice: 0,
        processingTime: '',
        shippingTime: ''
    });
    const [modalData, setModalData] = useState(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [variantNameError, setVariantNameError] = useState('');
    const [variantValueError, setVariantValueError] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const variantValueInputRef = useRef(null);
    const [groupImageModal, setGroupImageModal] = useState(false);
    const fileInputRefs = useRef([]);
    const nonVariantFileInputRef = useRef(null);
    const [groupImages, setGroupImages] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [request, setRequest] = useState({});
    const [redirected, setRedirected] = useState(false); // Nytt tillstånd
    const [quotationExists, setQuotationExists] = useState(false);
    const [existingQuotation, setExistingQuotation    ] = useState({});
    const [tableRows, setTableRows] = useState();

    const handleCategoryChange = (e) => {
        const newCategory = e.target.value;
        setSelectedCategory(newCategory);
        setGroupImages({});
        const fileInputs = document.querySelectorAll(`.${styles.groupImageModalContent} input[type="file"]`);
        fileInputs.forEach(input => input.value = '');
    };

    const applyGroupImagesToVariants = () => {
        const updatedRows = tableRows.map(row => {
            const categoryName = selectedCategory;
            const categoryValue = row[categoryName];
    
            if (categoryName && groupImages[categoryName] && groupImages[categoryName][categoryValue]) {
                return {
                    ...row,
                    image: groupImages[categoryName][categoryValue]
                };
            }
            return row;
        });
    
        updatedRows.forEach((row, index) => {
            if (row.image) {
                const fileInput = fileInputRefs.current[index];
                if (fileInput) {
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(row.image);
                    fileInput.files = dataTransfer.files;
                }
            }
        });
    
        setTableRows(updatedRows);
        setGroupImageModal(false);
    };


    
    useEffect(() => {
        setTableRows(generateTableRows());
    }, [variantFormData.Variants.VariantCategories]);

      
    useEffect(() => {
        const checkQuotationExists = async () => {
            try {
                const response = await supplierService.checkQuotation(requestId);
    
                if (response.exists && !redirected && !response.quotation) {
                    alert('You have already submitted a quotation for this request.');
                    setRedirected(true);
                    navigate('/supplier/sourcing');
                } else if (!response.exists && !response.quotation) {
                    setQuotationExists(false);
                }
                else {
                    const quotation = response.quotation;
                    setRequest(response.request);
                    setQuotationExists(true);
                    setExistingQuotation(quotation);
    
                    if (quotation.QutationNoVariant) {
                        const qutationNoVariant = quotation.QutationNoVariant;
                        setHasVariants(false);
    
                        if (qutationNoVariant.image && typeof qutationNoVariant.image === 'string') {
                            fetch(`/uploads/productImages/${qutationNoVariant.image}`)
                                .then(res => res.blob())
                                .then(blob => {
                                    const file = new File([blob], `${qutationNoVariant.image}`, { type: blob.type });
                                    setFormData({
                                        shippingPrice: qutationNoVariant.shippingPrice,
                                        processingTime: qutationNoVariant.processingTime,
                                        productPrice: qutationNoVariant.productPrice,
                                        shippingTime: qutationNoVariant.shippingTime,
                                        image: file,  // Använd File-objektet här
                                        SKU: qutationNoVariant.SKU,
                                        comments: quotation.comments
                                    });
                        
                                    if (nonVariantFileInputRef.current) {
                                        const dataTransfer = new DataTransfer();
                                        dataTransfer.items.add(file);
                                        nonVariantFileInputRef.current.files = dataTransfer.files;
                                    }
                                });
                        }                        
                    } else {
                        setHasVariants(true);
    
                        const firstOption = quotation.QuotationVariants[0];
                        let sameProductPrice = firstOption.productPrice;
                        let sameShippingPrice = firstOption.shippingPrice;
                        let sameProcessingTime = firstOption.processingTime;
                        let sameShippingTime = firstOption.shippingTime;
    
                        let allSame = true;
    
                        quotation.QuotationVariants.forEach(option => {
                            if (option.productPrice !== sameProductPrice) sameProductPrice = '';
                            if (option.shippingPrice !== sameShippingPrice) sameShippingPrice = '';
                            if (option.processingTime !== sameProcessingTime) sameProcessingTime = '';
                            if (option.shippingTime !== sameShippingTime) sameShippingTime = '';
                            if (option.productPrice !== firstOption.productPrice ||
                                option.shippingPrice !== firstOption.shippingPrice ||
                                option.processingTime !== firstOption.processingTime ||
                                option.shippingTime !== firstOption.shippingTime) {
                                allSame = false;
                            }
                        });
    
                        const categoryMap = new Map();
    
                        quotation.QuotationVariants.forEach(option => {
                            option.QuotationCategoryVariants.forEach(Variant => {
                                const categoryName = Variant.QuotationCategory.name;
                                const value = Variant.value;
    
                                if (!categoryMap.has(categoryName)) {
                                    categoryMap.set(categoryName, []);
                                }
    
                                if (!categoryMap.get(categoryName).includes(value)) {
                                    categoryMap.get(categoryName).push(value);
                                }
                            });
                        });
    
                        const VariantCategories = Array.from(categoryMap.entries()).map(([name, values]) => ({
                            name,
                            values
                        }));
    
                        setVariantList(VariantCategories);
    
                        setVariantFormData({
                            DefaultValues: {
                                shippingPrice: sameShippingPrice || '',
                                processingTime: sameProcessingTime || '',
                                productPrice: sameProductPrice || '',
                                shippingTime: sameShippingTime || '',
                                comments: quotation.comments
                            },
                            Variants: {
                                VariantCategories: VariantCategories,
                                UniquePricesAndTimes: allSame ? [] : quotation.QuotationVariants.map(option => ({
                                    productPrice: option.productPrice,
                                    shippingPrice: option.shippingPrice,
                                    processingTime: option.processingTime,
                                    shippingTime: option.shippingTime
                                }))
                            }
                        });
    
                        // Uppdatera tableRows baserat på den nya variantFormData
                        const updatedTableRows = generateTableRows();
                        setTableRows(updatedTableRows);
                    }
                }
            } catch (error) {
                console.error('Error checking if quotation exists:', error);
            }
        };
    
        checkQuotationExists();
    }, [requestId, navigate]);
    
    useEffect(() => {
        if (quotationExists && existingQuotation && existingQuotation.QuotationVariants && tableRows) {
            const updatedRows = [...tableRows];
            const fetchedFiles = {}; // Håller koll på redan hämtade filer
    
            tableRows.forEach((row, index) => {
                if (row.image && typeof row.image === 'string') {
                    const fileName = row.image.split('/').pop();
    
                    if (fetchedFiles[fileName]) {
                        // Om filen redan hämtats, återanvänd den
                        const file = fetchedFiles[fileName];
                        updatedRows[index].image = file;
                        const dataTransfer = new DataTransfer();
                        dataTransfer.items.add(file);
                        if (fileInputRefs.current[index]) {
                            fileInputRefs.current[index].files = dataTransfer.files;
                        }
                    } else {
                        // Om filen inte hämtats, gör en fetch och spara resultatet
                        fetch(`/uploads/productImages/${fileName}`)
                            .then(res => res.blob())
                            .then(blob => {
                                const file = new File([blob], fileName, { type: blob.type });
                                fetchedFiles[fileName] = file;
    
                                updatedRows.forEach((r, i) => {
                                    if (r.image === row.image) {
                                        updatedRows[i].image = file;
                                        const dataTransfer = new DataTransfer();
                                        dataTransfer.items.add(file);
                                        if (fileInputRefs.current[i]) {
                                            fileInputRefs.current[i].files = dataTransfer.files;
                                        }
                                    }
                                });
                                setTableRows([...updatedRows]); // Uppdatera state när fetch är klar
                            });
                    }
                }
            });
        }
    }, [tableRows, quotationExists, existingQuotation]);

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setVariantFormData((prev) => ({
                ...prev,
                DefaultValues: {
                    ...prev.DefaultValues,
                    [name]: checked,
                }
            }));
        } else if (type === 'number' && value >= 0) {
            setVariantFormData((prev) => ({
                ...prev,
                DefaultValues: {
                    ...prev.DefaultValues,
                    [name]: value,
                }
            }));
        } else if (type !== 'number') {
            setVariantFormData((prev) => ({
                ...prev,
                DefaultValues: {
                    ...prev.DefaultValues,
                    [name]: value,
                }
            }));
        }
    };

    const handleDefaultInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setFormData((prev) => ({
                ...prev,
                [name]: checked,
            }));
        } else if (type === 'number' && value >= 0) {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        } else if (type !== 'number') {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setFormData(prev => ({
                ...prev,
                image: file
            }));
        } else {
            alert('Please select a valid image file.');
        }
    };    

    const handleVariantFileChange = (index, e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const updatedRows = [...tableRows];
            updatedRows[index].image = file;
            setTableRows(updatedRows);
        } else {
            alert('Please select a valid image file.');
        }
    };
    

    const handleGroupFileChange = (value, e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setGroupImages((prev) => ({
                ...prev,
                [selectedCategory]: {
                    ...(prev[selectedCategory] || {}),
                    [value]: file
                }
            }));
        } else {
            alert('Please select a valid image file.');
        }
    };
    
    const handleAddCategory = () => {
        setShowCategoryModal(true);
    };

    const handleAddVariantValue = (e) => {
        if (e.key === 'Enter' && newVariantValue.trim() !== '') {
            e.preventDefault();
            if (newVariants.includes(newVariantValue.trim())) {
                setVariantValueError(`Duplicate variant value: ${newVariantValue.trim()}`);
            } else {
                setNewVariants((prev) => [...prev, newVariantValue.trim()]);
                setNewVariantValue('');
                setVariantValueError('');
                if (variantValueInputRef.current) {
                    variantValueInputRef.current.focus();
                }
            }
        }
    };

    const handleSaveCategory = () => {
        if (newVariantName.trim() === '') {
            setVariantNameError('QuotationCategory name cannot be empty');
            return;
        }
        if (variantList.some(variant => variant.name === newVariantName.trim())) {
            setVariantNameError(`Duplicate quotationcategory name: ${newVariantName.trim()}`);
            return;
        }
        if (newVariants.length === 0) {
            setVariantValueError('Please enter at least one variant value');
            return;
        }
        setVariantList((prev) => [
            ...prev,
            { name: newVariantName.trim(), values: newVariants }
        ]);
        setNewVariantName('');
        setNewVariantValue('');
        setNewVariants([]);
        setVariantNameError('');
        setVariantValueError('');
    };

    const handleRemoveVariantFromModal = (index) => {
        setNewVariants((prev) => prev.filter((_, i) => i !== index));
    };

    const handleRemoveCategoryFromVariantList = (index) => {
        setVariantList((prev) => prev.filter((_, i) => i !== index));
    };

    const handleSubmitVariants = () => {
        if (variantList.length === 0) {
            alert("Please add at least one quotationcategory with variants.");
            return;
        }
        setFormErrors(prevErrors => ({
            ...prevErrors,
            quotationcategory: ''
        }));
        setVariantFormData((prev) => ({
            ...prev,
            Variants: {
                ...prev.Variants,
                VariantCategories: variantList
            }
        }));
        setShowCategoryModal(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (hasVariants) {
            if (!validateForm()) {
                return;
            }

        }
        setModalData(hasVariants ? generateVariantData() : generateNonVariantData());
        setShowModal(true);
    };

    const validateForm = () => {
        const tableErrors = tableRows.map((row) => {
            if (!row.include) return {};
            const hasPriceAndTime = row.unique || (variantFormData.DefaultValues.productPrice && variantFormData.DefaultValues.shippingPrice && variantFormData.DefaultValues.processingTime && variantFormData.DefaultValues.shippingTime);
            return hasPriceAndTime ? {} : { priceAndTime: 'All prices and times are required' };
        });
    
        const categoryError = variantFormData.Variants.VariantCategories.length === 0 ? 'At least one quotationcategory with variants is required.' : null;
    
        setFormErrors({
            table: tableErrors,
            quotationcategory: categoryError,
        });
    
        return tableErrors.every(row => Object.keys(row).length === 0) && !categoryError;
    };
    

    const generateVariantData = () => {
        const variants = tableRows.filter(row => row.include).map((row, index) => {
            const { unique, uniqueShippingPrice, uniquePrice, uniqueProcessingTime, uniqueShippingTime, include, ...rest } = row;
            return {
                ...rest,
                shippingPrice: row.unique ? uniqueShippingPrice : variantFormData.DefaultValues.shippingPrice,
                productPrice: row.unique ? uniquePrice : variantFormData.DefaultValues.productPrice,
                processingTime: row.unique ? uniqueProcessingTime : variantFormData.DefaultValues.processingTime,
                shippingTime: row.unique ? uniqueShippingTime : variantFormData.DefaultValues.shippingTime,
                SKU: row.SKU, 
                image: row.image ? row.image : null
            };
        });
        return { variants };
    };
    

    const generateNonVariantData = () => {
        return {
            shippingPrice: formData.shippingPrice,
            processingTime: formData.processingTime,
            productPrice: formData.productPrice,
            shippingTime: formData.shippingTime,
            SKU: formData.SKU,
            image: formData.image ? formData.image : null,
            comments: formData.comments
        };
    };
    

    const createFormData = (variants, defaultComments) => {
        const data = new FormData();
        const imageMap = new Map(); // Kartlägg för att undvika duplicering av filuppladdningar
        const files = [];
        
        variants.forEach((variant, index) => {
            data.append(`variants[${index}][SKU]`, variant.SKU);
            data.append(`variants[${index}][shippingPrice]`, variant.shippingPrice);
            data.append(`variants[${index}][productPrice]`, variant.productPrice);
            data.append(`variants[${index}][processingTime]`, variant.processingTime);
            data.append(`variants[${index}][shippingTime]`, variant.shippingTime);
            
            Object.entries(variant).forEach(([key, value]) => {
                if (!['SKU', 'shippingPrice', 'productPrice', 'processingTime', 'shippingTime', 'image'].includes(key)) {
                    data.append(`variants[${index}][categories][${key}]`, value);
                }
            });
            
            if (variant.image) {
                    const existingImageName = existingQuotation?.QuotationVariants?.find(opt => opt.SKU === variant.SKU)?.image;

                    if (variant.image.name === existingImageName) {
                        data.append(`variants[${index}][image]`, variant.image.name);
                    } else {
                        const imageName = variant.image.name;
                        let imageId;
                        
                        if (!imageMap.has(imageName)) {
                            imageId = `image_${variant.SKU}_${Date.now()}_${imageName}`;
                            imageMap.set(imageName, imageId);
                            files.push({ fieldName: imageId, file: variant.image });
                        } else {
                            imageId = imageMap.get(imageName);
                        }
                        data.append(`variants[${index}][image]`, imageId);
                    }
            }
        });
    
        files.forEach(({ fieldName, file }) => {
            data.append(fieldName, file);
        });
    
        data.append('comments', defaultComments);
        return data;
    };
    
    
    const handleModalConfirm = async () => {
        let formDataToSend;
    
        if (hasVariants) {
            const variants = generateVariantData().variants;
            formDataToSend = createFormData(variants, variantFormData.DefaultValues.comments);
        } else {
            formDataToSend = new FormData();
    
            formDataToSend.append('shippingPrice', formData.shippingPrice);
            formDataToSend.append('productPrice', formData.productPrice);
            formDataToSend.append('processingTime', formData.processingTime);
            formDataToSend.append('shippingTime', formData.shippingTime);
            formDataToSend.append('SKU', formData.SKU);
    
            if (formData.image) {
                const existingImageName = existingQuotation?.QutationNoVariant?.image;
                if (formData.image.name === existingImageName) {
                    formDataToSend.append('image', formData.image);
                    formDataToSend.append('imageName', formData.image.name)
                } else {
                    const imageId = `image_${formData.SKU}_${Date.now()}_${formData.image.name}`;
                    formDataToSend.append('image', formData.image, imageId);
                    formDataToSend.append('imageName', imageId)
                }
            }
    
            formDataToSend.append('comments', formData.comments);
        }
    
        formDataToSend.append('requestId', requestId);
        try {
            await supplierService.createQuotation(formDataToSend);
            navigate('/supplier/sourcing');
        } catch (error) {
            console.error("Error submitting form: ", error);
        }
    };
    
        

    
    const handleSetUnique = (index) => {
        setSelectedRowIndex(index);
        const row = tableRows[index];
        setUniqueData({
            productPrice: row.unique ? row.uniquePrice : variantFormData.DefaultValues.productPrice,
            shippingPrice: row.unique ? row.uniqueShippingPrice : variantFormData.DefaultValues.shippingPrice,
            processingTime: row.unique ? row.uniqueProcessingTime : variantFormData.DefaultValues.processingTime,
            shippingTime: row.unique ? row.uniqueShippingTime : variantFormData.DefaultValues.shippingTime,
        });
        setShowUniqueModal(true);
    };

    const handleUniqueConfirm = () => {
        const updatedRows = tableRows.map((row, index) => {
            if (index === selectedRowIndex) {
                return {
                    ...row,
                    uniquePrice: uniqueData.productPrice,
                    uniqueShippingPrice: uniqueData.shippingPrice,
                    uniqueProcessingTime: uniqueData.processingTime,
                    uniqueShippingTime: uniqueData.shippingTime,
                    unique: true
                };
            }
            return row;
        });
        setTableRows(updatedRows);
        setShowUniqueModal(false);
    };

    const generateTableRows = () => {
        if (variantFormData.Variants.VariantCategories.length === 0) return [];
    
        const variantCombinations = variantFormData.Variants.VariantCategories.reduce((acc, quotationcategory) => {
            if (acc.length === 0) return quotationcategory.values.map(value => ({ [quotationcategory.name]: value }));
            return acc.flatMap(prev => quotationcategory.values.map(value => ({ ...prev, [quotationcategory.name]: value })));
        }, []);
    
        const initialTableRows = variantCombinations.map((combination) => {
            if (existingQuotation && existingQuotation.QuotationVariants) {
                const matchingOption = existingQuotation.QuotationVariants.find(option =>
                    option.QuotationCategoryVariants.every(variant =>
                        combination[variant.QuotationCategory.name] === variant.value
                    )
                );
    
                return {
                    ...combination,
                    SKU: matchingOption ? matchingOption.SKU : '',
                    image: matchingOption ? `/uploads/productImages/${matchingOption.image}` : null,
                    uniquePrice: matchingOption ? matchingOption.productPrice : variantFormData.DefaultValues.productPrice,
                    uniqueShippingPrice: matchingOption ? matchingOption.shippingPrice : variantFormData.DefaultValues.shippingPrice,
                    uniqueProcessingTime: matchingOption ? matchingOption.processingTime : variantFormData.DefaultValues.processingTime,
                    uniqueShippingTime: matchingOption ? matchingOption.shippingTime : variantFormData.DefaultValues.shippingTime,
                    unique: !!matchingOption,
                    include: true
                };
            } else {
                return {
                    ...combination,
                    SKU: '',
                    image: null,
                    uniquePrice: variantFormData.DefaultValues.productPrice,
                    uniqueShippingPrice: variantFormData.DefaultValues.shippingPrice,
                    uniqueProcessingTime: variantFormData.DefaultValues.processingTime,
                    uniqueShippingTime: variantFormData.DefaultValues.shippingTime,
                    unique: false,
                    include: true
                };
            }
        });
    
        return initialTableRows;
    };    

    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>{!quotationExists ? "Create" : "Edit"} Quotation</h1>
            <div className={styles.requestContainer}>
                <div className={styles.requestData}>
                {request.image && <div className={styles.requestImage}>
     <img src={`/uploads/productImages/${request.image}`} alt="Product Image"></img>
</div>}
<div className={styles.requestInfo}>
    <h2>Request</h2>
    {request.productName && (
        <p>
            <strong>Product name:</strong> {request.productName}
        </p>
    )}
    {request.description && (
        <p>
            <strong>Product description:</strong> {request.description}
        </p>
    )}
    {request.url && (
        <p>
            <strong>URL:</strong> <a href={request.url} target="_blank" rel="noopener noreferrer">{request.url}</a>
        </p>
    )}
    {request.createdAt && (
        <p>
            <strong>Date created:</strong> {new Date(request.createdAt).toLocaleDateString()}
        </p>
    )}
    {request.note && (
        <p>
            <strong>Note:</strong> {request.note}
        </p>
    )}
</div>

                </div>
            </div>
            <form onSubmit={handleFormSubmit} className={styles.form}>
                <div className={styles.hasVariants}>
                    <label>Has Variants:</label>
                    <Switch
                        checked={hasVariants}
                        onChange={() => {
                            if (hasVariants) {
                                if (Object.values(variantFormData.DefaultValues).some(val => val !== '')) {
                                    if (!window.confirm('You have unsaved data. Do you want to switch to No Variants and lose this data?')) {
                                        return;
                                    }
                                }
                                setVariantFormData({
                                    DefaultValues: {
                                        shippingPrice: '',
                                        processingTime: '',
                                        productPrice: '',
                                        shippingTime: '',
                                        comments: ''
                                    },
                                    Variants: {
                                        VariantCategories: [],
                                        UniquePricesAndTimes: []
                                    }
                                });
                                setFormErrors({})
                                setTableRows([]);
                            } else {
                                if (!Object.values(formData).every(val => val === '' || val === null)) {
                                    if (!window.confirm('You have unsaved data. Do you want to switch to Variants and lose this data?')) {
                                        return;
                                    }
                                }
                                setFormData({
                                    shippingPrice: '',
                                    processingTime: '',
                                    productPrice: '',
                                    shippingTime: '',
                                    image: null,
                                    SKU: '',
                                    comments: ''
                                });
                            }
                            setHasVariants(!hasVariants);
                        }}
                        color="primary"
                    />
                </div>
                {hasVariants ? (
                    <>
                        <div className={styles.basicFormContainer}>
                            <div className={styles.timeAndPrices}>
                                <label>
                                    Default Product Price:
                                    <div className={styles.inputWithPrefix}>
                                        <span className={styles.prefix}>$</span>
                                        <input type="number" name="productPrice" value={variantFormData.DefaultValues.productPrice} onChange={handleInputChange} min="0"/>
                                    </div>
                                </label>
                                <label>
                                    Default Shipping Price:
                                    <div className={styles.inputWithPrefix}>
                                        <span className={styles.prefix}>$</span>
                                        <input type="number" name="shippingPrice" value={variantFormData.DefaultValues.shippingPrice} onChange={handleInputChange} min="0"/>
                                    </div>
                                </label>
                                <label>
                                    Default Shipping Time:
                                    <div className={styles.inputWithPrefix}>
                                        <span className={styles.prefix}>day span</span>
                                        <input type="text" name="shippingTime" value={variantFormData.DefaultValues.shippingTime} onChange={handleInputChange}/>
                                    </div>
                                </label>
                                <label>
                                    Default Processing Time:
                                    <div className={styles.inputWithPrefix}>
                                        <span className={styles.prefix}>day span</span>
                                        <input type="text" name="processingTime" value={variantFormData.DefaultValues.processingTime} onChange={handleInputChange}/>
                                    </div>
                                </label>
                            </div>
                            <label>
                                Note
                                <textarea name="comments" value={variantFormData.DefaultValues.comments} onChange={handleInputChange}></textarea>
                            </label>
                        </div>
                        <div className={styles.variantbuttonGroup}>
                        <button type="button" onClick={handleAddCategory} className={styles.addEditVariantsButton}>
                            {variantFormData.Variants.VariantCategories.length > 0 ? "Edit Variants" : "Add Variants"}
                        </button>
                        {variantFormData.Variants.VariantCategories.length > 0 &&
                                                <button type="button" onClick={() => setGroupImageModal(true)} className={styles.addCategoryImagesButton}> Set images by group                                                
                                                </button>
                                            }
                        {formErrors.quotationcategory &&
                            <p className={styles.variantError}>You need atleast one variant</p>
                        }
                        </div>
                        {variantFormData.Variants.VariantCategories.length > 0 && (
                            <div className={styles.categoryTable}>
                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th>Include</th>
                                            {variantFormData.Variants.VariantCategories.map((quotationcategory, index) => (
                                                <th key={index}>{quotationcategory.name}</th>
                                            ))}
                                            <th>SKU</th>
                                            <th>Image</th>
                                            <th>Unique Price & Processing Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRows.map((row, index) => (
                                            <tr key={index} className={row.include && formErrors.table && formErrors.table[index]?.priceAndTime ? styles.errorRow : ''}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={row.include}
                                                        className={styles.includeBox}
                                                        onChange={() => {
                                                            const updatedRows = [...tableRows];
                                                            updatedRows[index].include = !updatedRows[index].include;
                                                            setTableRows(updatedRows);
                                                        }}
                                                    />
                                                </td>
                                                {Object.values(row).slice(0, variantFormData.Variants.VariantCategories.length).map((value, i) => (
                                                    <td key={i}>{value}</td>
                                                ))}
                                                <td>
                                                    <input 
                                                        type="text" name={`SKU-${index}`} value={row.SKU} onChange={(e) => {
                                                        const updatedRows = [...tableRows];
                                                        updatedRows[index].SKU = e.target.value;
                                                        setTableRows(updatedRows);
                                                    }} required={row.include} />
                                                </td>
                                                <td>
    <input
        type="file"
        name={`image-${index}`}
        onChange={(e) => handleVariantFileChange(index, e)}
        accept="image/*"
        required={row.include}
        ref={el => fileInputRefs.current[index] = el}
    />
</td>

                                                <td>
                                                    <button type="button" onClick={() => handleSetUnique(index)} className={`${styles.setUnique} ${row.include && formErrors.table && formErrors.table[index]?.priceAndTime ? styles.errorUnique : ''}`}>
                                                        {row.unique ? "Edit" : "Set"}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </>
                ) : (
                    <div className={styles.basicFormContainer}>
                        <div className={styles.timeAndPrices}>
                            <label>
                                Product Price
                                <span style={{ color: 'red' }}>*</span>
                                <div className={styles.inputWithPrefix}>
                                    <span className={styles.prefix}>$</span>
                                    <input type="number" name="productPrice" value={formData.productPrice} onChange={handleDefaultInputChange} required min="0"/>
                                </div>
                            </label>
                            <label>
                                Shipping Price
                                <span style={{ color: 'red' }}>*</span>
                                <div className={styles.inputWithPrefix}>
                                    <span className={styles.prefix}>$</span>
                                    <input type="number" name="shippingPrice" value={formData.shippingPrice} onChange={handleDefaultInputChange} required min="0"/>
                                </div>
                            </label>
                            <label>
                                Shipping Time
                                <span style={{ color: 'red' }}>*</span>
                                <div className={styles.inputWithPrefix}>
                                    <span className={styles.prefix}>day span</span>
                                    <input type="text" name="shippingTime" value={formData.shippingTime} onChange={handleDefaultInputChange} required/>
                                </div>
                            </label>
                            <label>
                                Processing Time
                                <span style={{ color: 'red' }}>*</span>
                                <div className={styles.inputWithPrefix}>
                                    <span className={styles.prefix}>day span</span>
                                    <input type="text" name="processingTime" value={formData.processingTime} onChange={handleDefaultInputChange} required/>
                                </div>
                            </label>
                        </div>
                        <label>
                            SKU
                            <span style={{ color: 'red' }}>*</span>
                            <input type="text" name="SKU" value={formData.SKU} onChange={handleDefaultInputChange} required/>
                        </label>
                        <label>
                            Product Image
                            <span style={{ color: 'red' }}>*</span>
                            <input
    type="file"
    name="image"
    onChange={handleFileChange}
    required
    accept="image/*"
    ref={nonVariantFileInputRef}
/>
                        </label>
                        <label>
                            Note
                            <textarea name="comments" value={formData.comments} onChange={handleDefaultInputChange}></textarea>
                        </label>
                    </div>
                )}
                <button type="submit" className={styles.submitButton}>View and Submit</button>
            </form>
            <Modal
    isOpen={showModal}
    onRequestClose={() => setShowModal(false)}
    className={styles.modaltwo}
    overlayClassName={styles.overlaytwo}
>
    <div className={styles.modalContentSubmit}>
        <h2>Confirm Your Quotation</h2>
        <p>{modalData &&  variantFormData.DefaultValues.comments && "Comment: " + variantFormData.DefaultValues.comments}</p>
        <p>{modalData && modalData.comments && "Comment: " + modalData.comments}</p>
        {modalData && hasVariants ? (
            <div className={styles.variantsContainer}>
                {modalData.variants.map((variant, index) => (
                    <div key={index} className={styles.variantModalItem}>
                        <div className={styles.imageContainer}>
                            {variant.image && <img src={URL.createObjectURL(variant.image)} alt={`Variant ${index}`} />}
                        </div>
                        <div className={styles.detailsContainer}>
                        {Object.keys(variant).map((key) => {
                                if (key !== 'image') {
                                    return (
                                        <p key={key}><strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {variant[key]}</p>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                ))}
            </div>
        ) : modalData ? (
            <div className={styles.nonVariantContainer}>
                <div className={styles.variantModalItem}>
                <div className={styles.imageContainer}>
                    {modalData.image && <img src={URL.createObjectURL(modalData.image)} alt="Product" />}
                </div>
                    <div className={styles.detailsContainer}>
                    <p><strong>SKU:</strong> {modalData.SKU}</p>
                    <p><strong>Product Price:</strong> ${modalData.productPrice}</p>
                    <p><strong>Shipping Price:</strong> ${modalData.shippingPrice}</p>
                    <p><strong>Processing Time:</strong> {modalData.processingTime}</p>
                    <p><strong>Shipping Time:</strong> {modalData.shippingTime}</p>
                    </div>
                </div>
            </div>
        ) : (
            <p>Loading...</p>
        )}
        <div className={styles.buttonGroup}>
            <button onClick={handleModalConfirm} className={styles.submitButton}>Submit {quotationExists && "Edit"} </button>
            <button onClick={() => setShowModal(false)} className={styles.cancelButton}>Cancel</button>
        </div>
    </div>
</Modal>



            <Modal
                isOpen={showCategoryModal}
                onRequestClose={() => setShowCategoryModal(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.modalContent}>
                    <h2>{variantList.length > 0 ? "Edit Variants" : "Add Variants"}</h2>
                    <label>
                        Variant QuotationCategory Name
                        <input
                            placeholder={variantList.length === 3 ? "Maximum of 3 Variant Titles" : "Enter Variant Title"}
                            type="text"
                            value={newVariantName}
                            onChange={(e) => setNewVariantName(e.target.value)}
                            className={`${styles.categoryInput} ${variantNameError ? styles.error : ''}`}
                            disabled={variantList.length === 3}
                        />
                        {variantNameError && <span className={styles.errorText}>{variantNameError}</span>}
                    </label>
                    <label>
                        Variants
                        <div className={`${styles.variantInputContainer} ${variantValueError ? styles.error : ''}`}>
                            {newVariants.map((variant, index) => (
                                <div key={index} className={styles.variantItem}>
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveVariantFromModal(index)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                    {variant}
                                </div>
                            ))}
                            <input
                                type="text"
                                id="variant"
                                name="variant"
                                value={newVariantValue}
                                onChange={(e) => setNewVariantValue(e.target.value)}
                                onKeyDown={handleAddVariantValue}
                                placeholder={variantList.length === 3 ? "Maximum of 3 Variant Titles" : "Enter Variant Value"}
                                className={styles.variantInput}
                                ref={variantValueInputRef}
                                disabled={variantList.length === 3}
                            />
                        </div>
                        {variantValueError && <span className={styles.errorText}>{variantValueError}</span>}
                    </label>
                    <button type="button" onClick={handleSaveCategory} className={styles.addVariantButton} disabled={variantList.length === 3}>
                        Add Variant quotationcategory
                    </button>
                    <div className={styles.addedVariantsContainer}>
                        {variantList.map((variant, index) => (
                            <div key={index} className={styles.addedVariants}>
                                <span><FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => handleRemoveCategoryFromVariantList(index)}
                                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                                /></span>
                                <p><strong>{variant.name + ": "} </strong> {variant.values.join(', ')}</p>
                            </div>
                        ))}
                    </div>
                    <div className={styles.buttonGroup}>
                        <button type="button" onClick={handleSubmitVariants} className={styles.submitButton}>
                            Submit
                        </button>
                        <button type="button" onClick={() => setShowCategoryModal(false)} className={styles.cancelButton}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
    isOpen={groupImageModal}
    onRequestClose={() => setGroupImageModal(false)}
    className={styles.modal}
    overlayClassName={styles.overlay}
>
    <h2>Add image by group</h2>
    <div className={styles.groupImageModalContent}>
        <FormControl fullWidth size="small">
            <InputLabel>Select QuotationCategory</InputLabel>
            <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Select QuotationCategory"
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {variantList.map((variant, index) => (
                    <MenuItem key={index} value={variant.name}>
                        {variant.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        {selectedCategory && (
            <div>
                <div className={styles.valueImageContainer}>
                {variantList
                    .find((variant) => variant.name === selectedCategory)
                    .values.map((value, index) => (
                        <div key={index} className={styles.groupImageAdd}>
                            <span><strong>{value}:</strong></span>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleGroupFileChange(value, e)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )}
    </div>
    <div className={styles.buttonGroup}>
        <button className={styles.submitButton} onClick={applyGroupImagesToVariants}>Submit</button>
        <button className={styles.cancelButton} onClick={() => setGroupImageModal(false)}>Cancel</button>
    </div>
</Modal>






            <Modal
                isOpen={showUniqueModal}
                onRequestClose={() => setShowUniqueModal(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.modalContent}>
                    <h2>Set Unique Price & Processing Time</h2>
                    <label>
                        <span style={{ color: 'red' }}>*</span> Product Price:
                        <input
                            required
                            type="number"
                            min="0"
                            name="uniqueProductPrice"
                            value={uniqueData.productPrice}
                            onChange={(e) => setUniqueData({ ...uniqueData, productPrice: e.target.value })}
                        />
                    </label>
                    <label>
                        <span style={{ color: 'red' }}>*</span> Shipping Price:
                        <input
                            required
                            type="number"
                            min="0"
                            name="uniqueShippingPrice"
                            value={uniqueData.shippingPrice}
                            onChange={(e) => setUniqueData({ ...uniqueData, shippingPrice: e.target.value })}
                        />
                    </label>
                    <label>
                        <span style={{ color: 'red' }}>*</span> Processing Time:
                        <input
                            required
                            type="text"
                            name="uniqueProcessingTime"
                            value={uniqueData.processingTime}
                            onChange={(e) => setUniqueData({ ...uniqueData, processingTime: e.target.value })}
                        />
                    </label>
                    <label>
                        <span style={{ color: 'red' }}>*</span> Shipping Time:
                        <input
                            required
                            type="text"
                            name="uniqueShippingTime"
                            value={uniqueData.shippingTime}
                            onChange={(e) => setUniqueData({ ...uniqueData, shippingTime: e.target.value })}
                        />
                    </label>
                    <div className={styles.buttonGroup}>
                        <button type="button" onClick={handleUniqueConfirm} className={styles.submitButton}>
                            Confirm
                        </button>
                        <button type="button" onClick={() => setShowUniqueModal(false)} className={styles.cancelButton}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CreateQuotation;
