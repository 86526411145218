import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Support.module.scss';
import userService from '../../services/userService';

function Support() {
    const [tickets, setTickets] = useState([]);
    const [visibleMessageId, setVisibleMessageId] = useState(null); // Håller koll på vilken ticket's meddelande som ska visas

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const response = await userService.fetchUserTickets();
                setTickets(response || []);
            } catch (error) {
                console.error('Failed to fetch tickets:', error);
            }
        };

        fetchTickets();
    }, []);

    const handleToggleMessage = (ticketId) => {
        setVisibleMessageId(visibleMessageId === ticketId ? null : ticketId);
    };

    const handleCreateTicket = () => {
        navigate('./create-ticket'); // Navigera till sidan för att skapa en ny ticket
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
            <h2>Support Tickets</h2>
            </div>
            <button className={styles.createTicketButton} onClick={handleCreateTicket}>
                {tickets.length === 0 ? 'No tickets found, create one' : 'Create New Ticket'}
            </button>
            {tickets.length !== 0 &&
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
    <thead>
        <tr>
            <th className={styles.ticketIdColumn}>Ticket_ID</th>
            <th className={styles.subjectColumn}>Subject</th>
            <th className={styles.messageColumn}>Message</th>
        </tr>
    </thead>
    <tbody>
        {tickets.map(ticket => (
            <React.Fragment key={ticket.id}>
                <tr>
                    <td className={styles.ticketIdColumn}>{ticket.id}</td>
                    <td className={styles.subjectColumn}>{ticket.subject}</td>
                    <td className={styles.messageColumn}>
                        <button onClick={() => handleToggleMessage(ticket.id)} className={styles.toggleBtn}>
                            {visibleMessageId === ticket.id ? '▲' : '▼'}
                        </button>
                    </td>
                </tr>
                {visibleMessageId === ticket.id && (
                    <tr className={styles.messageRow}>
                        <td colSpan="3">{ticket.message}</td>
                    </tr>
                )}
            </React.Fragment>
        ))}
    </tbody>
</table>

                </div>
            }
        </div>
    );
}

export default Support;
