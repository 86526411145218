import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import styles from './StoreManagement.module.scss';
import Modal from 'react-modal';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; 
import ToolTip from '../../components/ToolTip'; 

Modal.setAppElement('#root');

function StoreManagement() {
    useEffect(() => {
        document.title = 'Stores | Qiwi';
      }, []);

    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState(false); 
    const [loadingExpire, setLoadingExpire] = useState(false); 
    const [loadingModal, setLoadingModal] = useState(false); 
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [storeToDeleteOrders, setStoreToDeleteOrders] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [shopDomain, setShopDomain] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await merchantService.getStores();
                setStores(response || []);
            } catch (error) {
                console.error('Failed to fetch stores:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStores();
    }, []);

    const deleteStoreModal = async (store) => {
        setSelectedStore(store);
        setLoadingModal(true);
        try {
            const response = await merchantService.storeDeleteStatus(store.id);
            setStoreToDeleteOrders(response.orders || []);
        } catch (error) {
            console.error('Failed to fetch store orders:', error);
        } finally {
            setLoadingModal(false);
            setIsDeleteModalOpen(true);
        }
    };

    const expireStore = async () => {
        const confirmed = window.confirm("Are you sure you want to expire this store? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true);
                await merchantService.expireStore(selectedStore.id);
                alert("Store expired successfully.");
                setIsDeleteModalOpen(false);
                window.location.reload();
            } catch (error) {
                console.error("Error expiring store:", error);
                alert("Failed to expire store.");
            } finally {
                setLoadingExpire(false);
            }
        }
    };

    const deleteStore = async () => {
        if (window.confirm('Are you sure you want to delete this store? This action cannot be undone.')) {
            try {
                setLoadingDelete(true);
                await merchantService.deleteStore(selectedStore.id);
                setStores(stores.filter(store => store.id !== selectedStore.id));
                alert("Store deleted successfully.");
                setIsDeleteModalOpen(false);
            } catch (error) {
                console.error('Error deleting store:', error);
                alert('Failed to delete store');
            } finally {
                setLoadingDelete(false);
            }
        }
    };

    const handleConnectStore = async () => {
        const response = await merchantService.fetchInstallationLink(shopDomain);
        window.open(response.installUrl, '_blank');
        setShowModal(false);
    };

    const calculateHoursLeft = (expiringDate) => {
        const currentTime = new Date();
        const expirationTime = new Date(expiringDate);
        const differenceInMilliseconds = expirationTime - currentTime;
        const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        return differenceInHours;
    };

    return (
        <div className={styles.storeManagement}>
            <div className={styles.header}>
                <h1>Store Management</h1>
            </div>
            <div className={styles.storeManagementContent}>
                {stores.length !== 0 ? (
                    <>
                        {/*<div className={styles.btncontainer}>
                            <button onClick={() => setShowModal(true)} className={styles.createButton}>Connect New Shopify Store</button>
                        </div>*/}
                        <div className={styles.tableContainer}>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Shop Name</th>
                                        <th>Active</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading ? stores.map(store => (
                                        <tr key={store.id} className={styles.rows}>
                                            <td>{store.id}</td>
                                            <td>{store.shop.replace('.myshopify.com', '')}</td>
                                            <td>{store.expiringDate ? 
                                                    <ToolTip content={`This store has uninstalled the app and will be deleted in ${calculateHoursLeft(store.expiringDate)} hours if there are no pending orders. If there are pending orders the store will be deleted until fulfilled or after 29-30 days from when the redact request. To prevent this, reinstall the app.`}>
                                                        <div className={styles.hourglass}>
                                                    <span className="material-symbols-outlined">hourglass_bottom</span>
                                                    {`${calculateHoursLeft(store.expiringDate)}h`}
                                                        </div>
                                                    </ToolTip>
                                                     :
                                                (store.expiring ?
                                                    <div className={styles.expiring}>
                                                        <span className="material-symbols-outlined">hourglass_bottom</span>
                                                    </div> :
                                                    <div className={styles.checked}>
                                                        <span className="material-symbols-outlined">check_circle</span>
                                                    </div>
                                                )}
                                            </td>
                                            <td>{new Date(store.createdAt).toISOString().split('T')[0]}</td>
                                            <td className={styles.actions}>
                                                <button className={styles.deleteButton} onClick={() => deleteStoreModal(store)} disabled={store.expiring}>Delete</button>
                                            </td>
                                        </tr>
                                    )) :
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                            <td colSpan={5}></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : !loading &&
                
                //<button onClick={() => setShowModal(true)} className={styles.createButton}>No Shopify Store found, Connect One</button>
                <p className={styles.connectFirst}>No store connected, download from Shopify App Store and connect your first!</p>
                }
            </div>

            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className={styles.modaltwo}
                overlayClassName={styles.overlay}
            >
                <h2>Deleting Store</h2>
                {loadingModal ? (
                    <div className={styles.spinnerModal}><LoadingSpinner /></div>
                ) : storeToDeleteOrders && storeToDeleteOrders.length > 0 ? (
                    <>
                        <div className={styles.warningMessage}>
                            <span className="material-symbols-outlined">warning</span>
                            <p><strong>Warning: There are existing orders pending for this store. The store will be set to expiring until these orders are fulfilled.</strong></p>
                        </div>
                        <p>When you delete this store, the app will not be automatically uninstalled, and products sourced from Qiwi will not be removed from your Shopify store. Please ensure you manually set these products to "Draft" or remove them from your store.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeToDeleteOrders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.buttons}>
                        <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                        <button className={styles.expiringButton} onClick={expireStore} disabled={loadingExpire}>
                            {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
                        </button>
                        </div>
                    </>
                ) : (
                    <>
                        <p><strong>Are you sure you want to delete this store? This action cannot be undone.</strong></p>
                        <p>When you delete this store, the app will not be automatically uninstalled, and products sourced from Qiwi will not be removed from your Shopify store. Please ensure you manually set these products to "Draft" or remove them from your store.</p>
                        <div className={styles.buttons}>
                        <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                        <button className={styles.deleteButtonModal} onClick={deleteStore} disabled={loadingDelete}>
                            {loadingDelete ? <LoadingButtonDots /> : 'Yes, delete this store'}
                        </button>
                        </div>
                    </>
                )}
            </Modal>

            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.content}>
                    <h4>Connect Shopify Store</h4>
                    <div className={styles.domainInput}>
                        <p>https://</p>
                        <input
                            type="text"
                            value={shopDomain}
                            onChange={(e) => setShopDomain(e.target.value)}
                            className={styles.inputField}
                        />
                        <p>.myshopify.com</p>
                    </div>
                    <div className={styles.buttons}>
                        <button onClick={handleConnectStore} className={styles.connectButton}>Connect Store</button>
                        <button onClick={() => setShowModal(false)} className={styles.closeButton}>Close</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default StoreManagement;
