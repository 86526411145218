import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import styles from './CreateInvoice.module.scss';

const CreateInvoice = () => {
    const [invoices, setInvoices] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState({}); // Nytt tillstånd för att lagra valda filer

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const fetchedInvoices = await adminService.generateInvoices();
                setInvoices(fetchedInvoices);
            } catch (error) {
                console.error('Failed to fetch invoices:', error);
            }
        };

        fetchInvoices();
    }, []);

    const handleFileChange = (invoiceId, file) => {
        setSelectedFiles(prevFiles => ({
            ...prevFiles,
            [invoiceId]: file
        }));
    };

    const handleConfirmInvoice = async (invoice) => {
        const { totalAmount, invoiceAccount, orders, id } = invoice; // Se till att fakturan har ett unikt ID
        const orderIds = orders.map(order => order.orderId);
        const pdfFile = selectedFiles[id]; // Hämta den valda PDF-filen för denna faktura

        if (!pdfFile) {
            alert('Vänligen välj en PDF-fil innan du bekräftar fakturan.');
            return;
        }

        const invoiceData = {
            totalAmount,
            invoiceAccountId: invoiceAccount.id,
            orderIds
        };

        try {
            await adminService.confirmInvoiceSent(invoiceData, pdfFile); // Skicka fakturadata och PDF-fil
            window.location.reload();
        } catch (error) {
            console.error('Failed to confirm invoice:', error);
        }
    };

    return (
        <div className={styles.invoiceContainer}>
            <h1>Generated Invoices</h1>
            {invoices.length > 0 ? (
                invoices.map((invoice, index) => (
                    <div key={index} className={styles.invoice}>
                        <div className={styles.invoiceHeader}>
                            <h2>Merchant: {invoice.invoiceAccount.companyName}</h2>
                            <div className={styles.fileUpload}>
                            <label htmlFor={`file-${invoice.id}`}>Upload PDF:</label>
                            <input 
                                type="file" 
                                id={`file-${invoice.id}`} 
                                accept="application/pdf"
                                onChange={(e) => handleFileChange(invoice.id, e.target.files[0])}
                            />
                        </div>
                            <button 
                                className={styles.confirmButton} 
                                onClick={() => handleConfirmInvoice(invoice)}
                            >
                                Confirm Invoice Sent
                            </button>
                            
                        </div>
                        <p><strong>Invoice Status:</strong> {invoice.status}</p>
                        <p><strong>Total Amount:</strong> ${invoice.totalAmount}</p>
                        <p><strong>Created At:</strong> {new Date(invoice.createdAt).toLocaleString()}</p>
                        <h3>Invoice Account Details:</h3>
                        <p><strong>Org Number:</strong> {invoice.invoiceAccount.orgNumber}</p>
                        <p><strong>VAT Number:</strong> {invoice.invoiceAccount.vatNumber}</p>
                        <p><strong>Address:</strong> {invoice.invoiceAccount.address}</p>
                        <p><strong>Phone Number:</strong> {invoice.invoiceAccount.phoneNumber}</p>
                        <p><strong>Email:</strong> {invoice.invoiceAccount.email}</p>
                        <p><strong>Contact Person:</strong> {invoice.invoiceAccount.contactPerson}</p>
                        <h3>Orders:</h3>
                        {invoice.orders.map((order, orderIndex) => (
                            <div key={orderIndex} className={styles.order}>
                                <p><strong>Order ID:</strong> {order.orderId}</p>
                                <p><strong>Shopify ID:</strong> {order.shopifyId}</p>
                                <p><strong>Order Status:</strong> {order.orderStatus}</p>
                                <p><strong>Store Name:</strong> {order.storeName}</p>
                                <p><strong>Created At:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                                <h4>Items:</h4>
                                {order.items.map((item, itemIndex) => (
                                    <div key={itemIndex} className={styles.item}>
                                        <p><strong>Product Name:</strong> {item.productName}</p>
                                        <p><strong>SKU:</strong> {item.SKU || 'N/A'}</p>
                                        <p><strong>Store Name:</strong> {item.storeName}</p>
                                        <p><strong>Quantity:</strong> {item.quantity}</p>
                                        <p><strong>Product Price:</strong> ${item.productPrice}</p>
                                        <p><strong>Shipping Price:</strong> ${item.shippingPrice}</p>
                                        <p><strong>Supplier Name:</strong> {item.supplierName}</p>
                                        <p><strong>Total:</strong> ${item.total}</p>
                                        <h5>Variant Details:</h5>
                                        {item.Variant.length > 0 ? (
                                            item.Variant.map((variant, variantIndex) => (
                                                <p key={variantIndex}>{variant.name}: {variant.value}</p>
                                            ))
                                        ) : (
                                            <p>No variants</p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <p>No invoices available.</p>
            )}
        </div>
    );
};

export default CreateInvoice;
