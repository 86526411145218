import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import HoverableOverflowText from '../../components/HoverableOverflowText';
import styles from './Requests.module.scss';
import Modal from 'react-modal';

const SourcingRequests = () => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("Pending");
    const [dateRange, setDateRange] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
    const [quotationsData, setQuotationsData] = useState([]);
    const [currentQuotationIndex, setCurrentQuotationIndex] = useState(0);
    const [showArchived, setShowArchived] = useState(false);
    const navigate = useNavigate();


    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setCurrentPage(1);
    };

    const handlePrevious = () => {
        if (currentQuotationIndex > 0) {
            setCurrentQuotationIndex(currentQuotationIndex - 1);
        }
    };
    
    const handleNext = () => {
        if (currentQuotationIndex < quotationsData.length - 1) {
            setCurrentQuotationIndex(currentQuotationIndex + 1);
        }
    };

    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Responded', label: 'Responded' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'In Products', label: 'In Products' },
        { value: 'In Store', label: 'In Store' },
    ];

    const applyFilters = () => {
        fetchRequests();
    };

    const fetchRequests = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchRequestsByPageAndFilters(statusFilter, currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived);
            setData(response.rows || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
        }
    };

    const handleViewQuotations = (requestId) => {
        navigate(`/admin/sourcing-requests/quotation-details/${requestId}`);
    };

    useEffect(() => {
        fetchRequests();
    }, [currentPage, itemsPerPage, showArchived]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (filter) => {
        setStatusFilter(filter);
        setCurrentPage(1); // Reset to first page when filter changes
    };

    const openQuotationListDetails = async (request) => {
        try{
            const quotations = await adminService.fetchQuotationDetails(request.id, showArchived)
            setQuotationsData(quotations.quotations)
            setIsQuotationModalOpen(true)
        } catch(error) {
            console.error('Failed to fetch data:', error);
        }
    }

    const archiveRequest = async (requestId) => {
        const confirmed = window.confirm('Are you sure you want to archive this request? This action cannot be undone.');
    
        if (confirmed) {
            try {
                const response = await adminService.archiveRequest(requestId);
                if (response) {
                    fetchRequests()
                }
            } catch (error) {
                console.error('Failed to archive request:', error);
            }
        }
    };
    

    const closeModal = () => {
        setIsQuotationModalOpen(false);
        setCurrentQuotationIndex(0);
    }

    return (
        <div className={styles.container}>
                        <div className={styles.header}>
            <h1>Requests</h1>
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent            
                    backgroundColor='white'
                    statusFilter={statusFilter}
                    setSelectFilter={handleFilterChange}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    statusOptions={statusOptions}
                    searchPlaceholder="Search by product name..."
                    includeSearch={true}
                    selectLabel="Status"
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                    applyFilters={applyFilters}
                />
            </div>
            <div className={styles.tableContainer}>
            <table className={styles.table}>
                                    <thead>
                        <tr>
                            <th onClick={() => handleSort('productName')}>
                                <div className={styles.thHeader}>
                                    Product
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'productName' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'productName' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('url')}>
                                <div className={styles.thHeader}>
                                    URL
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'url' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'url' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Merchant</th>
                            <th>Note</th>
                            <th>Status</th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Created At
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Quotations</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(request => (
                            <tr key={request.id} className={styles.rows}>
                                <td className={styles.productInfo}>
                                    {request.image && <img src={`/uploads/productImages/${request.image}`} className={styles.requestImage} onClick={() => viewImage(`/uploads/productImages/${request.image}`)} alt="Request Image"/>}
                                    <div>
                                    {request.productName}
                                    {request.description && (
                                        <>
                                            <br />
                                            <HoverableOverflowText text={request.description}/>
                                        </>
                                    )}
                                    </div>
                                </td>
                                <td>
                                    {request.url}
                                </td>
                                <td>{request.merchantName}</td>
                                <td><HoverableOverflowText text={request.note}/></td>
                                <td>{request.status}</td>
                                <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                                <td>{request.numberResponses > 0 && <button onClick={() => openQuotationListDetails(request)} className={styles.viewQuotations}>{request.numberResponses}</button>}</td>
                                <td className={styles.buttons}>
                                {!showArchived &&
                                <>
                                    {request.status === 'Pending' &&
                                    <button onClick={() => handleViewQuotations(request.id)} className={styles.markupQuotations}>Markup & Send</button>
                                    }
                                    <button onClick={() => archiveRequest(request.id)} className={styles.archiveButton}>Archive</button>
                                    </>
                                }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <img src={selectedImage} alt="Selected Request Image" className={styles.largeImage} />
            </Modal>

            <Modal
    isOpen={isQuotationModalOpen}
    onRequestClose={closeModal}
    contentLabel="Quotations"
    className={styles.modaltwo}
    overlayClassName={styles.overlay}
>
    {currentQuotationIndex > 0 && (
        <button 
            onClick={handlePrevious} 
            className={styles.navButtonOne}
        >
            &lt;
        </button>
    )}
    <div className={styles.quotationsModal}>
        {quotationsData && isQuotationModalOpen && (
            <div className={styles.navigationButtons}>
                <h5>Quotation by {quotationsData[currentQuotationIndex].supplierName}</h5>
                {quotationsData[currentQuotationIndex].variants ? (
                    <div className={styles.modalTableContainer}>
                        <table className={styles.detailTable}>
                            <thead>
                                <tr className={styles.table_header}>
                                    <th>Image</th>
                                    {quotationsData[currentQuotationIndex].variants[0].categoryVariants.map((quotationCategoryVariant, index) => (
                                        <th key={index}>{quotationCategoryVariant.quotationcategory}</th>
                                    ))}
                                    <th>SKU</th>
                                    <th>Product Price</th>
                                    <th>Shipping Price</th>
                                    <th>Shipping Time</th>
                                    <th>Processing Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quotationsData[currentQuotationIndex].variants.map((variant, index) => (
                                    <tr key={index} className={styles.rows}>
                                        <td>
                                            <img src={variant.image ? `/uploads/productImages/${variant.image}` : 'https://cdn3.iconfinder.com/data/icons/design-n-code/100/272127c4-8d19-4bd3-bd22-2b75ce94ccb4-512.png'} alt={`Variant ${index + 1}`} className={styles.quotationImage}></img>
                                        </td>
                                        {variant.categoryVariants.map((quotationCategoryVariant, catIndex) => (
                                            <td key={catIndex}>{quotationCategoryVariant.value}</td>
                                        ))}
                                        <td>{variant.SKU}</td>
                                        <td>${variant.productPrice}</td>
                                        <td>${variant.shippingPrice}</td>
                                        <td>{variant.shippingTime}</td>
                                        <td>{variant.processingTime}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className={`${styles.tableContainer} ${styles.modalTableContainer}`}>
                        <table className={styles.detailTable}>
                            <thead>
                                <tr className={styles.table_header}>
                                    {quotationsData[currentQuotationIndex].image && <th>Product Image</th>}
                                    <th>SKU</th>
                                    <th>Product Price</th>
                                    <th>Shipping Price</th>
                                    <th>Shipping Time</th>
                                    <th>Processing Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={styles.rows}>
                                    {quotationsData[currentQuotationIndex].image && (
                                        <td>
                                            <img src={`/uploads/productImages/${quotationsData[currentQuotationIndex].image}`} alt={quotationsData[currentQuotationIndex].productName} className={styles.quotationImage} />
                                        </td>
                                    )}
                                    <td>{quotationsData[currentQuotationIndex].noVariant.SKU}</td>
                                    <td>${quotationsData[currentQuotationIndex].noVariant.productPrice}</td>
                                    <td>${quotationsData[currentQuotationIndex].noVariant.shippingPrice}</td>
                                    <td>{quotationsData[currentQuotationIndex].noVariant.shippingTime}</td>
                                    <td>{quotationsData[currentQuotationIndex].noVariant.processingTime}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        )}
    </div>
    {currentQuotationIndex < quotationsData.length - 1 && (
        <button 
            onClick={handleNext} 
            className={styles.navButtonTwo}
        >
            &gt;
        </button>
    )}
</Modal>

        </div>
    );
};

export default SourcingRequests;
