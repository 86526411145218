import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import HoverableList from '../../components/HoverableList';
import styles from './Merchants.module.scss';
import Modal from 'react-modal';

const Merchants = () => {
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState({});
    const [showArchived, setShowArchived] = useState(false);
    const [selectedMerchantToDelete, setSelectedMerchantToDelete] = useState({});
    const [selectedMerchantToDeleteOrders, setSelectedMerchantToDeleteOrders] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const deleteMerchantModal = async (merchant) => {
        const orders = await adminService.merchantDeleteStatus(merchant.id);
        setSelectedMerchantToDelete(merchant);
        setSelectedMerchantToDeleteOrders(orders.orders);
        setIsDeleteModalOpen(true);
    }

    const toggleItemDetails = async (merchantId) => {
        if (visibleUsers[merchantId]) {
            setVisibleUsers(prev => ({
                ...prev,
                [merchantId]: false
            }));
        } else {
            setVisibleUsers(prev => ({
                ...prev,
                [merchantId]: 'loading'
            }));
            try {
                const users = await adminService.fetchMerchantUsers(merchantId, showArchived);
                setData(prevMerchants => prevMerchants.map(merchant =>
                    merchant.id === merchantId ? { ...merchant, users } : merchant
                ));
            } catch (error) {
                console.error('Failed to fetch users:', error);
            } finally {
                setVisibleUsers(prev => ({
                    ...prev,
                    [merchantId]: true
                }));
            }
        }
    };

    const fetchMerchants = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchMerchantsByPageAndFilters(currentPage, itemsPerPage, startDate, endDate);
            setData(response.merchants || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchMerchants();
    }, [currentPage, itemsPerPage]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setCurrentPage(1);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };
    
    const expireMerchant = async (merchantId) => {
        const confirmed = window.confirm("Are you sure you want to expire this Merchant? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.expireMerchant(merchantId);
                alert("Merchant expired successfully.");
                fetchMerchants();
            } catch (error) {
                console.error("Error expiring Merchant:", error);
                alert("Failed to expire Merchant.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };
    
    const deleteMerchant = async (merchantId) => {
        const confirmed = window.confirm("Are you sure you want to delete this Merchant? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.merchantDelete(merchantId);
                alert("Merchant deleted successfully.");
                fetchMerchants();
            } catch (error) {
                console.error("Error deleting Merchant:", error);
                alert("Failed to delete Merchant.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };

    const createDetailRow = (merchantUsers) => {
        return (
            <td colSpan="7">
                <table>
                    <thead>
                        <tr>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th colSpan={5}>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {merchantUsers.map((user, index) => (
                            <tr key={`user-${user.id}-${index}`}>
                                <td>{user.id}</td>
                                <td>{user.email}</td>
                                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </td>
        );
    };

    const applyFilters = () => {
        fetchMerchants();
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Merchants</h1>
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent
                    backgroundColor='white'
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    applyFilters={applyFilters}
                    fetchData={fetchMerchants}
                    includeSearch={false}
                    selectLabel="Role"
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                />
            </div>
            {totalPages > 0 ? (
                <>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('id')}>
                                        <div className={styles.thHeader}>
                                            Merchant Id
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('name')}>
                                        <div className={styles.thHeader}>
                                            Name
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'name' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'name' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Stores</th>
                                    <th>Users</th>
                                    <th onClick={() => handleSort('createdAt')}>
                                        <div className={styles.thHeader}>
                                            Created At
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Active</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(merchant => (
                                    <React.Fragment key={merchant.id}>
                                            <tr
                                                className={visibleUsers[merchant.id] ? styles.droppeddown : styles.rows}
                                                >
                                            <td>{merchant.id}</td>
                                            <td>{merchant.name}</td>
                                            <td><HoverableList list={merchant.Stores.map(store => store.shop.replace('.myshopify.com', ''))}/></td>
                                            <td>
                                                <button
                                                    className={styles.toggleRowsButton}
                                                    onClick={() => toggleItemDetails(merchant.id)}
                                                >
                                                    {merchant.userCount}
                                                    <span className="material-symbols-outlined">
                                                        {visibleUsers[merchant.id] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                    </span>
                                                </button>
                                            </td>
                                            <td>{new Date(merchant.createdAt).toLocaleDateString()}</td>
                                            <td>{merchant.expiring ? 
                                    <div className={styles.hourglass}>
                                    <span className="material-symbols-outlined">
                                    hourglass_bottom
                                    </span>
                                    </div>
                                     :
                                     <div className={styles.checked}>
                                     <span className="material-symbols-outlined">
                                     check_circle
                                     </span>
                                     </div>
                                     }</td>
                                            <td>
                                            {!showArchived && !merchant.expiring && <button className={styles.deleteButton} onClick={() => deleteMerchantModal(merchant)}>Delete</button>}
                                            </td>
                                        </tr>
                                        {visibleUsers[merchant.id] === 'loading' ? (
                                            <tr className={styles.detailRow} key={`details-${merchant.id}`}>
                                                <td colSpan={5}>
                                                    <table>
                                                        <tbody>
                                                            {Array.from({ length: 3 }).map((_, index) => (
                                                                <tr key={index} className={`${styles.rows} ${styles.placeHolderDetailRow}`}>
                                                                    <td colSpan={5}></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        ) : visibleUsers[merchant.id] && merchant.users && (
                                            <tr className={styles.detailRow} key={`details-${merchant.id}`}>
                                                {createDetailRow(merchant.users)}
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                </>
            ) : (
                <p className={styles.noFound}>No merchants found</p>
            )}
             <Modal
    isOpen={isDeleteModalOpen}
    onRequestClose={() => setIsDeleteModalOpen(false)}
    className={styles.modal}
    overlayClassName={styles.overlay}
>
    <h2>Deleting Merchant</h2>
    <p>{selectedMerchantToDelete.name}</p>
    
    {selectedMerchantToDeleteOrders && selectedMerchantToDeleteOrders.length > 0 && (
        <>
        <p><strong>OBS. There are exisiting orders pending for this Merchant</strong></p>
        <table>
            <thead>
                <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {selectedMerchantToDeleteOrders.map(order => (
                    <tr key={order.orderId}>
                        <td>{order.orderId}</td>
                        <td>{order.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    )}
    <div className={styles.buttonGroup}>
    {selectedMerchantToDeleteOrders && selectedMerchantToDeleteOrders.length > 0 ? 
    <button className={styles.expiringButton} onClick={() => expireMerchant(selectedMerchantToDelete.id)}>Set as Expiring</button> :
    <button className={styles.deleteButtonModal} onClick={() => deleteMerchant(selectedMerchantToDelete.id)}> Delete</button>
    }
    </div>
</Modal>
        </div>
    );
};

export default Merchants;
