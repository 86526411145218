import React, { useEffect } from 'react';
import styles from './TermsOfService.module.scss';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
    useEffect(() => {
        document.title = 'Terms of Service | Qiwi';
    }, []);  

    return (
        <div className={styles.termscontainer}>
            <h1>Terms of Service</h1>

            <section>
                <h2>Introduction</h2>
                <p>
                    Welcome to Qiwi. These Terms of Service ("Terms") govern your use of our app and services. By accessing or using our app, you agree to comply with and be bound by these Terms.
                </p>
            </section>

            <section>
                <h2>Other Policies</h2>
                <p>
                    In addition to these Terms, your use of our services is also governed by our <Link to="/privacy-policy">Privacy Policy</Link> and <Link to="/data-protection-agreement">Data Protection Agreement</Link>. Please review these policies to understand our practices regarding data collection, usage, and protection.
                </p>
            </section>

            <section>
                <h2>Scope of Services</h2>
                <p>
                    Our app provides services related to sourcing, invoicing, product management, and order processing for merchants and suppliers. The specific features and functionalities available to you depend on your role (e.g., Merchant, Supplier).
                </p>
            </section>

            <section>
                <h2>User Responsibilities</h2>
                <h3>Merchants</h3>
                <ul>
                    <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                    <li><strong>Compliance:</strong> You must comply with all applicable laws and regulations related to your use of our services, including data protection laws.</li>
                    <li><strong>Data Accuracy:</strong> You are responsible for ensuring that all information you provide is accurate, complete, and up to date.</li>
                </ul>

                <h3>Suppliers</h3>
                <ul>
                    <li><strong>Order Fulfillment:</strong> You must fulfill orders accurately and on time, in accordance with the terms agreed upon with the merchant.</li>
                    <li><strong>Data Handling:</strong> You must handle customer data securely and in compliance with our Data Protection Agreement.</li>
                </ul>
            </section>

            <section>
                <h2>Limitation of Liability</h2>
                <p>
                    Qiwi is not liable for any indirect, incidental, or consequential damages arising out of or in connection with your use of our services. Our liability is limited to the amount you paid for the service.
                </p>
            </section>

            <section>
                <h2>Termination</h2>
                <p>
                    We reserve the right to suspend or terminate your account at any time if you violate these Terms or engage in activities that could harm our services or other users.
                </p>
            </section>

            <section>
                <h2>Changes to the Terms</h2>
                <p>
                    We may update these Terms from time to time. We will notify you of any significant changes by posting the updated Terms on our website or app.
                </p>
            </section>

            <section>
                <h2>Contact Information</h2>
                <p>
                    For any questions or concerns about these Terms, please contact us at Info@qiwifulfill.com.
                </p>
                            <div className={styles.contactInfo}>
                <p>Qiwi Support</p>
                <p>Rådmansgatan 6A</p>
                <p>114 25 Stockholm</p>
                <p>Sweden</p>
            </div>

            </section>

            <p className={styles.updatedDate}><em>Last updated: 30 Aug 2024</em></p>

            <Link to="/" className={styles.homeButton}>Go to Homepage</Link>
        </div>
    );
};

export default TermsOfService;
