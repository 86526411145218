import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate, Outlet } from 'react-router-dom';
import styles from './MerchantLayout.module.scss';
import authService from '../services/authService';

const MerchantLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Start with sidebar open
  const [showDropDown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [expiring, setExpiring] = useState(false);

  useEffect(() => {
    const storedExpiring = sessionStorage.getItem('expiring');
    if (storedExpiring !== null) {
      setExpiring(storedExpiring === 'true'); // Konvertera strängen till en boolean
    }
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropDown);
  };

  const logout = async () => {
    await authService.logout();
    navigate('/login');
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropDown]);

  return (
    <div className={styles.container}>
      <div className={`${styles.sidebar} ${sidebarOpen ? styles.open : styles.closed}`}>
        <div className={styles.header}>
          <h1>q<span>iwi</span></h1>
        </div>
        <div className={styles.sideMenu}>
          <ul>
            {/*<li className={styles.sideMenuItem}>
              <NavLink to="/dashboard" className={({ isActive }) => isActive ? styles.active : undefined} onClick={handleLinkClick}>
                <span className="material-symbols-outlined">dashboard</span>
                <p>Dashboard</p>
              </NavLink>
            </li>*/}
            <li className={styles.sideMenuItem}>
            <NavLink
  to="/sourcing"
  className={({ isActive }) =>
    isActive || window.location.pathname === '/' ? styles.active : undefined
  }
  onClick={handleLinkClick}
>
  <span className="material-symbols-outlined">travel_explore</span>
  <p>Sourcing</p>
</NavLink>

            </li>
            <li className={styles.sideMenuItem}>
              <NavLink to="/products" className={({ isActive }) => isActive ? styles.active : undefined} onClick={handleLinkClick}>
                <span className="material-symbols-outlined">inventory_2</span>
                <p>Products</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink to="/orders" className={({ isActive }) => isActive ? styles.active : undefined} onClick={handleLinkClick}>
                <span className="material-symbols-outlined">orders</span>
                <p>Orders</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink to="/store-management" className={({ isActive }) => isActive ? styles.active : undefined} onClick={handleLinkClick}>
                <span className="material-symbols-outlined">storefront</span>
                <p>Stores</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink to="/billing" className={({ isActive }) => isActive ? styles.active : undefined} onClick={handleLinkClick}>
                <span className="material-symbols-outlined">payments</span>
                <p>Billing</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.toggleSidebar} onClick={toggleSidebar}>
          <span className="material-symbols-outlined">
            chevron_left
          </span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.topnav}>
          <button className={styles.hamburger} onClick={toggleSidebar}>
            <span className="material-symbols-outlined">menu</span>
          </button>

          <h1>WOMBAT</h1>

          <ul className={styles.topMenu}>
          {expiring && <li className={`${styles.topMenuItem} ${styles.expiring}`}><span className="material-symbols-outlined">warning</span><p>Merchant expiring</p></li>}
            <li className={`${styles.topMenuItem} ${styles.support}`}>
              <NavLink to="/support" className={({ isActive }) => isActive ? styles.active : undefined}>
                <span className="material-symbols-outlined">support_agent</span>
                <p>Support</p>
              </NavLink>
            </li>
            <li className={styles.topMenuItem}>
              <button className={styles.profile} onClick={toggleDropdown}>
                <span className="material-symbols-outlined">person</span>
                <p>Profile</p>
              </button>
              <div ref={dropdownRef} className={`${styles.dropdown} ${showDropDown ? styles.dropdownOpen : ''}`}>
                <div className={styles.dropdownItem}>
                  <NavLink to="/settings">
                    <span className="material-symbols-outlined">settings</span>
                    Settings
                  </NavLink>    
                </div>

                <div className={styles.dropdownItem}>
                  <button onClick={logout}>
                    <span className="material-symbols-outlined">logout</span>
                    Logout
                  </button>
                </div>

              </div>
            </li>
          </ul>
        </div>
        <div className={styles.main}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MerchantLayout;
