import React from 'react';
import { Select, MenuItem, Pagination } from '@mui/material';
import styles from './PaginationComponent.module.scss';

const PaginationComponent = ({ itemsPerPage, setItemsPerPage, totalPages, currentPage, handlePageChange }) => {
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        handlePageChange(null, 1); // Reset to first page when items per page changes
    };
    return (
        <div className={styles.paginationSettings}>
            <p>Rows per page: </p>
            <Select
    value={itemsPerPage.toString()}
    disabled={itemsPerPage === 10 && totalPages === 1}
    onChange={handleItemsPerPageChange}
    sx={{
        color: "white",
        backgroundColor: "#202331",
        "& .MuiSelect-icon":{
            color: "white",
        },
        "& .MuiSelect-icon.Mui-disabled":{
            color: "grey",
        },
        height: "30px",
        width: "fit-content",
        border: "none",
        outline: "none",
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: "grey",
    }
    }}
>
    <MenuItem value={10}>10</MenuItem>
    <MenuItem value={20}>20</MenuItem>
</Select>


            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                disabled={totalPages === 1}

                sx={{
                    '& .MuiPaginationItem-root': {
                        color: '#202331',
                    },
                    '& .MuiPaginationItem-page.Mui-selected': {
                        backgroundColor: '#202331',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#202331',
                            color: '#202331',
                        },
                    },
                    "& .MuiPaginationItem-page.Mui-selected.Mui-disabled":{
                        color: "#202331",
                    },
                }}
            />
        </div>
    );
};

export default PaginationComponent;
