import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import merchantService from '../../services/merchantService';
import styles from './QuotationList.module.scss';
import Modal from 'react-modal';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import HoverableOverflowText from '../../components/HoverableOverflowText';
import LoadingButtonDots from '../../components/LoadingButtonDots';
import LoadingSpinner from '../../components/LoadingSpinner';  // Importera LoadingSpinner

Modal.setAppElement('#root');

const QuotationList = () => {
    useEffect(() => {
        document.title = 'Quotation List | Qiwi';
      }, []);
      
    const { requestId } = useParams();
    const [quotations, setQuotations] = useState([]);
    const [request, setRequest] = useState({});
    const [visibleVariants, setVisibleVariants] = useState({});
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedStoreToPublish, setSelectedStoreToPublish] = useState(null);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [stores, setStores] = useState([]);
    const [expiring, setExpiring] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingQuotationId, setLoadingQuotationId] = useState(null);
    const [loadingStores, setLoadingStores] = useState(true);  // Ny state för laddning av butiker
    const [publishing, setPublishing] = useState(false);  // Ny state för att spåra publicering

    useEffect(() => {
        const storedExpiring = sessionStorage.getItem('expiring');
        if (storedExpiring !== null) {
            setExpiring(storedExpiring === 'true');
        }
    }, []);

    useEffect(() => {
        fetchQuotationsAndRequest();
        fetchStores();
    }, [requestId]);

    const fetchQuotationsAndRequest = async () => {
        try {
            setLoading(true);
            const response = await merchantService.fetchQuotationsAndRequest(requestId);
            setQuotations(response.quotations);
            setRequest(response.request);
        } catch (error) {
            console.error('Failed to fetch request:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStores = async () => {
        try {
            setLoadingStores(true);  // Starta laddning av butiker
            const storesResponse = await merchantService.getStores();
            setStores(storesResponse);
        } catch (error) {
            console.error("Error fetching stores: ", error);
        } finally {
            setLoadingStores(false);  // Avsluta laddning av butiker
        }
    };

    const chooseQuotation = async (quotationId) => {
        try {
            setLoadingQuotationId(quotationId);
            await merchantService.confirmQuotation(quotationId, requestId);
            alert('Quotation confirmed');
            fetchQuotationsAndRequest();
        } catch (error) {
            alert('Failed confirmation');
            console.error('Error confirming quotation:', error);
        } finally {
            setLoadingQuotationId(null);
        }
    };

    const addToProducts = async (quotationId) => {
        try {
            setLoadingQuotationId(quotationId);
            await merchantService.addToProducts(quotationId, requestId);
            alert('Quotation added to products');
            fetchQuotationsAndRequest();
        } catch (error) {
            alert('Failed adding the product');
            console.error('Error adding to products:', error);
        } finally {
            setLoadingQuotationId(null);
        }
    };

    const handleSelectedStoreChange = (event) => {
        setSelectedStoreToPublish(event.target.value);
    };

    const publishNoVariant = async (quotationId, storeId) => {
        try {
            const storeToUse = storeId || selectedStoreToPublish;
            if (!storeToUse) {
                console.error('No store selected for publishing.');
                return;
            }

            setPublishing(true);  // Starta publiceringsladdning
            await merchantService.publishNoVariantProduct(quotationId, null, storeToUse);
            fetchQuotationsAndRequest();
            setIsPublishModalOpen(false);
        } catch (error) {
            console.error('Failed to publish no variant product:', error);
        } finally {
            setPublishing(false);  // Avsluta publiceringsladdning
        }
    };

    const publishWithVariants = async (quotationId, storeId, variantIds) => {
        try {
            const storeToUse = storeId || selectedStoreToPublish;
            setPublishing(true);  // Starta publiceringsladdning
            await merchantService.publishProductWithVariants(quotationId, null, storeToUse, variantIds);
            fetchQuotationsAndRequest();
            setIsPublishModalOpen(false);
        } catch (error) {
            console.error('Failed to publish product with variants:', error);
        } finally {
            setPublishing(false);  // Avsluta publiceringsladdning
        }
    };

    const publishToStore = async (quotationId) => {
        setSelectedQuotation(quotations.find(q => q.id === quotationId));
        setIsPublishModalOpen(true);
    };

    const toggleVariantVisibility = (quotationId) => {
        setVisibleVariants(prev => ({
            ...prev,
            [quotationId]: !prev[quotationId]
        }));
    };

    const hasConsistentValues = (variants, field) => {
        if (!variants || variants.length === 0) return false;
        return variants.every(variant => variant[field] === variants[0][field]);
    };

    const renderConsistentOrVariableValue = (variants, field) => {
        if (hasConsistentValues(variants, field)) {
            const value = variants[0][field];
    
            if (field === 'productPrice' || field === 'shippingPrice') {
                return `$${value}`; // Lägg till dollartecken
            }
            return value;
        }
    
        return (
            <span className={styles.warningsymbol}>
                <span className="material-symbols-outlined">warning</span> Varies
            </span>
        );
    };
    
    

    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    const handleVariantSelection = (variantId) => {
        setSelectedVariants(prevState => {
            let updatedVariants;
            if (prevState.includes(variantId)) {
                updatedVariants = prevState.filter(item => item !== variantId);
            } else {
                updatedVariants = [...prevState, variantId];
            }
            return updatedVariants;
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Quotations for Request </h1>
                <div className={styles.requestDetails}>
                    {request.image && <div className={styles.imageContainer}>
                        <img src={`/uploads/productImages/${request.image}`} alt={request.productName} className={styles.productImage} />
                    </div>}
                    <div className={styles.detailsContainer}>
                        <p><strong>ID:</strong> {requestId}</p>
                        <p><strong>Product:</strong> {request.productName}</p>
                        <p><strong>Description:</strong> {request.description}</p>
                        <p><strong>URL:</strong> <a href={request.url} target="_blank" rel="noopener noreferrer">{request.url}</a></p>
                        <p><strong>Note:</strong> {request.note}</p>
                    </div>
                </div>
            </div>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>SKU</th>
                            <th>Product Price</th>
                            <th>Shipping Price</th>
                            <th>Processing Time (days)</th>
                            <th>Shipping Time (days)</th>
                            <th>Variants</th>
                            <th>Supplier</th>
                            <th>Note</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading
                            ? Array.from({ length: 5 }).map((_, index) => (
                                <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                    <td colSpan="9"></td>
                                </tr>
                            ))
                            : quotations.map(quotation => (
                                <React.Fragment key={quotation.id}>
                                    <tr className={`${quotation.id === request.selectedQuotationId && styles.selectedQuotation} ${styles.rows} ${visibleVariants[quotation.id] && styles.droppeddown}`} >
                                        <td>{quotation.image && <img src={`/uploads/productImages/${quotation.image}`} className={styles.variantImage} onClick={() => viewImage(`/uploads/productImages/${quotation.image}`)} alt={quotation.image}/>}</td>
                                        {quotation.hasVariants ? (
                                            <>
                                                <td></td>
                                                <td>{renderConsistentOrVariableValue(quotation.variants, 'productPrice')}</td>
                                                <td>{renderConsistentOrVariableValue(quotation.variants, 'shippingPrice')}</td>
                                                <td>{renderConsistentOrVariableValue(quotation.variants, 'processingTime')}</td>
                                                <td>{renderConsistentOrVariableValue(quotation.variants, 'shippingTime')}</td>
                                                <td>
                                                    <button onClick={() => toggleVariantVisibility(quotation.id)} className={styles.VariantsButton}>
                                                        {quotation.variants.length}
                                                        <span className="material-symbols-outlined">
                                                            {visibleVariants[quotation.id] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                        </span>
                                                    </button>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td><HoverableOverflowText text={quotation.SKU}/></td>
                                                <td>${quotation.productPrice}</td>
                                                <td>${quotation.shippingPrice}</td>
                                                <td>{quotation.processingTime}</td>
                                                <td>{quotation.shippingTime}</td>
                                                <td>0</td>
                                            </>
                                        )}
                                        <td>{quotation.supplierName}</td>
                                        <td><HoverableOverflowText text={quotation.comments} /></td>
                                        <td className={styles.buttonGroup}>
                                            {request.status === "Responded" && !expiring &&
                                                <button onClick={() => chooseQuotation(quotation.id)} className={styles.actionsButton}>
                                                    {loadingQuotationId === quotation.id ? <LoadingButtonDots /> : 'Select'}
                                                </button>
                                            }
                                            {request.status === "Confirmed" && request.selectedQuotationId === quotation.id &&
                                                <button onClick={() => addToProducts(quotation.id)} className={styles.actionsButton}>
                                                    {loadingQuotationId === quotation.id ? <LoadingButtonDots /> : 'Add to Products'}
                                                </button>
                                            }
                                            {request.status === "In Products" && request.selectedQuotationId === quotation.id &&
                                                <button onClick={() => publishToStore(quotation.id)} className={styles.actionsButton}>
                                                    {loadingQuotationId === quotation.id ? <LoadingButtonDots /> : 'Publish to Store'}
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    {quotation.hasVariants && visibleVariants[quotation.id] && (
                                        <tr className={styles.detailRow}>
                                            <td colSpan="10">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>SKU</th>
                                                            <th>Product Price</th>
                                                            <th>Shipping Price</th>
                                                            <th>Processing Time (days)</th>
                                                            <th>Shipping Time (days)</th>
                                                            <th colSpan={4}>Categories</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {quotation.variants.map(variant => (
                                                            <tr key={variant.SKU}>
                                                                <td>{variant.image && <img src={`/uploads/productImages/${variant.image}`} className={styles.variantImage} onClick={() => viewImage(`/uploads/productImages/${variant.image}`)} alt={variant.image}/>}</td>
                                                                <td><HoverableOverflowText text={variant.SKU}/></td>
                                                                <td>{variant.productPrice}$</td>
                                                                <td>{variant.shippingPrice}$</td>
                                                                <td>{variant.processingTime}</td>
                                                                <td>{variant.shippingTime}</td>
                                                                {variant.categoryVariants.map((cv, index) => (
                                                                    <td key={index}>{cv.quotationCategory}: {cv.value}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                    </tbody>
                </table>
            </div>

            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modaltwo}
                overlayClassName={styles.overlaytwo}
            >
                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
            </Modal>

            <Modal
                isOpen={isPublishModalOpen}
                onRequestClose={() => setIsPublishModalOpen(false)}
                contentLabel="Publish to Store"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <h2>Publish to Store</h2>
                {loadingStores ? (  // Visa spinner om butikerna laddas
                    <div className={styles.spinner}><LoadingSpinner /></div>
                ) : (
                    selectedQuotation && (
                        <div className={styles.publishContent}>
                            {stores.length === 0 ? (
                                <div>
                                    <p>No stores available. Please add a store first to publish the product.</p>
                                    <a href="./store-management">Go to Store Management</a>
                                </div>
                            ) : (
                                <>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel>Choose Store</InputLabel>
                                        <Select
                                            label="Choose Store"
                                            value={selectedStoreToPublish || ""}
                                            onChange={handleSelectedStoreChange}
                                        >
                                            {stores.map((store, index) => {
                                                const isDisabled = selectedQuotation?.Stores?.some(pStore => pStore.id === store.id);
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={store.id}
                                                        disabled={isDisabled}
                                                    >
                                                        {store.shop.replace('.myshopify.com', '')} {isDisabled ? "(Already Published)" : ""}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                    {selectedQuotation?.variants && selectedQuotation.variants.length > 0 && (
                                        <div className={styles.variantsList}>
                                            {selectedQuotation.variants.map((variant, index) => (
                                                <div key={index} className={styles.variantRow}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value={variant.id}
                                                            onChange={() => handleVariantSelection(variant.id)}
                                                        />
                                                        {variant.SKU} - {(variant.categoryVariants || []).map((cv) => (cv.value
                                                        )).join('/')}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className={styles.buttons}>
                                        {selectedQuotation?.variants && selectedQuotation.variants.length > 0 ? (
                                            <button
                                                onClick={() => publishWithVariants(selectedQuotation.id, selectedStoreToPublish, selectedVariants)}
                                                className={styles.publishButton}
                                                disabled={!selectedStoreToPublish || selectedVariants.length === 0}
                                            >
                                                {publishing ? <LoadingButtonDots /> : 'Publish With Variants'}
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => publishNoVariant(selectedQuotation.id, selectedStoreToPublish)}
                                                className={styles.publishButton}
                                                disabled={!selectedStoreToPublish}
                                            >
                                                {publishing ? <LoadingButtonDots /> : 'Publish No Variant'}
                                            </button>
                                        )}
                                        <button onClick={() => setIsPublishModalOpen(false)} className={styles.closeButton}>Close</button>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                )}
            </Modal>
        </div>
    );
};

export default QuotationList;
