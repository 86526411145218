import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import styles from './CreateUser.module.scss';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function CreateUserForm() {
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        email: '',
        merchantId: null,
        supplierId: null,
    });

    const [merchants, setMerchants] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchMerchantsAndSuppliers = async () => {
            try {
                const response = await adminService.fetchMerchantsAndSuppliers();
                setMerchants(response.merchants || []);
                setSuppliers(response.suppliers || []);
            } catch (error) {
                console.error('Failed to fetch merchants and suppliers:', error);
            }
        };

        fetchMerchantsAndSuppliers();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(''); // Clear error when user makes changes
    };

    const handleMerchantChange = (event) => {
        setFormData(prevState => ({
            ...prevState,
            merchantId: event.target.value,
            supplierId: null // Reset supplier if merchant is selected
        }));
    };

    const handleSupplierChange = (event) => {
        setFormData(prevState => ({
            ...prevState,
            supplierId: event.target.value,
            merchantId: null // Reset merchant if supplier is selected
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;

        if (!passwordStrength.test(formData.password)) {
            setError('Password must be at least 7 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match!');
            return;
        }

        if (formData.merchantId === null && formData.supplierId === null) {
            setError('Please select either a Merchant or a Supplier.');
            return;
        }

        try {
            await adminService.createUser(formData);
            alert('User created successfully!');
            // Clear form after successful submission
            setFormData({
                password: '',
                confirmPassword: '',
                email: '',
                merchantId: null,
                supplierId: null,
            });
            setError('');
        } catch (error) {
            setError(error.message || 'Failed to create user!');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Create User</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                    <div className={styles.merchantsAndSuppliers}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Merchant</InputLabel>
                            <Select
                                value={formData.merchantId || ''}
                                onChange={handleMerchantChange}
                                label="Merchant"
                                name="merchantId"
                                disabled={formData.supplierId !== null}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {merchants.map(merchant => (
                                    <MenuItem key={merchant.id} value={merchant.id}>
                                        {merchant.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl size="small" fullWidth>
                            <InputLabel>Supplier</InputLabel>
                            <Select
                                value={formData.supplierId || ''}
                                onChange={handleSupplierChange}
                                label="Supplier"
                                name="supplierId"
                                disabled={formData.merchantId !== null}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {suppliers.map(supplier => (
                                    <MenuItem key={supplier.id} value={supplier.id}>
                                        {supplier.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <label>
                        Email:
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </label>
                    <br />
                    <label>
                        Password:
                        <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                    </label>
                    <br />
                    <label>
                        Repeat Password:
                        <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                    </label>
                    {error && <p className={styles.error}>{error}</p>}
                </div>
                <button type="submit" className={styles.submitButton}>Create User</button>
            </form>
        </div>
    );
}

export default CreateUserForm;
