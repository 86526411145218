import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import supplierService from '../../services/supplierService';
import styles from './Orders.module.scss';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';

Modal.setAppElement('#root');

const Orders = () => {
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [visibleItems, setVisibleItems] = useState({});
  const [shipmentModalIsOpen, setShipmentModalIsOpen] = useState(false);
  const [mergeModalIsOpen, setMergeModalIsOpen] = useState(false);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingCompany, setTrackingCompany] = useState('');
  const [loading, setLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [unhandledMergeRequestCount, setUnhandledMergeRequestCount] = useState(0);
  const [unhandledMergeRequestsData, setUnhandledMergeRequestsData] = useState([]);
  const [currentMergeRequestIndex, setCurrentMergeRequestIndex] = useState(0);
  const [cancellationModalIsOpen, setCancellationModalIsOpen] = useState(false);
  const [unhandledCancellationRequestsData, setUnhandledCancellationRequestsData] = useState([]);
  const [unhandledCancellationRequestCount, setUnhandledCancellationRequestCount] = useState(0);
  const [currentCancellationRequestIndex, setCurrentCancellationRequestIndex] = useState(0);

  // Fetching order data
  const fetchData = async () => {
    setLoading(true);
    try {
      const { orders, totalPages } = await supplierService.fetchFulfillmentOrdersByPageAndFilters({
        status: statusFilter,
        pageNumber: currentPage,
        pageAmount: itemsPerPage,
        searchQuery: searchQuery,
        startDate: dateRange.length ? dateRange[0].startDate : null,
        endDate: dateRange.length ? dateRange[0].endDate : null,
        showArchived,
      });
      setOrdersData(orders);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUnhandledCancellationRequestCount = async () => {
    try {
      const data = await supplierService.fetchCancellationRequestCount();
      setUnhandledCancellationRequestCount(data.cancellationRequestCount);
    } catch (error) {
      console.error("Error fetching unhandled merge requests: ", error);
    }
  };

  // Fetching unhandled merge requests
  const fetchUnhandledMergeRequestCount = async () => {
    try {
      const data = await supplierService.fetchUnhandledMergeRequestCount();
      setUnhandledMergeRequestCount(data.unhandledMergeRequests);
    } catch (error) {
      console.error("Error fetching unhandled merge requests: ", error);
    }
  };

  const fetchCancellationData = async () => {
    try {
      const data = await supplierService.fetchCancellationRequests();
      setUnhandledCancellationRequestsData(data);
    } catch (error) {
      console.error('Error fetching cancellation requests: ', error);
    }
  };

  // Fetching merge requests for modal
  const fetchMergingData = async () => {
    setLoading(true);
    try {
      const mergingData = await supplierService.fetchMergeRequests();
      setUnhandledMergeRequestsData(mergingData.mergeRequests);
    } catch (error) {
      console.error("Error fetching merge requests: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, showArchived]);

  useEffect(() => {
    fetchUnhandledMergeRequestCount();
    fetchUnhandledCancellationRequestCount();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const submitFilter = async () => {
    setCurrentPage(1);
    fetchData();
  };

  const toggleItemDetails = async (order) => {
    const orderKey = order.mergeGroupId || order.fulfillmentOrderId;

    if (visibleItems[orderKey]) {
      setVisibleItems((prev) => ({
        ...prev,
        [orderKey]: false,
      }));
    } else {
      setVisibleItems((prev) => ({
        ...prev,
        [orderKey]: 'loading',
      }));

      try {
        const items = await supplierService.fetchSupplierOrderItems(order, showArchived);
        setOrdersData((prevOrdersData) =>
          prevOrdersData.map((o) =>
            (o.mergeGroupId || o.fulfillmentOrderId) === orderKey
              ? { ...o, items }
              : o
          )
        );
      } catch (error) {
        console.error('Failed to fetch order items:', error);
      } finally {
        setVisibleItems((prev) => ({
          ...prev,
          [orderKey]: true,
        }));
      }
    }
  };

  const openShipmentModal = (order, type) => {
    setSelectedOrder(order);
    setShipmentModalIsOpen(true);
  };

  const closeShipmentModal = () => {
    setShipmentModalIsOpen(false);
    setSelectedOrder(null);
    setTrackingNumber('');
    setTrackingCompany('');
  };
  const openDetailsModal = (order, type) => {
    setSelectedOrder(order);
    setDetailsModalIsOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalIsOpen(false);
    setSelectedOrder(null);
  };

  const closeMergeModal = () => {
    setMergeModalIsOpen(false);
    setUnhandledMergeRequestsData([]);
  };

  const handleConfirmShipment = async () => {
    try {
      await supplierService.setShipment({
        fulfillmentOrderId: selectedOrder.fulfillmentOrderId,
        mergeGroupId: selectedOrder.mergeGroupId,
        trackingId: trackingNumber,
        trackingCompany: trackingCompany,
      });
      closeShipmentModal();
      fetchData();
    } catch (error) {
      console.error('Error confirming shipment:', error);
    }
  };

  const openMergeModal = async () => {
    await fetchMergingData();
    setCurrentMergeRequestIndex(0); // Reset index
    setMergeModalIsOpen(true);
  };

  const handlePrevious = () => {
    if (currentMergeRequestIndex > 0) {
      setCurrentMergeRequestIndex(currentMergeRequestIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentMergeRequestIndex < unhandledMergeRequestsData.length - 1) {
      setCurrentMergeRequestIndex(currentMergeRequestIndex + 1);
    }
  };

  const handleAccept = async () => {
    try {
      await supplierService.acceptMergeRequest(unhandledMergeRequestsData[currentMergeRequestIndex].mergeRequestId);
      fetchUnhandledCancellationRequestCount(); // Refresh unhandled merge requests after accepting
      closeMergeModal();
    } catch (error) {
      console.error('Error accepting merge request: ', error);
    }
  };

  const handleDeny = async () => {
    try {
      await supplierService.denyMergeRequest(unhandledMergeRequestsData[currentMergeRequestIndex].mergeRequestId);
      fetchUnhandledCancellationRequestCount(); // Refresh unhandled merge requests after denying
      closeMergeModal();
    } catch (error) {
      console.error('Error denying merge request: ', error);
    }
  };

    // Open cancellation modal
    const openCancellationModal = async () => {
      await fetchCancellationData();
      setCurrentCancellationRequestIndex(0);
      setCancellationModalIsOpen(true);
    };
  
    // Close cancellation modal
    const closeCancellationModal = () => {
      setCancellationModalIsOpen(false);
      setUnhandledCancellationRequestsData([]);
    };

    const handleAcceptCancellation = async () => {
      try {
        await supplierService.acceptCancellationRequest(unhandledCancellationRequestsData[currentCancellationRequestIndex].id);
        fetchCancellationData();
        closeCancellationModal();
      } catch (error) {
        console.error('Error accepting cancellation request: ', error);
      }
    };
  
    // Deny cancellation request
    const handleDenyCancellation = async () => {
      try {
        await supplierService.denyCancellationRequest(unhandledCancellationRequestsData[currentCancellationRequestIndex].id);
        fetchCancellationData();
        closeCancellationModal();
      } catch (error) {
        console.error('Error denying cancellation request: ', error);
      }
    };

    const handlePreviousCancellation = () => {
      if (currentCancellationRequestIndex > 0) {
        setCurrentCancellationRequestIndex(currentCancellationRequestIndex - 1);
      }
    };
  
    // Navigate to the next cancellation request
    const handleNextCancellation = () => {
      if (currentCancellationRequestIndex < unhandledCancellationRequestsData.length - 1) {
        setCurrentCancellationRequestIndex(currentCancellationRequestIndex + 1);
      }
    };

  const createDetailRow = (orderItems) => {
    return (
      <td colSpan="5">
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>SKU</th>
              <th colSpan={2}>Variant</th>
            </tr>
          </thead>
          <tbody>
            {orderItems.map((item, index) => (
              <tr key={`item-${index}`}>
                <td>{item.name}</td> {/* Korrigerat från item.name */}
                <td>{item.quantity}</td>
                <td>{item.SKU}</td>
                <td colSpan={2}>
                  {item.variantDetails && item.variantDetails.categories && item.variantDetails.categories.length > 0 ? ( // Korrigerat till variantDetails
                    item.variantDetails.categories.map((cv, catIndex) => (
                      <span key={catIndex}>
                        {`${cv.name}: ${cv.value}`}
                        {catIndex !== item.variantDetails.categories.length - 1 && ', '}
                      </span>
                    ))
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    );
  };

  const renderMergedOrder = (mergedGroup) => (
    <div key={mergedGroup.mergeGroupId} className={styles.mergedOrderContainer}>
      <h3>Group ID: {mergedGroup.mergeGroupId}</h3>
      {mergedGroup.mergedOrders.map((order) => (
        <div key={order.orderId} className={styles.orderCard}>
          <h4>Order ID: {order.orderId}</h4>
          <p>Customer: {order.customer.fullName}</p>
          <p>Date: {new Date(order.createdAt).toLocaleDateString()}</p>
          <p>Status: {order.status}</p>
          <table>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>SKU</th>
                <th>Variant</th>
              </tr>
            </thead>
            <tbody>
              {order.orderItems.map((item) => (
                <tr key={item.id}>
                  <td>{item.productName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.SKU}</td>
                  <td>{item.variantDetails || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
  
  const renderSingleOrder = (order) => (
    <div key={order.orderId} className={styles.orderCard}>
      <h4>Order ID: {order.orderId}</h4>
      <p>Customer: {order.customer.fullName}</p>
      <p>Date: {new Date(order.createdAt).toLocaleDateString()}</p>
      <p>Status: {order.status}</p>
      <table>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>SKU</th>
            <th>Variant</th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map((item) => (
            <tr key={item.id}>
              <td>{item.productName}</td>
              <td>{item.quantity}</td>
              <td>{item.SKU}</td>
              <td>{item.variantDetails || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  
  const renderMergeRequestOrders = (orders) => {
    return orders.map((order) => {
      if (order.mergeGroupId && order.mergedOrders) {
        return renderMergedOrder(order);
      } else {
        return renderSingleOrder(order);
      }
    });
  };
  

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Orders</h2>
      </div>
      <div className={styles.top}>
        <FilterComponent
          dataType={'Orders'}
          backgroundColor="white"
          statusFilter={statusFilter}
          setSelectFilter={setStatusFilter}
          selectLabel="Status"
          dateRange={dateRange}
          setDateRange={setDateRange}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          applyFilters={submitFilter}
          statusOptions={[
            { value: 'all', label: 'All' },
            { value: 'Awaiting Fulfillment', label: 'Awaiting Fulfillment' },
            { value: 'Fulfilled', label: 'Fulfilled' },
          ]}
          searchPlaceholder="Search by Order ID"
          includeSearch={true}
          showArchived={showArchived}
          setShowArchived={setShowArchived}
        />
        <div className={styles.unhandledContainer}>
        {unhandledMergeRequestCount > 0 && (
          <button onClick={openMergeModal} className={styles.unhandledButton}>
            {unhandledMergeRequestCount} Unhandled merge requests
          </button>
        )}
                {unhandledCancellationRequestCount > 0 && (
          <button onClick={openCancellationModal} className={styles.unhandledButton}>
            {unhandledCancellationRequestCount} Unhandled cancellation requests
          </button>
        )}
        </div>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Total Products</th>
              <th>Date</th>
              <th>Fulfilled</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: itemsPerPage }).map((_, index) => (
                <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                  <td colSpan={5}></td>
                </tr>
              ))
            ) : ordersData.length === 0 ? (
              <tr>
                <td colSpan={5}>Currently no orders</td>
              </tr>
            ) : (
              ordersData.map((order) => {
                const orderKey = order.mergeGroupId || order.fulfillmentOrderId;
                const isMerged = !!order.mergeGroupId;

                return (
                  <React.Fragment key={orderKey}>
                    <tr className={visibleItems[orderKey] ? styles.droppeddown : styles.rows}>
                      <td>
                        {isMerged ? (
                          <>
                            <strong>Merged: </strong>
                            {order.mergedFulfillmentOrders.map((fo) => fo.fulfillmentOrderId).join(', ')}
                          </>
                        ) : (
                          <div>{order.fulfillmentOrderId}</div>
                        )}
                      </td>
                      <td>
                        <button className={styles.toggleRowsButton} onClick={() => toggleItemDetails(order)}>
                          {order.itemsCount}
                          <span className="material-symbols-outlined">
                            {visibleItems[orderKey] ? 'arrow_drop_up' : 'arrow_drop_down'}
                          </span>
                        </button>
                      </td>
                      <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                      <td>
                        {order.isFulfilled ? (
                          <div className={styles.checkedCircle}>
                            <span className="material-symbols-outlined">check_circle</span>
                          </div>
                        ) : (
                          <div className={styles.xCircle}>
                            <span className="material-symbols-outlined">cancel</span>
                          </div>
                        )}
                      </td>
                      <td>
                        {!order.isFulfilled && (
                          <div className={styles.buttons}>
                            <button className={styles.shipmentButton} onClick={() => openShipmentModal(order, 'shipment')}>
                              Shipment
                            </button>
                            <button className={styles.detailsButton} onClick={() => openDetailsModal(order, 'details')}>
                              Details
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                    {visibleItems[orderKey] === 'loading' ? (
                      <tr className={styles.detailRow} key={`details-${orderKey}`}>
                        <td colSpan={5}>
                          <table>
                            <tbody>
                              {Array.from({ length: order.itemsCount + 1 }).map((_, index) => (
                                <tr key={index} className={`${styles.rows} ${styles.placeHolderDetailRow}`}>
                                  <td colSpan={5}></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : (
                      visibleItems[orderKey] && order.items && (
                        <tr className={styles.detailRow} key={`details-${orderKey}`}>
                          {createDetailRow(order.items)}
                        </tr>
                      )
                    )}
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />

      {detailsModalIsOpen && selectedOrder && (
        <Modal
          isOpen={detailsModalIsOpen}
          onRequestClose={closeDetailsModal}
          contentLabel="Order Details"
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <div className={styles.detailsContainer}>
            <h2>Order Details</h2>
            <ul>
              <li>
                <strong>Full Name:</strong> {selectedOrder.customerData.fullName}
              </li>
              <li>
                <strong>Delivery Address:</strong> {selectedOrder.customerData.deliveryAddressLine}
              </li>
              {selectedOrder.customerData.deliveryAddressLine2 && (
                <li>
                  <strong>Address Line 2:</strong> {selectedOrder.customerData.deliveryAddressLine2}
                </li>
              )}
              <li>
                <strong>City:</strong> {selectedOrder.customerData.deliveryCity}
              </li>
              <li>
                <strong>ZIP:</strong> {selectedOrder.customerData.deliveryZIP}
              </li>
              <li>
                <strong>Country:</strong> {selectedOrder.customerData.deliveryCountry}
              </li>
              <li>
                <strong>Province:</strong> {selectedOrder.customerData.deliveryProvince}
              </li>
              {selectedOrder.customerData.company && (
                <li>
                  <strong>Company:</strong> {selectedOrder.customerData.company}
                </li>
              )}
            </ul>
            <button onClick={closeDetailsModal} className={styles.closeButton}>
              Close
            </button>
          </div>
        </Modal>
      )}

      {shipmentModalIsOpen && selectedOrder && (
        <Modal
          isOpen={shipmentModalIsOpen}
          onRequestClose={closeShipmentModal}
          contentLabel="Confirm Shipment"
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <h2>Confirm Shipment</h2>
          {selectedOrder.hasPendingMerge && <div className={styles.warningMerge}><span class="material-symbols-outlined">
error
</span><span>This order has a merge request. <br/> Confirming shipment will automatically deny.</span></div>}
          <div className={styles.trackingInputContainer}>
            <label htmlFor="trackingNumber">
              Tracking Number
              <input
                type="text"
                id="trackingNumber"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              />
            </label>
            <label htmlFor="trackingCompany">
              Tracking Company
              <input
                type="text"
                id="trackingCompany"
                value={trackingCompany}
                onChange={(e) => setTrackingCompany(e.target.value)}
              />
            </label>
          </div>
          <div className={styles.buttonGroup}>
            <button onClick={handleConfirmShipment} className={styles.confirmButton}>
              Confirm Shipment
            </button>
            <button onClick={closeShipmentModal} className={styles.closeButton}>
              Close
            </button>
          </div>
        </Modal>
      )}

{mergeModalIsOpen && unhandledMergeRequestsData.length > 0 && (
  <Modal
    isOpen={mergeModalIsOpen}
    onRequestClose={closeMergeModal}
    contentLabel="Merge Requests"
    className={styles.modalthree}
    overlayClassName={styles.overlay}
  >
    <div className={styles.mergeRequestModal}>
      <header className={styles.modalHeader}>
        <h2>
          Merge Request {currentMergeRequestIndex + 1} of {unhandledMergeRequestsData.length}
        </h2>
        <button onClick={closeMergeModal} className={styles.closeButton} aria-label="Close Modal">
          &times;
        </button>
      </header>

      <div className={styles.navigationButtons}>
              {currentMergeRequestIndex > 0 && (
                <button onClick={handlePrevious} className={styles.navButtonOne}>
                  &lt;
                </button>
              )}
              {currentMergeRequestIndex < unhandledMergeRequestsData.length - 1 && (
                <button onClick={handleNext} className={styles.navButtonTwo}>
                  &gt;
                </button>
              )}
            </div>
      
      <section className={styles.mergeRequestInfo}>
        <p><strong>Merge Request ID:</strong> {unhandledMergeRequestsData[currentMergeRequestIndex].mergeRequestId}</p>
        <p><strong>Supplier ID:</strong> {unhandledMergeRequestsData[currentMergeRequestIndex].supplierId}</p>
        <p><strong>Status:</strong> {unhandledMergeRequestsData[currentMergeRequestIndex].status}</p>
        <p><strong>Number of Orders:</strong> {unhandledMergeRequestsData[currentMergeRequestIndex].orders.length}</p>
      </section>

      <section className={styles.ordersList}>
        {renderMergeRequestOrders(unhandledMergeRequestsData[currentMergeRequestIndex].orders)}
      </section>
      <div className={styles.actionButtons}>
        <div className={styles.actionGroup}>
          <button onClick={handleAccept} className={styles.acceptButton}>
            Accept
          </button>
          <button onClick={handleDeny} className={styles.denyButton}>
            Deny
          </button>
        </div>
        <button onClick={closeMergeModal} className={styles.cancelButton}>
          Cancel
        </button>
      </div>
    </div>
  </Modal>
)}
{cancellationModalIsOpen && unhandledCancellationRequestsData.length > 0 && (
        <Modal
          isOpen={cancellationModalIsOpen}
          onRequestClose={closeCancellationModal}
          contentLabel="Cancellation Requests"
          className={styles.modalthree}
          overlayClassName={styles.overlay}
        >
          <div className={styles.cancellationRequestModal}>
            <header className={styles.modalHeader}>
              <h2>
                Cancellation Request {currentCancellationRequestIndex + 1} of {unhandledCancellationRequestsData.length}
              </h2>
              <button onClick={closeCancellationModal} className={styles.closeButton} aria-label="Close Modal">
                &times;
              </button>
            </header>

            <section className={styles.cancellationRequestInfo}>
              <p><strong>Order ID:</strong> {unhandledCancellationRequestsData[currentCancellationRequestIndex].fulfillmentOrderId}</p>
              <p><strong>Customer:</strong> {unhandledCancellationRequestsData[currentCancellationRequestIndex].customerName}</p>
              <p><strong>Status:</strong> Pending</p>
            </section>

            <div className={styles.navigationButtons}>
              {currentCancellationRequestIndex > 0 && (
                <button onClick={handlePreviousCancellation} className={styles.navButtonOne}>
                  &lt;
                </button>
              )}
              {currentCancellationRequestIndex < unhandledCancellationRequestsData.length - 1 && (
                <button onClick={handleNextCancellation} className={styles.navButtonTwo}>
                  &gt;
                </button>
              )}
            </div>

            <div className={styles.actionButtons}>
              <button onClick={handleAcceptCancellation} className={styles.acceptButton}>
                Accept
              </button>
              <button onClick={handleDenyCancellation} className={styles.denyButton}>
                Deny
              </button>
              <button onClick={closeCancellationModal} className={styles.closeButton}>
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Orders;