import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import authService from '../../services/authService';
import styles from './Settings.module.scss';
import PasswordChangeModal from '../../components/PasswordChangeModal';
import Modal from 'react-modal';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; 
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    useEffect(() => {
        document.title = 'Settings | Qiwi';
      }, []);
      
    const [userInfo, setUserInfo] = useState(null);
    const [invoiceInfo, setInvoiceInfo] = useState(null);
    const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [merchantToDeleteOrders, setMerchantToDeleteOrders] = useState({});
    const [deleteConfirmation, setDeleteConfirmation] = useState('');
    const [newInvoiceInfo, setNewInvoiceInfo] = useState({
        companyName: '',
        orgNumber: '',
        vatNumber: '',
        address: '',
        phoneNumber: '',
        email: '',
        contactPerson: ''
    });
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingExpire, setLoadingExpire] = useState(false); // Loading state for expire button
    const [loadingDelete, setLoadingDelete] = useState(false); // Loading state for delete button
    const [loadingModal, setLoadingModal] = useState(false); // Loading state for modal
    const [expiring, setExpiring] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState(null); 
    const navigate = useNavigate();

    const deleteMerchantModal = async () => {
        setLoadingModal(true); // Start loading spinner for modal
        const orders = await merchantService.merchantDeleteStatus();
        setMerchantToDeleteOrders(orders.orders);
        setLoadingModal(false); // Stop loading spinner for modal
        setIsDeleteModalOpen(true);
    };

    const logout = async () => {
        await authService.logout();
        navigate('/login');
    };

    const expireMerchant = async () => {
        const confirmed = window.confirm("Are you sure you want to expire this Merchant? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true); // Start loading spinner
                await merchantService.expireMerchant();
                sessionStorage.setItem('expiring', true);
                alert("Merchant expired successfully.");
                setIsDeleteModalOpen(false);
                window.location.reload();
            } catch (error) {
                console.error("Error expiring Merchant:", error);
                alert("Failed to expire Merchant.");
            } finally {
                setLoadingExpire(false); // Stop loading spinner
            }
        }
    };

    const deleteMerchant = async () => {
        if (deleteConfirmation === 'I confirm') {
            try {
                setLoadingDelete(true); // Start loading spinner
                await merchantService.deleteMerchant();
                alert("Merchant deleted successfully.");
                logout();
            } catch (error) {
                console.error('Error deleting user:', error);
                alert('Failed to delete user');
            } finally {
                setLoadingDelete(false); // Stop loading spinner
                setIsDeleteModalOpen(false);
            }
        } else {
            alert('You must type "I confirm" to delete your account');
        }
    };

    useEffect(() => {
        const storedExpiring = sessionStorage.getItem('expiring');
        if (storedExpiring !== null) {
            setExpiring(storedExpiring === 'true');
        }
    }, []);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await merchantService.getUserInfo();
                setUserInfo(response);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        const fetchInvoiceAccountInfo = async () => {
            try {
                const response = await merchantService.fetchInvoiceAccountInfo();
                setInvoiceInfo(response);
            } catch (error) {
                console.error('Error fetching invoice info:', error);
            }
        };

        // Fetch data and set loading to false once done
        Promise.all([fetchUserInfo(), fetchInvoiceAccountInfo()]).then(() => {
            setLoading(false); // Stäng av laddningsindikatorn när datan är laddad
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewInvoiceInfo({
            ...newInvoiceInfo,
            [name]: value
        });
    };

    const handleCreateOrUpdateInvoice = async () => {
        try {
            setLoadingSubmit(true); 
            let response;
            if (invoiceInfo) {
                response = await merchantService.updateInvoiceInfo(newInvoiceInfo);
            } else {
                response = await merchantService.createInvoiceInfo(newInvoiceInfo);
            }
            setInvoiceInfo(response);
            setIsCreatingInvoice(false);
        } catch (error) {
            console.error('Error creating/updating invoice info:', error);
        } finally {
            setLoadingSubmit(false); 
        }
    };

    const handleChangePassword = async (newPassword) => {
        try {
            setLoadingSubmit(true); // Visa loading dots på knappen
            await authService.changePassword(newPassword);
            alert('Password changed successfully');
            window.location.reload();
        } catch (error) {
            setPasswordChangeError(error.response.data.message); // Sätt felmeddelande
        } finally {
            setLoadingSubmit(false); // Stäng av loading dots på knappen
        }
    };

    const toggleInvoiceCreation = () => {
        if (isCreatingInvoice && (newInvoiceInfo.companyName || newInvoiceInfo.orgNumber || newInvoiceInfo.vatNumber || newInvoiceInfo.address || newInvoiceInfo.phoneNumber || newInvoiceInfo.email || newInvoiceInfo.contactPerson)) {
            if (window.confirm('You have unsaved changes. Are you sure you want to cancel?')) {
                setIsCreatingInvoice(false);
                setNewInvoiceInfo({
                    companyName: '',
                    orgNumber: '',
                    vatNumber: '',
                    address: '',
                    phoneNumber: '',
                    email: '',
                    contactPerson: ''
                });
            }
        } else {
            if (invoiceInfo) {
                setNewInvoiceInfo(invoiceInfo);
            }
            setIsCreatingInvoice(!isCreatingInvoice);
        }
    };

    if (loading) {
        return <div className={styles.spinner}><LoadingSpinner /></div>; 
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <h2>Personal Information</h2>
                <p>Email: {userInfo.email}</p>
                <div className={styles.buttonGroup}>
                    <button onClick={() => setIsPasswordModalOpen(true)} className={styles.edit}>Change Password</button>
                    <button onClick={() => deleteMerchantModal()} className={styles.remove} disabled={expiring}>Delete Account</button>
                </div>
            </div>
            <div className={styles.section}>
                <h2>Invoice Information</h2>
                {invoiceInfo && !isCreatingInvoice ? (
                    <>
                        <p>Company Name: {invoiceInfo.companyName}</p>
                        <p>Org Number: {invoiceInfo.orgNumber}</p>
                        <p>VAT Number: {invoiceInfo.vatNumber}</p>
                        <p>Address: {invoiceInfo.address}</p>
                        <p>Phone Number: {invoiceInfo.phoneNumber}</p>
                        <p>Email: {invoiceInfo.email}</p>
                        <p>Contact Person: {invoiceInfo.contactPerson}</p>
                        <button onClick={toggleInvoiceCreation} className={styles.edit}>Edit Invoice Information</button>
                    </>
                ) : (
                    <>
                        {!isCreatingInvoice ? (
                            <button onClick={toggleInvoiceCreation} className={styles.createButton}>Create Invoice Information</button>
                        ) : (
                            <form className={styles.form}>
                                <label htmlFor="companyName">Company Name</label>
                                <input
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    value={newInvoiceInfo.companyName}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="orgNumber">Org Number</label>
                                <input
                                    type="text"
                                    id="orgNumber"
                                    name="orgNumber"
                                    value={newInvoiceInfo.orgNumber}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="vatNumber">VAT Number</label>
                                <input
                                    type="text"
                                    id="vatNumber"
                                    name="vatNumber"
                                    value={newInvoiceInfo.vatNumber}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="address">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={newInvoiceInfo.address}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={newInvoiceInfo.phoneNumber}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={newInvoiceInfo.email}
                                    onChange={handleInputChange}
                                />

                                <label htmlFor="contactPerson">Contact Person</label>
                                <input
                                    type="text"
                                    id="contactPerson"
                                    name="contactPerson"
                                    value={newInvoiceInfo.contactPerson}
                                    onChange={handleInputChange}
                                />
                                <div className={styles.buttonGroup}>
                                    <button type="button" onClick={handleCreateOrUpdateInvoice} className={styles.createButton} disabled={loadingSubmit}>
                                        {loadingSubmit ? <LoadingButtonDots /> : 'Save Invoice Information'}
                                    </button>
                                    <button type="button" onClick={toggleInvoiceCreation} className={styles.cancel}>Cancel</button>
                                </div>
                            </form>
                        )}
                    </>
                )}
            </div>
            <PasswordChangeModal
                isOpen={isPasswordModalOpen}
                onRequestClose={() => setIsPasswordModalOpen(false)}
                onChangePassword={handleChangePassword}
                loading={loadingSubmit} 
                error={passwordChangeError} 
            />
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <h2>Deleting Merchant</h2>
                {loadingModal ? ( 
                    <div className={styles.spinnerModal}><LoadingSpinner /></div> 
                ) : merchantToDeleteOrders && merchantToDeleteOrders.length > 0 ? (
                    <>
                        <div className={styles.warningMessage}>
                            <span className="material-symbols-outlined">warning</span>
                            <p><strong>OBS. There are existing orders pending for this Merchant. Your account will be expiring until fulfilled.</strong></p>
                        </div>
                        <p>When you delete this merchant and account, the app will not be automatically uninstalled from your stores, and products sourced from Qiwi will not be removed from your Shopify stores. Please ensure you manually set these products to "Draft" or remove them from your stores.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {merchantToDeleteOrders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.buttons}>
                        <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                        <button className={styles.expiringButton} onClick={expireMerchant} disabled={loadingExpire}>
                            {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
                        </button>
                        </div>
                    </>
                ) : (
                    <>
                        <p><strong>Are you sure you want to delete your merchant account? This action cannot be undone.</strong></p>
                        <p>When you delete this merchant and account, the app will not be automatically uninstalled from your stores, and products sourced from Qiwi will not be removed from your Shopify stores. Please ensure you manually set these products to "Draft" or remove them from your stores.</p>
                        <input
                            type="text"
                            placeholder='Type "I confirm" to delete'
                            value={deleteConfirmation}
                            onChange={(e) => setDeleteConfirmation(e.target.value)}
                        />
                        <div className={styles.buttons}>
                        <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                        <button className={styles.deleteButtonModal} onClick={deleteMerchant} disabled={deleteConfirmation !== 'I confirm' || loadingDelete}>
                            {loadingDelete ? <LoadingButtonDots /> : 'Yes, delete my account'}
                        </button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default Settings;
