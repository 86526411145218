import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const fetchSourcingDataByPageAndStatus = async (status, pageNumber, pageAmount, searchQuery, startDate, endDate, showArchived) => {
    try {
        const response = await axios.get(API_URL + 'sourcing-merchant/sourcingDataByPageAndStatus', {
            params: {
                status,
                pageNumber,
                pageAmount,
                searchQuery,
                startDate,
                endDate,
                showArchived
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching sourcing requests:', error);
        throw error;
    }
};


const fetchOrdersByPageAndFilters = async (status, pageNumber, pageAmount, searchQuery, startDate, endDate, showArchived) => {
    try {
        const response = await axios.get(API_URL + 'orders/fetchAllOrdersByPageAndFilters', {
            params: {
                status,
                pageNumber,
                pageAmount,
                searchQuery,
                startDate,
                endDate,
                showArchived
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching sourcing requests:', error);
        throw error;
    }
};


const createSourcingRequest = async (requestData) => {
    try {
        const response = await axios.post(API_URL + 'sourcing-merchant/create', requestData, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error creating sourcing request:', error);
        throw error;
    }
};

const createSourcingRequestWithImage = async (formData) => {
    try {
        const response = await axios.post(API_URL + 'sourcing-merchant/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error creating sourcing request:', error);
        throw error;
    }
};

const addToProducts = async (quotationId ,requestId) => {
    try {
        const response = await axios.post(`${API_URL}sourcing-merchant/addProduct`, {
            requestId: requestId,
            quotationId: quotationId,
        }, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        console.error('Failed to add product:', error);
        throw error;
    }
};

const getStores = async () => {
    try {
        const response = await axios.get(API_URL + `store/getStores`, {
            withCredentials: true
        });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching stores:', error);
        throw error;
    }
};


const publishNoVariantProduct = async (quotationId, productId, storeId) => {
    try {
        const response = await axios.post(`${API_URL}product/publishWithNoVariants`, {
            productId: productId,
            storeId: storeId,
            quotationId: quotationId,
        }, {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error('Failed to publish product to store:', error);
        throw error;
    }
}

const publishProductWithVariants = async (quotationId, productId, storeId, variantIds) => {
    try {
        const response = await axios.post(`${API_URL}product/publishWithVariants`, {
            productId: productId,
            quotationId: quotationId,
            storeId: storeId,
            variantIds: variantIds
        }, {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error('Failed to publish product to store:', error);
        throw error;
    }
}


const deleteStore = async (storeId) => {
    try {
        const response = await axios.delete(`${API_URL}store/delete/${storeId}`, {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error('Failed to delete store:', error);
        throw error;
    }
}

const cancelOrder = async (orderId) => {
    try {
        
        const response = await axios.put(`${API_URL}orders/cancelOrder/${orderId}`, {}, {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error('Failed to cancel order:', error);
        throw error;
    }
}


const createOrderManually = async (orderData) => {
    try {
        const response = await axios.post(`${API_URL}orders/createOrderManually`, {
            orderData: orderData
        },{
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error('Failed to create order manually:', error);
        throw error;
    }
}

const getProductsByStore = async (storeId) => {
    try {
        const response = await axios.get(`${API_URL}product/getProductsByStore/${storeId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get products by store:', error);
        throw error;
    }
}

const fetchInvoicesByPageAndFilters = async (statusFilter, currentPage, itemsPerPage, startDate, endDate, showArchived) => {
    try {
        const response = await axios.get(API_URL + 'invoice/fetchInvoicesByPageAndFilters', {
            params: {
                statusFilter,
                currentPage,
                itemsPerPage,
                startDate,
                endDate,
                showArchived
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get invoices by user:', error);
        throw error;
    }
}
const fetchInvoiceDetails = async (invoiceId, showArchived) => {
    try {
        const response = await axios.get(`${API_URL}invoice/fetchInvoiceDetails/${invoiceId}`, {
            params: {
                showArchived: showArchived
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get invoice details:', error);
        throw error;
    }
};

const fetchInstallationLink = async (invoiceId) => {
    try {
        const response = await axios.get(`${API_URL}store/install-link/${invoiceId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get invoice details:', error);
        throw error;
    }
}

const fetchUserProductsWithFilterAndPagination = async (currentPage, itemsPerPage, selectedStore, showArchived) => {
    try {
        const response = await axios.get(`${API_URL}product/fetchUserProductsWithFilterAndPagination`, {
            params: {
                currentPage,
                itemsPerPage,
                selectedStore,
                showArchived
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user products with filter and pagination', error);
        throw error;
    }
};

const fetchAllShopifyProductsWithFilterAndPagination = async (selectedStore, itemsPerPage, pageInfo, direction) => {
    try {
        const response = await axios.get(`${API_URL}product/fetchAllShopifyProductsWithFilterAndPagination`, {
            params: {
                selectedStore,
                itemsPerPage,
                pageInfo,
                direction
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch Shopify products with filter and pagination', error);
        throw error;
    }
};

const fetchInvoiceAccountInfo = async () => {
    try {
        const response = await axios.get(`${API_URL}user/fetchInvoiceAccountInfo`, {
            withCredentials: true
        });
        return response.data;
    }catch (error) {
        console.error('Failed to fetch invoice info', error);
        throw error;
    }
}

const getUserInfo = async () => {
    try {
        const response = await axios.get(`${API_URL}user/fetchUserInfo`, {
            withCredentials: true
        });
        return response.data;
    }catch (error) {
        console.error('Failed to fetch invoice info', error);
        throw error;
    }
}

const createInvoiceInfo = async (invoiceData) => {
    try {
        const response = await axios.post(`${API_URL}user/createInvoiceInfo`, invoiceData, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to create invoice info', error);
        throw error;
    }
};

const updateInvoiceInfo = async (invoiceData) => {
    try {
        const response = await axios.put(`${API_URL}user/updateInvoiceInfo`, invoiceData, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to update invoice info', error);
        throw error;
    }
};

const checkInvoiceInfoExistance = async () => {
    try {
        const response = await axios.get(`${API_URL}user/checkInvoiceInfoExistance`, {
            withCredentials: true
        });
        return response.data.exists;
    } catch (error) {
        console.error('Failed to check invoice info existence', error);
        throw error;
    }
};

const fetchInvoiceInfo = async () => {
    try {
        const response = await axios.get(`${API_URL}invoice/fetchInvoiceInfo`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch invoice info', error);
        throw error;
    }
};

const createTransactionProof = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}invoice/uploadTransactionProof`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Error uploading transaction proof:', error);
        throw error;
    }
};

const fetchQuotationsAndRequest = async (requestId) => {
    try {
        const response = await axios.get(API_URL + `sourcing-merchant/quotations-and-request/${requestId}`, {    
        withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to delete user:', error);
        throw error;
    }
  };


const confirmQuotation = async (quotationId, requestId) => {
    try {
        const response = await axios.post(`${API_URL}sourcing-merchant/confirm-quotation`, { quotationId, requestId }, {    
            withCredentials: true
            });
        return response.data;
    } catch (error) {
        console.error('Error confirming quotation:', error);
        throw error;
    }
};

const fetchMerchantOrderItems = async (orderId, showArchived) => {
    try {
        const response = await axios.get(`${API_URL}orders/fetchMerchantOrderItems/`, {
            params: {
                showArchived: showArchived,
                orderId: orderId,
            },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get order items details:', error);
        throw error;
    }
}

const fetchOrdersInfo = async () => {
    const response = await axios.get(`${API_URL}orders/fetchOrdersInfo/`, {
        withCredentials: true
    });
    return response.data;
};

const fetchSourcingInfo = async () => {
    const response = await axios.get(`${API_URL}sourcing-merchant/fetchSourcingInfo`, {
        withCredentials: true
    });
    return response.data;
};

const merchantDeleteStatus = async () => {
    try {
        const response = await axios.get(`${API_URL}user/merchantDeleteStatus`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get merchant delete status:', error);
        throw error;
    }
};

const expireMerchant = async () => {
    try {
        const response = await axios.get(`${API_URL}user/expireMerchant`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire merchant:', error);
        throw error;
    }
};

const deleteMerchant = async () => {
    try {
        const response = await axios.delete(`${API_URL}user/deleteMerchant`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to delete merchant:', error);
        throw error;
    }
};

const productDeleteStatus = async (productId) => {
    try {
        const response = await axios.get(`${API_URL}product/productDeleteStatus/${productId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get product delete status:', error);
        throw error;
    }
};

const deleteProduct = async (productId) => {
    try {
        const response = await axios.delete(`${API_URL}product/productDelete/${productId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to delete product:', error);
        throw error;
    }
};

const expireProduct = async (productId) => {
    try {
        const response = await axios.get(`${API_URL}product/expireProduct/${productId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire product:', error);
        throw error;
    }
};

const storeDeleteStatus = async (storeId) => {
    try {
        const response = await axios.get(`${API_URL}store/storeDeleteStatus/${storeId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to get store delete status:', error);
        throw error;
    }
};

const storeDelete = async (storeId) => {
    try {
        const response = await axios.delete(`${API_URL}store/delete/${storeId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to delete store:', error);
        throw error;
    }
};

const expireStore = async (storeId) => {
    try {
        const response = await axios.get(`${API_URL}store/expireStore/${storeId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire store:', error);
        throw error;
    }
};

const fetchMergeOrders = async () => {
    try {
        const response = await axios.get(`${API_URL}orders/fetchMergeOrders`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire store:', error);
        throw error;
    }
};

const fetchMergeRequests = async () => {
    try {
        const response = await axios.get(`${API_URL}orders/fetchMergeRequests`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire store:', error);
        throw error;
    }
};

const fetchMergesFromOrderId = async (orderId) => {
    try {
        const response = await axios.get(`${API_URL}orders/fetchMergesFromOrderId/${orderId}`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire store:', error);
        throw error;
    }
};

const createMergeOrder = async (fulfillmentOrderIds) => {
    try {
        const response = await axios.post(`${API_URL}orders/createMergeOrder`, {fulfillmentOrderIds}, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire store:', error);
        throw error;
    }
};

const createMergeRequest = async (fulfillmentOrderIds) => {
    try {
        const response = await axios.post(`${API_URL}orders/createMergeRequest`, {fulfillmentOrderIds}, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Failed to expire store:', error);
        throw error;
    }
};

const getInvoiceFile = (filename) => {
    return `${API_URL}invoice/getInvoiceFile/${filename}`;
};


export default {
    getInvoiceFile,
    fetchMergesFromOrderId,
    fetchMergeRequests,
    createMergeRequest,
    createMergeOrder,
    fetchMergeOrders,
    storeDeleteStatus,
    storeDelete,
    expireStore,
    productDeleteStatus,
    expireProduct,
    expireMerchant,
    merchantDeleteStatus,
    fetchSourcingInfo,
    fetchOrdersInfo,
    fetchMerchantOrderItems,
    confirmQuotation,
    fetchQuotationsAndRequest,
    deleteMerchant,
    checkInvoiceInfoExistance,
    createInvoiceInfo,
    getUserInfo,
    fetchInvoiceAccountInfo,
    createSourcingRequest,
    createSourcingRequestWithImage,
    addToProducts,
    getStores,
    publishNoVariantProduct,
    publishProductWithVariants,
    deleteStore,
    deleteProduct,
    fetchOrdersByPageAndFilters,
    cancelOrder,
    createOrderManually,
    getProductsByStore,
    fetchInvoicesByPageAndFilters,
    fetchInvoiceDetails,
    fetchInstallationLink,
    fetchSourcingDataByPageAndStatus,
    fetchAllShopifyProductsWithFilterAndPagination,
    fetchUserProductsWithFilterAndPagination,
    createTransactionProof,
    fetchInvoiceInfo,
    updateInvoiceInfo
};
