import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import authService from '../services/authService';

const StandardRoute = () => {
    const [authenticated, setAuthenticated] = useState(null);
    const [role, setRole] = useState(null);
    const [isAuthChecked, setIsAuthChecked] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const auth = await authService.authenticate();
            setAuthenticated(auth.authenticated);
            setRole(auth.role);
            setIsAuthChecked(true);
        };

        checkAuth();
    }, []);

    if (!isAuthChecked) {
        return null; // Visa en laddningsindikator om du vill
    }

    if (!authenticated) {
        return <Navigate to="/login" />;
    }

    if (role === 'Admin') {
        return <Navigate to="/admin" />;
    } else if (role === 'Supplier') {
        return <Navigate to="/supplier" />;
    } else if (role === 'Merchant') {
        return (
            <Outlet /> // Renderar MerchantLayout och alla underliggande rutter
        );
    }

    return <Navigate to="/unauthorized" />;
};

export default StandardRoute;
