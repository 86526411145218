import React, { useEffect } from 'react';
import styles from './DataProtectionAgreement.module.scss';
import { Link } from 'react-router-dom';

const DataProtectionAgreement = () => {
    useEffect(() => {
        document.title = 'Data Protection Agreement | Qiwi';
    }, []);  

    return (
        <div className={styles.policycontainer}>
            <h1>Data Protection Agreement</h1>

            <section>
                <h2>Introduction</h2>
                <p>
                    This Data Protection Agreement (DPA) outlines our commitments to protecting personal data for merchants and suppliers using Qiwi, focusing on data derived from Shopify and data generated within our platform.
                </p>
            </section>

            <section>
                <h2>Scope</h2>
                <p>This DPA applies to all personal data processed by Qiwi in connection with the services provided to merchants and suppliers.</p>
            </section>

            <section>
                <h2>Data Collection and Usage</h2>
                <h3>Shopify Collected Data</h3>
                <p>
                    Data collected from Shopify stores and their customers via Shopify's webhooks and APIs.
                </p>
                <h4>Shopify Data (Merchant-Specific)</h4>
                <ul>
                    <li><strong>Shop Information:</strong> Shop Name, Access Token.</li>
                    <li><strong>Order Information:</strong> Shopify Order ID, Product ID, Quantity.</li>
                </ul>

                <h4>Customer Data via Shopify</h4>
                <ul>
                    <li><strong>Personal Information:</strong> Customer ID, Full Name, Company Name.</li>
                    <li><strong>Delivery Information:</strong> Address, City, State, Postal Code, Country.</li>
                </ul>

                <h4>Internally Generated Data</h4>
                <ul>
                    <li><strong>User Data:</strong> Full Name, Email Address, Password (encrypted).</li>
                    <li><strong>Invoice Information:</strong> VAT Number, Organization Number, Contact Person, Billing Address, Phone Number.</li>
                </ul>
            </section>

            <section>
                <h2>Data Security Measures</h2>
                <p>We employ encryption, access control, SSL/TLS, and CORS policies to ensure data security.</p>
            </section>

            <section>
                <h2>Data Retention and Deletion</h2>
                <p>We retain personal data only as long as necessary to fulfill its intended purpose and comply with legal obligations.</p>
            </section>

            <section>
                <h2>Contact Information</h2>
                <p>For any concerns or inquiries related to data protection, please contact us at Info@qiwifulfill.com</p>

                <div className={styles.contactInfo}>
                <p>Qiwi Support</p>
                <p>Rådmansgatan 6A</p>
                <p>114 25 Stockholm</p>
                <p>Sweden</p>
            </div>
            </section>

            <p className={styles.updatedDate}><em>Last updated: 30 Aug 2024</em></p>

            <Link to="/" className={styles.homeButton}>Go to Homepage</Link>
        </div>
    );
};

export default DataProtectionAgreement;
