import axios from 'axios';


const API_URL = process.env.REACT_APP_API_BASE_URL;


const manageShopifySession = async (userId) => {
  const shopifyShop = getCookie('shopify_shop');

  if (shopifyShop) {

    const response = await axios.post(API_URL + 'store/bindUserStore', {
      userId,
      storeName: shopifyShop
    }, {
      withCredentials: true
    });
    deleteCookie('shopify_shop');
    return response.data;
  }
}

const login = async (email, password) => {
  const response = await axios.post(API_URL + 'user/login', {
    email,
    password,
  }, {
    withCredentials: true
  });
  if (response.data.token) {
    const {unexpectedUser = false, expiring = false} = await manageShopifySession(response.data.userId) || {};
    if(response.data.expiring) {
      sessionStorage.setItem('expiring', response.data.expiring);
    }
    return {
      token: response.data.token,
      unexpectedUser: unexpectedUser,
      role: response.data.role,
      expiring: expiring
    };
  }
  return response.data;
};


const register = async (merchantName, password, email) => {
  const response = await axios.post(API_URL + 'user/registerUserAndMerchant', {
    password,
    email,
    role: 'Merchant',
    merchantName
  }, {
    withCredentials: true
  });

  if (response.data.token) {
  const {unexpectedUser = false, expiring = false} = await manageShopifySession(response.data.userId) || {};
    return {
      token: response.data.token,
      unexpectedUser: unexpectedUser,
      expiring: expiring,
      role: response.data.role
  };
  }
  return response.data;
};


const logout = async () => {
  try {
    await axios.get(API_URL + 'user/logout', { withCredentials: true });
    sessionStorage.removeItem('expiring'); // Ta bort 'expiring' från sessionStorage
  } catch (error) {
    throw error;
  }
};


const authenticate = async () => {
  try {
    const response = await axios.get(API_URL + 'user/authenticate', {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    return { authenticated: false, role: null };
  }
};

const checkLoginSkip = async (cookie) => {
  try {
    const response = await axios.get(API_URL + `user/checkLoginSkip/${cookie}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    return { authenticated: false, role: null };
  }
};

const checkStoreAndUserExistance = async (shopifyShopValue) => {
  try {
    const response = await axios.get(API_URL + `user/checkStoreAndUserExistance/${shopifyShopValue}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    return { authenticated: false, role: null };
  }
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function deleteCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const changePassword = async (newPassword) => {
  try {
    const response = await axios.post(API_URL + 'user/change-password', {
      newPassword
    }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const forgotPassword = async (email) => {
  try {
      const response = await axios.post(`${API_URL}user/forgot-password`, { 
        email 
      }, {
        withCredentials: true
      })
      return response.data;
  } catch (error) {
      throw error;
  }
};

const resetPassword = async (token, newPassword) => {
  try {
    const response = await axios.post(API_URL + 'user/reset-password', { 
      token, newPassword 
    }, {
      withCredentials: true
    })
    return response.data;
  } catch (error) {
    throw error;
  }
};

const checkResetPassword = async (token) => {
  try {
    const response = await axios.get(API_URL + `user/checkResetPassword/${token}`, {  
    }, {
      withCredentials: true
    })
    return response.data;
  } catch (error) {
    throw new Error('Failed to access reset password');
  }
};

export default {
  checkResetPassword,
  resetPassword,
  forgotPassword,
  changePassword,
  login,
  register,
  logout,
  authenticate,
  checkLoginSkip,
  checkStoreAndUserExistance
};

