import React, { useEffect, useRef, useState } from 'react';
import styles from './InfoBox.module.scss';

const InfoBox = ({ content, wantRight }) => {
    const boxRef = useRef(null);
    const [positionClass, setPositionClass] = useState('');

    useEffect(() => {
        const adjustPosition = () => {
            if (boxRef.current) {
                const rect = boxRef.current.getBoundingClientRect();
                const isOverflowingRight = rect.right > window.innerWidth;
                const isOverflowingLeft = rect.left < 0;

                if (isOverflowingRight) {
                    setPositionClass(styles.bottomright);
                } else if (isOverflowingLeft) {
                    setPositionClass(''); // Default left position
                }

                if(wantRight) {
                    setPositionClass(styles.right)
                }
            }
        };

        adjustPosition();
        window.addEventListener('resize', adjustPosition);

        return () => {
            window.removeEventListener('resize', adjustPosition);
        };
    }, []);

    return (
        <div ref={boxRef} className={`${styles.dropdownToggle} ${positionClass}`}>
            {Array.isArray(content) ? (
                content.map((item, index) => <p key={index}>{item}</p>)
            ) : (
                <p>{content}</p>
            )}
        </div>
    );
};

export default InfoBox;
