import React, { useRef, useState, useEffect } from 'react';
import InfoBox from './InfoBox';
import styles from './HoverableOverflowText.module.scss';

const HoverableOverflowText = ({ text }) => {
    const textRef = useRef(null);
    const [isOverflow, setIsOverflow] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const element = textRef.current;
        if (element) {
            setIsOverflow(element.scrollWidth > element.clientWidth);
        }
    }, [text]);

    return (
        <div className={styles.container}>
            <div
                className={`${styles.textContainer} ${isOverflow && styles.hoverable}`}
                ref={textRef}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {text}
            </div>
            {isOverflow && isHovered && <InfoBox content={text} />}
        </div>
    );
};

export default HoverableOverflowText;
