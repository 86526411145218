import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import Modal from 'react-modal';
import styles from './SupportTickets.module.scss';
import HoverableOverflowText from '../../components/HoverableOverflowText';

const SupportTickets = () => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [filter, setFilter] = useState('all');

    const filterOptions = [
        { value: 'all', label: 'All' },
        { value: 'false', label: 'Unread' },
        { value: 'true', label: 'Read' },
    ];

    const handleFilterChange = (filter) => {
        setFilter(filter);
        setCurrentPage(1);
    };


    const fetchTickets = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchSupportTickets(filter, currentPage, itemsPerPage, searchQuery, startDate, endDate);
            setData(response || []); // Ensure the response is always an array
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, [currentPage, itemsPerPage]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const openModal = async (ticket) => {
        setSelectedTicket(ticket);
        setModalIsOpen(true);
        if (!ticket.viewed) {
            try {
                await adminService.setTicketViewed(ticket.id);
                fetchTickets(); // Uppdatera listan efter att ticket har markerats som visad
            } catch (error) {
                console.error('Failed to set ticket as viewed:', error);
            }
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedTicket(null);
    };

    const applyFilters = () => {
        fetchTickets();
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Support Tickets</h1>
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent
                    backgroundColor='white'
                    statusFilter={filter} 
                    setSelectFilter={handleFilterChange} // No-op
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    statusOptions={filterOptions} // No status options for products
                    searchPlaceholder="Search by Ticket Id..."
                    includeSearch={true}
                    selectLabel="Status"
                    applyFilters={applyFilters}
                />
            </div>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('id')}>
                                <div className={styles.thHeader}>
                                    Ticket ID
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('User.email')}>
                                <div className={styles.thHeader}>
                                    User
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'User.email' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'User.email' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Read</th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Created At
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Subject</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(ticket => (
                            <tr key={ticket.id} className={styles.rows}>
                                <td>{ticket.id}</td>
                                <td>{ticket.User && ticket.User.email}</td>
                                <td>{!ticket.viewed ? 
                                    <div className={styles.cancelIcon}>
<span class="material-symbols-outlined">
cancel
</span>
                                    </div>
                                     :
                                     <div className={styles.checkedIcon}>
                                     <span className="material-symbols-outlined">
                                     check_circle
                                     </span>
                                     </div>
                                     }</td>
                                <td>{new Date(ticket.createdAt).toLocaleDateString()}</td>
                                <td>
                                <HoverableOverflowText text={ticket.subject}/>
                                 </td>
                                <td className={styles.buttons}>
                                    <button onClick={() => openModal(ticket)} className={styles.ticketDetails}>Details</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Ticket Details"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                {selectedTicket && (
                    <div className={styles.modalContent}>
                        <h2>Ticket Details</h2>
                        <p><strong>Subject:</strong> {selectedTicket.subject}</p>
                        <p><strong>Message:</strong> {selectedTicket.message}</p>
                        <p><strong>Mail:</strong> {selectedTicket.mail}</p>
                        <button onClick={closeModal} className={styles.closeButton}>Close</button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default SupportTickets;
