import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import merchantService from '../../services/merchantService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import HoverableOverflowText from '../../components/HoverableOverflowText';

import styles from './Sourcing.module.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Detta är viktigt för tillgänglighet, ange roten för din app

const Sourcing = () => {
        useEffect(() => {
          document.title = 'Sourcing | Qiwi';
        }, []);

      
    const [sourcingData, setSourcingData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("all");
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState([]);
    const [showDate, setShowDate] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [noActiveRequests, setNoActiveRequests] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [loading, setLoading] = useState(true);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [expiring, setExpiring] = useState(false);

    useEffect(() => {
      const storedExpiring = sessionStorage.getItem('expiring');
      if (storedExpiring !== null) {
        setExpiring(storedExpiring === 'true'); // Konvertera strängen till en boolean
      }
    }, []);

    const navigate = useNavigate();

    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Responded', label: 'Responded' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'In Products', label: 'In Products' },
        { value: 'In Store', label: 'In Store' },
    ];
    const [sourcingInfo, setSourcingInfo] = useState([]);
    const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);

    useEffect(() => {
        const fetchOrdersInfo = async () => {
            try {
                setLoadingInitialInfo(true)
                const response = await merchantService.fetchSourcingInfo();
                setSourcingInfo(response);
                setLoadingInitialInfo(false)
            } catch (error) {
                console.error('Failed to fetch orders info:', error);
                setSourcingInfo([]);
            }
        };
        fetchOrdersInfo();
    }, []);


    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage, showArchived]);

    const fetchData = async () => {
        setLoading(true);
        let startDate, endDate;
        if (dateRange.length > 0) {
            startDate = dateRange[0].startDate;
            endDate = dateRange[0].endDate;
        }
        const data = await merchantService.fetchSourcingDataByPageAndStatus(
            statusFilter,
            currentPage,
            itemsPerPage,
            searchQuery,
            startDate,
            endDate,
            showArchived
        );
        setSourcingData(data.requests);
        setTotalPages(data.totalPages);
        setLoading(false);

        if (initialLoad) {
            if (!data.requests.length) {
                setNoActiveRequests(true);
                setFiltersApplied(false);
            } else {
                setNoActiveRequests(false);
                setFiltersApplied(true);
            }
            setInitialLoad(false);
        } else {
            setFiltersApplied(true);
            setNoActiveRequests(!data.requests.length);
        }
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const applyFilters = () => {
        setFiltersApplied(true);
        fetchData();
    };

    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setInitialLoad(true);
        setCurrentPage(1);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...sourcingData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : 0;
            }
            return 0;
        });
        setSourcingData(sortedData);
    };

    const viewQuotations = (requestId) => {
        navigate(`./quotations/${requestId}`);
    };

    const renderActionButton = (request) => {
        switch (request.status) {
            case 'Responded':
            case 'Confirmed':
            case 'In Products':
            case 'In Store':
                return <button onClick={() => viewQuotations(request.id)} className={styles.quotationsButton}>Quotations</button>;
            default:
                return null;
        }
    };

    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    const viewDetails = (request) => {
        setSelectedRequest(request);
        setIsDetailsModalOpen(true);
    };

    const handleClick = (e) => {
        if (expiring) {
          e.preventDefault(); // Förhindrar navigering
          alert('Your account is expiring, you cannot create a new request.');
        } else {
          navigate('/sourcing/create-request'); // Tillåt navigering
        }
      };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Sourcing</h1>
            </div>
            <div className={styles.infoContainers}>
            <div className={`${styles.infobox} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {! loadingInitialInfo &&
                    <>
                    <p>Total</p>
                    <h2>{sourcingInfo.totalCount}</h2>
                    </>
                    }
                </div>
                <div className={`${styles.infobox} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {! loadingInitialInfo &&
                    <>
                    <p>Pending</p>
                    <h2>{sourcingInfo.pendingCount}</h2>
                    </>
                    }
                </div>
                <div className={`${styles.infobox} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {! loadingInitialInfo &&
                    <>
                    <p>Responded</p>
                    <h2>{sourcingInfo.respondedCount}</h2>
                    </>
                    }
                </div>
                <div className={`${styles.infobox} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {! loadingInitialInfo &&
                    <>
                    <p>Confirmed</p>
                    <h2>{sourcingInfo.confirmedCount}</h2>
                    </>
                    }
                </div>
            </div>
            <div className={styles.top}>
                <FilterComponent
                    statusFilter={statusFilter}
                    setSelectFilter={setStatusFilter}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    fetchData={fetchData}
                    statusOptions={statusOptions}
                    includeSearch={true}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder="Search Product Name..."
                    showDate={showDate}
                    setShowDate={setShowDate}
                    selectLabel="Status"
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                    applyFilters={applyFilters}
                    dataType="Sourcing Requests"
                    filtersDisabled={(!filtersApplied && noActiveRequests) || loading}
                />
                    <Link 
                    to="/sourcing/create-request" 
                    className={`${styles.createButton} ${expiring ? styles.disabled : ''}`}
                    onClick={handleClick}
                    >
                    + Create Request
                    </Link>           
            </div>
            {!noActiveRequests &&
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('id')}>
                                <div className={styles.thHeader}>
                                    Request ID
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('productName')}>
                                <div className={styles.thHeader}>
                                    Product Info
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'productName' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'productName' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Date
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('status')}>
                                <div className={styles.thHeader}>
                                    Status
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'status' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'status' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className={styles.thHeader}>
                                    Actions
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading
                            ? Array.from({ length: 5 }).map((_, index) => (
                                <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                    <td colSpan={5}></td>
                                </tr>
                              ))
                            : sourcingData.map(request => (
                                <tr key={request.id} className={styles.rows}>
                                    <td>{request.id}</td>
                                    <td className={styles.productInfo}>
                                            {request.image && <img src={`/uploads/productImages/${request.image}`} alt={request.productName} className={styles.productImage} onClick={() => viewImage(`/uploads/productImages/${request.image}`)} />}
                                            <HoverableOverflowText text={request.productName} className={styles.hoverableOverflowText}/>
                                    </td>
                                    <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                                    <td>
                                        {request.status}</td>
                                    <td className={styles.actions}>
                                        <div className={styles.buttonGroup}>
                                            <button className={styles.detailsButton} onClick={() => viewDetails(request)}>Details</button>
                                            {renderActionButton(request)}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            }
            {totalPages > 0 && (
                <PaginationComponent
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                />
            )}
            {!loading && noActiveRequests && (
                showArchived ? (
                    <p className={styles.noFound}>No archived requests found{filtersApplied && ", check filtering"}</p>
                ) : (
                    <p className={styles.noFound}>No active requests found{filtersApplied && ", check filtering"}</p>
                )
            )}

            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
            </Modal>

            <Modal
                isOpen={isDetailsModalOpen}
                onRequestClose={() => setIsDetailsModalOpen(false)}
                className={styles.modaltwo}
                overlayClassName={styles.overlay}
            >
                {selectedRequest && (
                    <div className={styles.detailsContainer}>
                        {selectedRequest.image && (
                            <div className={styles.imageContainer}>
                                <img src={`/uploads/productImages/${selectedRequest.image}`} alt={selectedRequest.productName} />
                            </div>
                        )}
                        <div className={styles.detailsRows}>
                        <h2>Request Details</h2>
<div>
    <p><strong>Request ID</strong></p>
    <p>{selectedRequest.id}</p>
</div>
<div>
    <p><strong>Product Name</strong></p>
    <p>{selectedRequest.productName}</p>
</div>
<div>
    <p><strong>URL</strong></p>
    <p><a href={selectedRequest.url} target="_blank" rel="noopener noreferrer">{selectedRequest.url}</a></p>
</div>
<div>
    <p><strong>Created At</strong></p>
    <p>{new Date(selectedRequest.createdAt).toLocaleDateString()}</p>
</div>
{selectedRequest.status && (
    <div>
        <p><strong>Status</strong></p>
        <p>{selectedRequest.status}</p>
    </div>
)}
{selectedRequest.description && (
    <div>
        <p><strong>Description</strong></p>
        <p>{selectedRequest.description}</p>
    </div>
)}
{selectedRequest.note && (
    <div>
        <strong>Note</strong>
        <pre>{selectedRequest.note}</pre>
    </div>
)}

                        </div>
                    </div>
                )}
            <button onClick={() => setIsDetailsModalOpen(false)} className={styles.closeButton}>Close</button>
            </Modal>
        </div>
    );
};

export default Sourcing;
