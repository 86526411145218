import React, { useState, useRef, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styles from './FilterComponent.module.scss';

const FilterComponent = ({
    backgroundColor,
    statusFilter,
    setSelectFilter,
    selectLabel,
    dateRange,
    setDateRange,
    searchQuery,
    setSearchQuery,
    statusOptions,
    searchPlaceholder,
    includeSearch,
    showArchived,
    setShowArchived,
    applyFilters,
    dataType,
    filtersDisabled
}) => {
    const [showDate, setShowDate] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const datepickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                datepickerRef.current &&
                !datepickerRef.current.contains(event.target) &&
                !event.target.closest(`.${styles.dateContainer}`)
            ) {
                setShowDate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datepickerRef]);

    const toggleDate = () => {
        setShowDate(prevShowDate => !prevShowDate);
    };

    const handleStatusChange = (e) => {
        setSelectFilter(e.target.value);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const clearDates = () => {
        setDateRange([]);
        setShowDate(false);
    };

    const clearFilters = () => {
        if (statusFilter && statusFilter.length > 0) {
            setSelectFilter("all");
        }
        setDateRange([]);
        if (includeSearch) {
            setSearchQuery('');
        }
    };

    const handleToggleArchived = (value) => {
        setShowArchived(value);
        clearFilters();
    };

    const toggleSearch = () => {
        if (showSearch) {
            setSearchQuery(''); // Reset search query when hiding the search input
        }
        setShowSearch(!showSearch);
    };

    return (
        <div className={styles.filterContainer}>
            <div className={styles.option} style={{ backgroundColor: backgroundColor }}>
                <a
                    onClick={() => handleToggleArchived(false)}
                    className={!showArchived ? styles.active : ''}
                    disabled={!showArchived ? true : false}
                >
                    Active {dataType}
                </a>
                <a
                    onClick={() => handleToggleArchived(true)}
                    className={showArchived ? styles.active : ''}
                    disabled={showArchived ? true : false}
                >
                    Archived {dataType}
                </a>
            </div>
            <div className={styles.filterOptions}>
                {statusFilter && statusFilter.length > 0 &&
                    <FormControl variant="outlined" size="small" disabled={filtersDisabled}>
                        <InputLabel id="status-filter-label">{selectLabel}</InputLabel>
                        <Select
                            value={statusFilter}
                            onChange={handleStatusChange}
                            label={selectLabel}
                            sx={{
                                color: "#202331",
                                "& .MuiSvgIcon-root": {
                                    color: "#202331",
                                },
                            }}
                        >
                            {statusOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                <div className={styles.dateContainer}>
                    <button className={styles.dateBtn} onClick={toggleDate} disabled={filtersDisabled}>
                        <span className="material-symbols-outlined">date_range</span>
                    </button>
                    {dateRange.length > 0 && (
                        <>
                            <div className={styles.dates} onClick={toggleDate}>
                                <span>{format(dateRange[0].startDate, 'MM/dd/yy')} &rarr; {format(dateRange[0].endDate, 'MM/dd/yy')}</span>
                            </div>
                            <button onClick={clearDates} className={styles.clearDateButton}>
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </>
                    )}
                    <div className={`${styles.datepicker} ${showDate ? styles.activeDate : styles.inactiveDate}`} ref={datepickerRef}>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setDateRange([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange.length > 0 ? dateRange : [{
                                startDate: new Date(),
                                endDate: new Date(),
                                key: 'selection'
                            }]}
                            maxDate={new Date()}
                        />
                    </div>
                </div>
                {includeSearch &&
                    <div className={styles.searchContainer}>
                        <button className={`${styles.searchBtn} ${showSearch ? styles.activeSearchBtn : styles.inactiveSearchBtn}`} onClick={toggleSearch} disabled={filtersDisabled}>
                            <span className="material-symbols-outlined">search</span>
                        </button>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder={searchPlaceholder}
                            className={`${styles.searchInput} ${showSearch ? styles.activeSearch : styles.inactiveSearch}`}
                            disabled={filtersDisabled}
                        />
                    </div>}
                <button onClick={applyFilters} className={styles.filterButton} disabled={filtersDisabled}>Search</button>
                <button onClick={clearFilters} variant="outlined" className={styles.clearButton} disabled={filtersDisabled}>Clear Filter</button>
            </div>
        </div>
    );
};

export default FilterComponent;
