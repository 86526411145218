import React from 'react';
import InfoBox from './InfoBox';
import styles from './GridInfoBox.module.scss';

const GridInfoBox = ({ grid }) => {
    const content = Object.entries(grid).map(([label, value], index) => (
        <React.Fragment key={index}>
            <span><strong>{label}:</strong></span>
            <span>{value ? value : 'NA'}</span>
        </React.Fragment>
    ));

    return (
        <InfoBox content={<div className={styles.gridContainer}>{content}</div>} />
    );
};

export default GridInfoBox;
