import styles from './SourcingQuotationDetail.module.scss';

const SourcingQuotationDetail = ({ responseDetail }) => {

    return (
        <div className={styles.container}>
            <div className={styles.detailContainer}>
                <div className={styles.detailItem}>
                    <strong>Product Name:</strong> {responseDetail.productName}
                </div>
                {responseDetail.hasVariants ? (
                    <div className={styles.tableContainer}>
                    <table className={styles.variantsTable}>
                        <thead className={styles.table_header}>
                            <tr className={styles.table_header}>
                                {responseDetail.categories.map((quotationcategory, index) => (
                                    <th key={index}>{quotationcategory}</th>
                                ))}
                                <th>SKU</th>
                                <th>Product Price</th>
                                <th>Shipping Price</th>
                                <th>Shipping Time</th>
                                <th>Processing Time</th>
                                {/*<th>Image</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {responseDetail.variants.map((variant, index) => (
                                <tr key={index} className={styles.rows}>
                                    {responseDetail.categories.map((quotationcategory, catIndex) => (
                                        <td key={catIndex}>{variant[quotationcategory]}</td>
                                    ))}
                                    <td>{variant.SKU}</td>
                                    <td>${variant.productPrice}</td>
                                    <td>${variant.shippingPrice}</td>
                                    <td>{variant.shippingTime}</td>
                                    <td>{variant.processingTime}</td>
                                    {/*<td>
                                            <img src={variant.image? `/uploads/${variant.image}` : 'https://cdn3.iconfinder.com/data/icons/design-n-code/100/272127c4-8d19-4bd3-bd22-2b75ce94ccb4-512.png'} alt={`Variant ${index + 1}`}></img>
                                    </td>*/}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                ) : (
                    <div className={styles.noVariantDetails}>
                        <div className={styles.detailItem}>
                            <strong>Shipping Time:</strong> {responseDetail.shippingTime}
                        </div>
                        <div className={styles.detailItem}>
                            <strong>Shipping Price:</strong> ${responseDetail.shippingPrice}
                        </div>
                        <div className={styles.detailItem}>
                            <strong>Product Price:</strong> ${responseDetail.productPrice}
                        </div>
                        <div className={styles.detailItem}>
                            <strong>Total Price:</strong> ${parseFloat(responseDetail.shippingPrice) + parseFloat(responseDetail.productPrice)}
                        </div>
                        <div className={styles.detailItem}>
                            <strong>Processing Time:</strong> {responseDetail.processingTime}
                        </div>
                        <div className={styles.detailItem}>
                            <strong>SKU:</strong> {responseDetail.SKU}
                        </div>
                        {/*responseDetail.image && (
                            <div className={styles.detailItem}>
                                <strong>Product Image:</strong>
                                <img src={`/uploads/${responseDetail.image}`} alt={responseDetail.productName} className={styles.productImage} />
                            </div>
                        )*/} 
                    </div>
                )}
                {responseDetail.comments.length > 0 &&
                <div className={styles.detailItem}>
                    <strong>Comments:</strong> {responseDetail.comments}
                </div>
                }
            </div>
        </div>
    );
};

export default SourcingQuotationDetail;
