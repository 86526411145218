import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import adminService from '../../services/adminService';
import styles from './QuotationDetails.module.scss';
import HoverableOverflowText from '../../components/HoverableOverflowText';

const QuotationDetails = () => {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [markupData, setMarkupData] = useState({});
    const [feedback, setFeedback] = useState('');
    const [visibleItems, setVisibleItems] = useState({});
    const [selectedQuotations, setSelectedQuotations] = useState([]);

    const fetchQuotationDetails = async () => {
        try {
            const response = await adminService.fetchQuotationDetails(requestId);
            setData(response || {});
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData({});
        }
    };

    useEffect(() => {
        fetchQuotationDetails();
    }, [requestId]);

    useEffect(() => {
        fetchQuotationDetails();
    }, [markupData]);

    const handleInfoClick = (index) => {
        setVisibleItems((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const handleGiveFeedback = (quotationId) => {
        setMarkupData({ quotationId, type: 'feedback' });
        setShowModal(true);
    };

    const handleSetMarkup = (quotationId, variants, noVariant) => {
        if (variants.length > 0) {
            setMarkupData({
                quotationId,
                type: 'markup',
                variants: variants.map(variant => ({
                    ...variant,
                    markup: variant.markup || ''
                })),
                noVariantMarkup: '',
                defaultMarkup: ''
            });
        } else {
            setMarkupData({
                quotationId,
                type: 'markup',
                variants: [],
                noVariantMarkup: noVariant.markup || '',
                defaultMarkup: '',
                noVariant
            });
        }
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setMarkupData({});
    };

    const handleFeedbackSubmit = async () => {
        try {
            await adminService.giveFeedback(markupData.quotationId, feedback);
            setShowModal(false);
            fetchQuotationDetails();
        } catch (error) {
            console.error('Failed to submit feedback:', error);
        }
    };

    const handleMarkupSubmit = async () => {
        if (markupData.variants.length > 0) {
            const allVariantsHaveMarkup = markupData.variants.every(variant => {
                const markup = parseFloat(variant.markup || markupData.defaultMarkup);
                return markup > 0;
            });

            if (!allVariantsHaveMarkup) {
                alert('All variants must have a positive markup value, either default or specific.');
                return;
            }

            try {
                await adminService.setMarkup(markupData.quotationId, markupData.variants.map(variant => ({
                    ...variant,
                    markup: variant.markup || markupData.defaultMarkup
                })));
                setShowModal(false);
                fetchQuotationDetails();
            } catch (error) {
                console.error('Failed to set markup:', error);
            }
        } else {
            try {
                await adminService.setMarkupNoVariant(markupData.quotationId, markupData.noVariantMarkup);
                setShowModal(false);
                fetchQuotationDetails();
            } catch (error) {
                console.error('Failed to set markup:', error);
            }
        }
    };

    const handleMarkupChange = (index, value) => {
        setMarkupData(prevData => ({
            ...prevData,
            variants: prevData.variants.map((variant, i) => {
                if (i === index) {
                    return { ...variant, markup: value };
                }
                return variant;
            })
        }));
    };

    const handleDefaultMarkupChange = (value) => {
        setMarkupData(prevData => ({
            ...prevData,
            defaultMarkup: value
        }));
    };

    const handleCheckboxChange = (quotationId) => {
        setSelectedQuotations(prevSelected => {
            if (prevSelected.includes(quotationId)) {
                return prevSelected.filter(id => id !== quotationId);
            } else {
                return [...prevSelected, quotationId];
            }
        });
    };

    const handleSubmitSelected = async () => {
        const quotationsWithMarkupIssues = selectedQuotations.filter(quotationId => {
            const quotation = data.quotations.find(q => q.id === quotationId);
            if (quotation.noVariant) {
                return !quotation.noVariant.markup;
            } else {
                return quotation.variants.some(variant => !variant.markup);
            }
        });

        if (quotationsWithMarkupIssues.length > 0) {
            alert('All selected quotations must have a positive markup.');
            return;
        }

        try {
            await adminService.submitQuotations(requestId, selectedQuotations);
            alert('Quotations submitted successfully.');
            navigate('/admin/sourcing-requests/');
        } catch (error) {
            console.error('Failed to submit selected quotations:', error);
        }
    };

    const createDetailRow = (variants) => {
        return (
            <td colSpan="6" className={styles.detailRow}>
                <table>
                    <thead>
                        <tr>
                            <th>SKU</th>
                            <th>Product Price</th>
                            <th>Shipping Price</th>
                            <th>Processing Time</th>
                            <th>Shipping Time</th>
                            <th>Variant</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variants.map((variant, index) => (
                            <tr key={`variant-${variant.id}-${index}`}>
                                <td>{variant.SKU}</td>
                                <td>{variant.productPrice}</td>
                                <td>{variant.shippingPrice}</td>
                                <td>{variant.processingTime}</td>
                                <td>{variant.shippingTime}</td>
                                <td>
                                {variant.categoryVariants.map(cv => cv.value).join(' / ')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <div className={styles.container}>
            <h1>Quotation Details for {data.productName}</h1>
            <div className={styles.requestDetails}>
                <p><strong>Description:</strong> {data.description}</p>
                <p><strong>URL:</strong> <a href={data.url} target="_blank" rel="noopener noreferrer">{data.url}</a></p>
                <p><strong>Note:</strong> {data.note}</p>
                <p><strong>Requested By:</strong> {data.merchant}</p>
                {data.image && <img src={data.image} alt={data.productName} className={styles.productImage} />}
            </div>
            {data.quotations && data.quotations.length > 0 ? (
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Supplier</th>
                                <th>Feedback</th>
                                <th colSpan={2}>Details</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.quotations.map((quotation, qIndex) => (
                                <React.Fragment key={quotation.id}>
                                    <tr className={visibleItems[qIndex] ? styles.droppeddown : styles.rows}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedQuotations.includes(quotation.id)}
                                                onChange={() => handleCheckboxChange(quotation.id)}
                                                className={styles.checkBox}
                                            />
                                        </td>
                                        <td>{quotation.supplierName}</td>
                                        <td>
                                        <HoverableOverflowText text={quotation.feedback}/>                                        
                                        </td>
                                        {quotation.noVariant ? (
                                            <>
                                            <td>
                                                Product Price: {quotation.noVariant.productPrice}
                                                <br/>
                                                Shipping Price: {quotation.noVariant.shippingPrice}
                                            </td>
                                            <td> 
                                                Processing Time: {quotation.noVariant.processingTime}
                                                <br/>
                                                Shipping Time: {quotation.noVariant.shippingTime}
                                            </td>
                                            </>
                                        ) : (
                                                <td colSpan={2}>
                                                <button onClick={() => handleInfoClick(qIndex)} className={styles.toggleRowsButton}>
                                                {quotation.VariantCount} variants <span className="material-symbols-outlined">{visibleItems[qIndex] ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                                                </button>
                                                </td>
                                        )}
                                        <td className={styles.buttons}>
                                            <button onClick={() => handleGiveFeedback(quotation.id)} className={styles.feedbackBtn}>Give Feedback</button>
                                            <button onClick={() => handleSetMarkup(quotation.id, quotation.variants || [], quotation.noVariant || {})} className={styles.markupBtn}>
                                                {quotation.noVariant?.markup || quotation.variants?.some(v => v.markup) ? 'Edit and View Markup' : 'Set Markup'}
                                            </button>
                                        </td>
                                    </tr>
                                    {visibleItems[qIndex] && quotation.variants && (
                                        createDetailRow(quotation.variants)
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={handleSubmitSelected}>Submit Selected Quotations</button>
                </div>
            ) : (
                <p>No quotations found</p>
            )}
            <Modal
                isOpen={showModal}
                onRequestClose={handleModalClose}
                contentLabel="Details Modal"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                {markupData.type === 'feedback' ? (
                    <div className={styles.modalContent}>
                        <h2>Give Feedback</h2>
                        <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            placeholder="Enter your feedback"
                        />
                        <button onClick={handleFeedbackSubmit}>Submit</button>
                        <button onClick={handleModalClose}>Cancel</button>
                    </div>
                ) : (
                    <div className={styles.modalContent}>
                        <h2>Set Markup</h2>
                        {markupData.variants && markupData.variants.length > 0 ? (
                            <>
                                <div>
                                    <label>Default Markup:</label>
                                    <input
                                        type="number"
                                        min="0"
                                        onChange={(e) => handleDefaultMarkupChange(e.target.value)}
                                        value={markupData.defaultMarkup || ''}
                                    />
                                </div>
                                <div className={styles.variantMarkups}>
                                    {markupData.variants.map((variant, index) => (
                                        <div key={index}>
                                            <h3>Variant {index + 1}</h3>
                                            <p>{variant.categoryVariants.map(cv => `${cv.quotationcategory}: ${cv.value}`).join(', ')}</p>
                                            <div>
                                                <label>Product Price:</label>
                                                <span>{variant.productPrice}</span>
                                            </div>
                                            <div>
                                                <label>Shipping Price:</label>
                                                <span>{variant.shippingPrice}</span>
                                            </div>
                                            <div>
                                                <label>Markup:</label>
                                                <input
                                                    type="number"
                                                    min="0"
                                                    value={variant.markup}
                                                    onChange={(e) => handleMarkupChange(index, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <label>Product Price:</label>
                                    <span>{markupData.noVariant?.productPrice || 0}</span>
                                </div>
                                <div>
                                    <label>Shipping Price:</label>
                                    <span>{markupData.noVariant?.shippingPrice || 0}</span>
                                </div>
                                <div>
                                    <label>Markup:</label>
                                    <input
                                        type="number"
                                        min="0"
                                        value={markupData.noVariantMarkup !== '' ? markupData.noVariantMarkup : 0}
                                        onChange={(e) => setMarkupData({ ...markupData, noVariantMarkup: e.target.value })}
                                    />
                                </div>
                            </>
                        )}
                        <button onClick={handleMarkupSubmit}>Submit</button>
                        <button onClick={handleModalClose}>Cancel</button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default QuotationDetails;
