import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import styles from '../shared/FormStyles.module.scss';
import authService from '../../services/authService';
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Importera LoadingButtonDots-komponenten

const ResetPassword = () => {
    useEffect(() => {
        document.title = 'Reset Password | Qiwi';
      }, []);  
    const { token } = useParams();
    const navigate = useNavigate(); 
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Lägg till en state för att hantera laddningsindikatorn

    useEffect(() => {
        if (token) {
            validateResetToken();
        }
    }, [token]);

    const validateResetToken = async () => {
        try {
            await authService.checkResetPassword(token);
        } catch (error) {
            window.alert('Invalid or expired password reset link.');
            navigate('/forgot-password');
        }
    };

    const handlePasswordChange = async () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
    
        const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;
        if (!passwordStrength.test(password)) {
            alert('Password must be at least 7 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character');
            setError('Password')
            return;
        }

        setLoading(true); // Sätt loading till true när lösenordet ändras

        try {
            await authService.resetPassword(token, password);
            
            // Om backend skickar tillbaka en framgångsrik respons
            alert("Password changed successfully");
            navigate('/login'); 
    
        } catch (error) {

            // Hantera fel som kommer från servern
            if (error.response && error.response.data && error.response.data.message) {
                setError(error.response.data.message); // Sätt felmeddelandet från servern
            } else {
                setError('Failed to reset password. Please try again.'); // Standardfelmeddelande
            }
        } finally {
            setLoading(false); // Sätt loading till false när processen är klar
        }
    };

    const handlePasswordInputChange = (e) => {
        setPassword(e.target.value);
        if (error && error.includes('Password')) {
            setError(''); // Rensa felmeddelandet när användaren börjar skriva i lösenordsfältet
        }
    };

    const handleConfirmPasswordInputChange = (e) => {
        setConfirmPassword(e.target.value);
        if (error === 'Passwords do not match') {
            setError(''); // Rensa felmeddelandet när användaren börjar skriva i bekräfta lösenord-fältet
        }
    };

    return (
        <div className={styles.container}>
            <h1>qiwi</h1>
            <form className={styles.form}>
                <h2>Reset Password</h2>
                <div className={styles.inputs}>
                    <div className={styles.passwordInput}>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={password}
                            onChange={handlePasswordInputChange}
                            required
                            className={`${styles.input} ${error && error.includes('Password') ? styles.errorInput : ''}`}
                        />
                    </div>
                    <div className={styles.passwordInput}>
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordInputChange}
                            required
                            className={`${styles.input} ${error === 'Passwords do not match' ? styles.errorInput : ''}`}
                        />
                        {error && <p className={styles.errorMessageBottom}>{error}</p>}
                    </div>
                    <button type="button" onClick={handlePasswordChange} className={styles.button} disabled={loading}>
                        {loading ? <LoadingButtonDots /> : 'Change Password'}
                    </button>
                </div>
                <div className={styles.registerLoginLink}>
                    <Link to="../login">Go to login</Link>
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
