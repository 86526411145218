import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Support.module.scss';
import userService from '../../services/userService';
import HoverableOverflowText from '../../components/HoverableOverflowText';

function Support() {
    useEffect(() => {
        document.title = 'Support | Qiwi';
      }, []);
      
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true); // Lägg till loading state

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const response = await userService.fetchUserTickets();
                setTickets(response || []);
            } catch (error) {
                console.error('Failed to fetch tickets:', error);
            } finally {
                setLoading(false); // Sätt loading till false när datan har laddats
            }
        };

        fetchTickets();
    }, []);

    const handleCreateTicket = () => {
        navigate('./create-ticket'); // Navigera till sidan för att skapa en ny ticket
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Support Tickets</h1>
            </div>
            <button className={styles.createTicketButton} onClick={handleCreateTicket}>
                {tickets.length === 0 && !loading ? 'No tickets found, create one' : 'Create New Ticket'}
            </button>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={styles.ticketIdColumn}>Ticket Id</th>
                            <th className={styles.subjectColumn} colSpan={2}>Subject</th>
                            <th className={styles.messageColumn} colSpan={2}>Message</th>
                            <th colSpan={2}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            // Placeholder rows when loading
                            Array.from({ length: 5 }).map((_, index) => (
                                <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                    <td colSpan="7"></td>
                                </tr>
                            ))
                        ) : (
                            tickets.map(ticket => (
                                <tr key={ticket.id}>
                                    <td className={styles.ticketIdColumn}>{ticket.id}</td>
                                    <td className={styles.subjectColumn} colSpan={2}>{ticket.subject}</td>
                                    <td className={styles.messageColumn} colSpan={2}>
                                        <HoverableOverflowText text={ticket.message}/>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Support;
