import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Products.module.scss';
import Modal from 'react-modal';

const Products = () => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [hoveredProductId, setHoveredProductId] = useState(null);
    const [visibleVariants, setVisibleVariants] = useState({});
    const [hoveredVariantId, setHoveredVariantId] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProductToDelete, setSelectedProductToDelete] = useState({});
    const [selectedProductToDeleteOrders, setSelectedProductToDeleteOrders] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const deleteProductModal = async (product) => {
        const orders = await adminService.productDeleteStatus(product.id);
        setSelectedProductToDelete(product);
        setSelectedProductToDeleteOrders(orders.orders);
        setIsDeleteModalOpen(true);
    }

    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    const viewInfo = (product) => {
        setSelectedProduct(product);
        setIsInfoModalOpen(true);
    };

    const handleMouseEnter = (productId) => {
        setHoveredProductId(productId);
    };
    
    const handleMouseLeave = () => {
        setHoveredProductId(null);
    };

    const handleMouseEnterVariant = (variantId) => {
        setHoveredVariantId(variantId);
    };
    
    const handleMouseLeaveVariant = () => {
        setHoveredVariantId(null);
    };


    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setCurrentPage(1);
    };

    const applyFilters = () => {
        fetchProducts();
    };

    const fetchProducts = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchProductsByPageAndFilters(currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived);
            setData(response.products || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [currentPage, itemsPerPage, showArchived]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const toggleVariantsDetail = (productId) => {
        setVisibleVariants(prev => ({
            ...prev,
            [productId]: !prev[productId]
        }));
    };

    const expireProduct = async (productId) => {
        const confirmed = window.confirm("Are you sure you want to expire this product? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.expireProduct(productId);
                alert("Product expired successfully.");
            } catch (error) {
                console.error("Error expiring product:", error);
                alert("Failed to expire product.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };
    
    const deleteProduct = async (productId) => {
        const confirmed = window.confirm("Are you sure you want to delete this product? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.productDelete(productId);
                alert("Product deleted successfully.");
            } catch (error) {
                console.error("Error deleting product:", error);
                alert("Failed to delete product.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };
    
    const createDetailRow = (product) => {
        return (
            <td colSpan="8">
                <table>
                    <thead>
                        <tr>
                            <th>Variant ID</th>
                            <th>Image</th>
                            <th>Variant</th>
                            <th>SKU</th>
                            <th colSpan={3}>Stores</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.variants.map((variant, index) => (
                            <tr key={`variant-${product.id}-${index}`}>
                                <td>{variant.id}</td>
                                 <td>{variant.image && <img src={`/uploads/productImages/${variant.image}`} className={styles.variantImage} onClick={() => viewImage(`/uploads/productImages/${variant.image}`)} alt={product.name}/>}</td>
                                <td>{variant.categoryVariants.map((variant,index) => (
                                                    <p key={index}>{variant.category}: {variant.value}</p>
                                                ))}</td>
                                <td>{variant.SKU}</td>
                                <td 
                                        colSpan={4}
                                        onMouseEnter={() => handleMouseEnterVariant(variant.id)}
                                        onMouseLeave={handleMouseLeaveVariant}
                                        className={variant.stores.length > 1 && styles.hoverable}
                                    >
                                        {variant.stores.length === 1 ? variant.stores[0].shop : variant.stores.length}
                                        {hoveredVariantId === variant.id && variant.stores.length > 1 && (
                                            <div className={styles.dropdownToggle}>
                                                {variant.stores.map(store => (
                                                    <p key={store.id}>{store.shop}</p>
                                                ))}
                                            </div>
                                        )}
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Products</h1>
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent
                    backgroundColor='white'
                    statusFilter={''} // No status filter for products
                    setStatusFilter={() => {}} // No-op
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    fetchData={fetchProducts}
                    statusOptions={[]} // No status options for products
                    searchPlaceholder="Search by name..."
                    includeSearch={true}
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                    applyFilters={applyFilters}
                />
            </div>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('id')}>
                                <div className={styles.thHeader}>
                                    Product ID
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('name')}>
                                <div className={styles.thHeader}>
                                    Product
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'name' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'name' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('merchant')}>
                                <div className={styles.thHeader}>
                                    Owner
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'merchant' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'merchant' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('Supplier.name')}>
                                <div className={styles.thHeader}>
                                    Supplier
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'Supplier.name' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'Supplier.name' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Stores</th>
                            <th>Variants</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(product => (
                            <React.Fragment key={product.id}>
                                <tr className={visibleVariants[product.id] ? styles.droppeddown : styles.rows}>
                                    <td>{product.id}</td>
                                    <td>{product.image && <img src={`/uploads/productImages/${product.image}`} className={styles.variantImage} onClick={() => viewImage(`/uploads/productImages/${product.image}`)} alt={product.name}/>}{product.name}{product.description}</td>
                                    <td>{product.merchant}</td>
                                    <td>{`${product.supplierName}(${product.supplierAlias})`}</td>
                                    <td 
                                        onMouseEnter={() => handleMouseEnter(product.id)}
                                        onMouseLeave={handleMouseLeave}
                                        className={product.Stores.length > 1 && styles.hoverable}
                                    >
                                        {product.Stores.length === 1 ? product.Stores[0].shop : product.Stores.length}
                                        {hoveredProductId === product.id && product.Stores.length > 1 && (
                                            <div className={styles.dropdownToggle}>
                                                {product.Stores.map(store => (
                                                    <p key={store.id}>{store.shop}</p>
                                                ))}
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {product.variants.length > 0 &&
                                        <button 
                                            className={styles.toggleRowsButton}
                                            onClick={() => toggleVariantsDetail(product.id)}
                                        >
                                            {product.variants.length}
                                            <span className="material-symbols-outlined">
                                                {visibleVariants[product.id] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                            </span>
                                        </button>
                                        }
                                    </td>
                                    <td>{product.expiring ? 
                                    <div className={styles.hourglass}>
                                    <span className="material-symbols-outlined">
                                    hourglass_bottom
                                    </span>
                                    </div>
                                     :
                                     <div className={styles.checked}>
                                     <span className="material-symbols-outlined">
                                     check_circle
                                     </span>
                                     </div>
                                     }</td>
                                    <td className={styles.buttons}>
                                    <button className={styles.infoButton} onClick={() => viewInfo(product)}>Prices & Time</button>
                                    <button className={styles.deleteButton} onClick={() => deleteProductModal(product)}>Delete</button>
                                    </td>
                                </tr>
                                {visibleVariants[product.id] && product.variants.length > 0 && (
                                    <tr className={styles.detailRow} key={`details-${product.id}`}>
                                        {createDetailRow(product)}
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
            <PaginationComponent
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />

            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
            </Modal>

            <Modal
    isOpen={isDeleteModalOpen}
    onRequestClose={() => setIsDeleteModalOpen(false)}
    className={styles.modaltwo}
    overlayClassName={styles.overlay}
>
    <h2>Deleting Product</h2>
    <p>{selectedProductToDelete.name}</p>
    
    {selectedProductToDeleteOrders && selectedProductToDeleteOrders.length > 0 && (
        <>
        <p><strong>OBS. There are exisiting orders pending for this product</strong></p>
        <table>
            <thead>
                <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Quantity</th>
                </tr>
            </thead>
            <tbody>
                {selectedProductToDeleteOrders.map(order => (
                    <tr key={order.orderId}>
                        <td>{order.orderId}</td>
                        <td>{order.status}</td>
                        <td>{order.items[0]?.quantity}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    )}
    <div className={styles.buttonGroup}>
    {selectedProductToDeleteOrders && selectedProductToDeleteOrders.length > 0 ? 
    <button className={styles.expiringButton} onClick={() => expireProduct(selectedProductToDelete.id)}>Set as Expiring</button> :
    <button className={styles.deleteButton} onClick={() => deleteProduct(selectedProductToDelete.id)}> Delete</button>
    }
    </div>
</Modal>


            <Modal
    isOpen={isInfoModalOpen}
    onRequestClose={() => setIsInfoModalOpen(false)}
    className={styles.modaltwo}
    overlayClassName={styles.overlay}
>
    {selectedProduct && selectedProduct.variants && selectedProduct.variants.length > 0 ? (
        <div>
            <h2>{selectedProduct.name} - Variants Details</h2>
            <table className={styles.variantTable}>
                <thead>
                    <tr>
                        <th>SKU</th>
                        <th>Product Price</th>
                        <th>Shipping Price</th>
                        <th>Shipping Time</th>
                        <th>Processing Time</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedProduct.variants.map((variant, index) => (
                        <tr key={index}>
                            <td>{variant.SKU}</td>
                            <td>{variant.productPrice}</td>
                            <td>{variant.shippingPrice}</td>
                            <td>{variant.shippingTime}</td>
                            <td>{variant.processingTime}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    ) : (
        <div>
            <h2>{selectedProduct.name} - Product Details</h2>
            <table className={styles.variantTable}>
                <thead>
                    <tr>
                        <th>SKU</th>
                        <th>Product Price</th>
                        <th>Shipping Price</th>
                        <th>Shipping Time</th>
                        <th>Processing Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{selectedProduct.SKU}</td>
                        <td>{selectedProduct.productPrice}</td>
                        <td>{selectedProduct.shippingPrice}</td>
                        <td>{selectedProduct.shippingTime}</td>
                        <td>{selectedProduct.processingTime}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )}
</Modal>


        </div>
    );
};

export default Products;
