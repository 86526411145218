import React, { useState, useEffect } from 'react';
import authService from '../../services/authService';
import styles from '../shared/FormStyles.module.scss';
import { Link, useNavigate } from 'react-router-dom'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; 

const MerchantRegister = () => {
    useEffect(() => {
        document.title = 'Register | Qiwi';
      }, []);  

    const [merchantName, setMerchantName] = useState('');  // Ny state för Merchant/Company Name
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [existingStore, setExistingStore] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [termsAccepted, setTermsAccepted] = useState(false); 
    const navigate = useNavigate(); 

    const hasShopifyCookie = document.cookie.split('; ').find(row => row.startsWith('shopify_shop'));
    const shopifyShopValue = hasShopifyCookie ? hasShopifyCookie.split('=')[1] : null;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!termsAccepted) {
            setMessage('You must accept the Terms of Service');
            return;
        }

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;
        if (!passwordStrength.test(password)) {
            alert('Password must be at least 7 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character');
            setMessage('Password');
            return;
        }

        setLoading(true);

        try {
            const response = await authService.register(merchantName, password, email);  // Uppdatera för att inkludera merchantName
            if (response.unexpectedUser) {
                alert(`The store ${shopifyShopValue}, which you came from, is connected to another user`);
            }
            if(response.expiring) {
                alert(`Cannot connect store to expiring merchant`);
            }
            navigate('/sourcing');

        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            }        
        } finally {
            setLoading(false);
        }
    };

    const handleMerchantNameChange = (e) => {  // Ny funktion för att hantera ändringar i merchantName
        setMerchantName(e.target.value);
        if (message === "Merchant/Company Name is already connected to a user") {
            setMessage('');
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (message === "Email is already connected to a user") {
            setMessage('');
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (message.includes('Password')) {
            setMessage('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (message === "Passwords do not match") {
            setMessage('');
        }
    };

    const handleTermsChange = (e) => {
        setTermsAccepted(e.target.checked);
        if (message === 'You must accept the Terms of Service') {
            setMessage('');
        }
    };

    useEffect(() => {
        if (shopifyShopValue) {
            authService.checkStoreAndUserExistance(shopifyShopValue)
                .then((userstore) => {
                    if (userstore.existing) {
                        setExistingStore(true);
                    }
                })
                .catch(error => {
                    console.error('Error checking store existence:', error);
                    setMessage('Error checking store existence');
                });
        }
    }, [shopifyShopValue]);

    return (
        <div className={styles.container}>
            <h1>qiwi</h1>
            {(!existingStore && hasShopifyCookie) && <p>Login or register to connect store</p>}
            <form onSubmit={handleSubmit} className={styles.form}>
                <h2>Register</h2>
                <div className={styles.inputs}>
                    <div className={styles.merchantNameInput}>
                        <input 
                            type="text" 
                            value={merchantName} 
                            onChange={handleMerchantNameChange} 
                            required 
                            className={`${styles.input} ${message === "Merchant/Company Name is already connected to a user" ? styles.errorInput : ''}`} 
                            placeholder="Merchant/Company Name"
                        />
                        {message === "Merchant/Company Name is already connected to a user" && <p className={styles.errorMessage}>Merchant/Company Name is already in use</p>}
                    </div>
                    <div className={styles.emailInput}>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={handleEmailChange} 
                            required 
                            className={`${styles.input} ${message === "Email is already connected to a user" ? styles.errorInput : ''}`} 
                            placeholder="Email"
                        />
                        {message === "Email is already connected to a user" && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <div className={styles.passwordInput}>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={handlePasswordChange} 
                            required 
                            className={`${styles.input} ${message.includes('Password') ? styles.errorInput : ''}`}
                            placeholder="Password"
                        />
                    </div>
                    <div className={styles.passwordInput}>
                        <input 
                            type="password" 
                            value={confirmPassword} 
                            onChange={handleConfirmPasswordChange} 
                            required 
                            className={`${styles.input} ${message === "Passwords do not match" ? styles.errorInput : ''}`}
                            placeholder="Repeat Password"
                        />
                        {message === "Passwords do not match" && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <div className={styles.termsCheckbox}>
                        <input 
                            type="checkbox" 
                            checked={termsAccepted} 
                            onChange={handleTermsChange} 
                            required 
                        />
                        <label>
                            I accept the <Link to="/terms-of-service" target="_blank">Terms of Service</Link>
                        </label>
                        {message === 'You must accept the Terms of Service' && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <button type="submit" className={styles.button} disabled={loading}>
                        {loading ? <LoadingButtonDots /> : 'Register account'}
                    </button>
                </div>
                <div className={styles.registerLoginLink}>
                    <p>Already have an account?</p><Link to="../login">Login</Link>
                </div>
            </form>
        </div>
    );
};

export default MerchantRegister;
