import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dataService from '../../services/merchantService';
import styles from './CreateRequest.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Importera LoadingDots-komponenten

const CreateRequest = () => {
    useEffect(() => {
        document.title = 'Create Sourcing Request | Qiwi';
      }, []);      

    const [formDataURL, setFormDataURL] = useState({
        url: '',
        productName: '',
        note: ''
    });

    const [formDataDetails, setFormDataDetails] = useState({
        productName: '',
        description: '',
        note: '',
        url: '',
        image: null,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTwo, setIsLoadingTwo] = useState(false);

    const navigate = useNavigate();

    const handleInputChangeURL = (e) => {
        const { name, value } = e.target;
        setFormDataURL(prev => ({ ...prev, [name]: value }));
    };

    const handleInputChangeDetails = (e) => {
        const { name, value } = e.target;
        setFormDataDetails(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        setFormDataDetails(prev => ({ ...prev, image: e.target.files[0] }));
    };

    const handleSubmitURL = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const requestData = {
                url: formDataURL.url,
                productName: formDataURL.productName,
            };
            await dataService.createSourcingRequest(requestData);
            alert('Request created successfully!');
            navigate('/sourcing');
        } catch (error) {
            console.error('Failed to create request: ', error);
            alert('Failed to create request');
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleSubmitDetails = async (e) => {
        e.preventDefault();
        setIsLoadingTwo(true);
        try {
            const formDataToSubmit = new FormData();
            formDataToSubmit.append('productName', formDataDetails.productName);
            formDataToSubmit.append('description', formDataDetails.description);
            formDataToSubmit.append('note', formDataDetails.note);
            formDataToSubmit.append('url', formDataDetails.url);
            if (formDataDetails.image) {
                formDataToSubmit.append('image', formDataDetails.image);
            }

            await dataService.createSourcingRequestWithImage(formDataToSubmit);
            alert('Detailed request created successfully!');
            navigate('/sourcing');
        } catch (error) {
            console.error('Failed to create request with details: ', error);
            alert('Failed to create request with details');
        } finally {
            setIsLoadingTwo(false);
        }
    };

    const back = () => {
        navigate('/sourcing');
    };

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <h1>Create Request</h1>
                <button onClick={back} className={styles.backButton}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to requests
                </button>
            </div>
            <div className={styles.forms}>
                <form onSubmit={handleSubmitURL} className={styles.formContainer}>
                    <h2>Submit with URL</h2>
                    <label htmlFor="url"><span style={{ color: 'red' }}>*</span>URL:
                    <input
                        type="text"
                        id="url"
                        name="url"
                        value={formDataURL.url}
                        onChange={handleInputChangeURL}
                        required
                    />
                    </label>
                    <label htmlFor="productName"><span style={{ color: 'red' }}>*</span>Product Name:
                    <input
                        type="text"
                        id="productName"
                        name="productName"
                        value={formDataURL.productName}
                        onChange={handleInputChangeURL}
                        required
                    />
                    </label>
                    <label htmlFor="productName">Note:
                    <textarea
                        type="text"
                        id="note"
                        name="note"
                        value={formDataURL.note}
                        onChange={handleInputChangeURL}
                        placeholder={"E.g. variants"}
                    />
                    </label>
                    <button type="submit" className={styles.submitButton} disabled={isLoading}>
                        {isLoading ? <LoadingButtonDots /> : 'Submit Request'}
                    </button>
                </form>
                <form onSubmit={handleSubmitDetails} className={styles.detailsContainer}>
                    <h2>Submit with Details</h2>
                    <label htmlFor="image"><span style={{ color: 'red' }}>*</span>Image:
                    <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        id="image"
                        onChange={handleFileChange}
                    />
                    </label>
                    <label htmlFor="productName"><span style={{ color: 'red' }}>*</span>Product Name:
                    <input
                        type="text"
                        id="productName"
                        name="productName"
                        value={formDataDetails.productName}
                        onChange={handleInputChangeDetails}
                        required
                    />
                    </label>
                    <label htmlFor="description"><span style={{ color: 'red' }}>*</span>Description:
                    <textarea
                        id="description"
                        name="description"
                        value={formDataDetails.description}
                        onChange={handleInputChangeDetails}
                        required
                    />
                    </label>
                    <label htmlFor="url">URL:
                    <input
                        id="url"
                        name="url"
                        value={formDataDetails.url}
                        onChange={handleInputChangeDetails}
                    />
                    </label>
                    <label htmlFor="note">Note:
                    <textarea
                        id="note"
                        name="note"
                        value={formDataDetails.note}
                        onChange={handleInputChangeDetails}
                        placeholder={"E.g. variants"}
                    />
                    </label>
                    <button type="submit" className={styles.submitButton} disabled={isLoading}>
                        {isLoadingTwo ? <LoadingButtonDots /> : 'Submit Request with Details'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateRequest;
