import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

const createSupplier = async (formData) => {
    try {
        const response = await api.post('suppliers/create', formData);
        return response;
    } catch (error) {
        console.error('Failed to create supplier:', error);
        throw error;
    }
};

const fetchRequestsByPageAndFilters = async (statusFilter, currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived) => {
    try {
        const response = await api.get("/sourcing-admin/fetchSourcingRequestsByPageAndFilters", {
            params: {
                showArchived,
                statusFilter,
                currentPage,
                itemsPerPage,
                searchQuery,
                startDate,
                endDate
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching sourcing requests:', error);
        throw error;
    }
};

const fetchUsersByPageAndFilters = async (roleFilter, currentPage, itemsPerPage, startDate, endDate, showArchived) => {
    try {
        const response = await api.get("user/fetchUsersByPageAndFilters", {
            params: {
                roleFilter,
                currentPage,
                itemsPerPage,
                startDate,
                endDate,
                showArchived
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

const fetchMerchantsByPageAndFilters = async (currentPage, itemsPerPage, startDate, endDate) => {
    try {
        const response = await api.get("user/fetchMerchantsByPageAndFilters", {
            params: {
                currentPage,
                itemsPerPage,
                startDate,
                endDate
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching merchants:', error);
        throw error;
    }
};

const fetchSuppliersByPageAndFilters = async (currentPage, itemsPerPage, startDate, endDate) => {
    try {
        const response = await api.get("user/fetchSuppliersByPageAndFilters", {
            params: {
                currentPage,
                itemsPerPage,
                startDate,
                endDate
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching suppliers:', error);
        throw error;
    }
};

const fetchStoresByPageAndFilters = async (statusFilter, currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived) => {
    try {
        const response = await api.get("store/fetchAllStoresByPageAndFilters", {
            params: {
                statusFilter,
                currentPage,
                itemsPerPage,
                searchQuery,
                startDate,
                endDate,
                showArchived
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching stores:', error);
        throw error;
    }
};

const fetchProductsByPageAndFilters = async (currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived) => {
    try {
        const response = await api.get("product/fetchProductsByPageAndFilters", {
            params: {
                currentPage,
                itemsPerPage,
                searchQuery,
                startDate,
                endDate,
                showArchived
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

const updateUser = async (userId, userData) => {
    try {
        const response = await api.put(`user/update/${userId}`, userData);
        return response.data;
    } catch (error) {
        console.error('Failed to update user:', error);
        throw error;
    }
};

const updateStore = async (storeId, storeData) => {
    try {
        const response = await api.put(`store/update/${storeId}`, storeData);
        return response.data;
    } catch (error) {
        console.error('Failed to update store:', error);
        throw error;
    }
};

const updateOrder = async (orderId, orderData) => {
    try {
        const response = await api.put(`order/update/${orderId}`, orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to update order:', error);
        throw error;
    }
};

const deleteOrder = async (orderId) => {
    try {
        const response = await api.delete(`order/delete/${orderId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete order:', error);
        throw error;
    }
};

const updateProduct = async (productId, productData) => {
    try {
        const response = await api.put(`product/update/${productId}`, productData);
        return response.data;
    } catch (error) {
        console.error('Failed to update product:', error);
        throw error;
    }
};

const fetchAllOrdersByPageAndFilters = async (statusFilter, currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived) => {
    try {
        const response = await api.get("orders/fetchAllOrdersByPageAndFilters", {
            params: {
                status: statusFilter,
                pageNumber: currentPage,
                pageAmount: itemsPerPage,
                searchQuery,
                startDate,
                endDate,
                showArchived
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};

const fetchInvoicesAwaitingConfirmation = async () => {
    try {
        const response = await api.get('invoice/fetchInvoicesAwaitingConfirmation');
        return response.data;
    } catch (error) {
        console.error('Error fetching invoices awaiting confirmation:', error);
        throw error;
    }
};

const getTransactionProof = (filename) => {
    return `${API_URL}invoice/getTransactionProof/${filename}`;
};

const confirmPayment = async (invoiceId) => {
    try {
        const response = await api.put(`invoice/confirmPayment/${invoiceId}`, {});
        return response.data;
    } catch (error) {
        console.error('Error confirming payment:', error);
        throw error;
    }
};

const denyPayment = async (invoiceId) => {
    try {
        const response = await api.put(`invoice/denyPayment/${invoiceId}`, {});
        return response.data;
    } catch (error) {
        console.error('Error denying payment:', error);
        throw error;
    }
};

const fetchAllInvoicesByPageAndFilters = async (statusFilter, currentPage, itemsPerPage, startDate, endDate, searchQuery) => {
    try {
        const response = await api.get('invoice/fetchAllInvoicesByPageAndFilters', {
            params: { statusFilter, currentPage, itemsPerPage, startDate, endDate, searchQuery }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching invoices:', error);
        throw error;
    }
};

const updateInvoice = async (invoiceId, status) => {
    try {
        const response = await api.put(`invoice/update/${invoiceId}`, { status });
        return response.data;
    } catch (error) {
        console.error('Failed to update invoice:', error);
        throw error;
    }
};

const deleteInvoice = async (invoiceId) => {
    try {
        const response = await api.delete(`invoice/${invoiceId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete invoice:', error);
        throw error;
    }
};

const fetchQuotationDetails = async (requestId, showArchived) => {
    try {
        const response = await api.get(`/sourcing-admin/fetchQuotationDetails/`, {
            params: {
                showArchived,
                requestId,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching quotation details:', error);
        throw error;
    }
};

const setMarkup = async (quotationId, variants) => {
    try {
        const response = await api.post('/sourcing-admin/setMarkup', {
            quotationId,
            variants
        });
        return response.data;
    } catch (error) {
        console.error('Error setting markup:', error);
        throw error;
    }
};

const setMarkupNoVariant = async (quotationId, markup) => {
    try {
        const response = await api.post('/sourcing-admin/setMarkupNoVariant', {
            quotationId,
            markup
        });
        return response.data;
    } catch (error) {
        console.error('Error setting markup:', error);
        throw error;
    }
};

const giveFeedback = async (quotationId, feedback) => {
    try {
        const response = await api.post('/sourcing-admin/giveFeedback', {
            quotationId,
            feedback
        });
        return response.data;
    } catch (error) {
        console.error('Error giving feedback:', error);
        throw error;
    }
};

const submitQuotations = async (requestId, selectedQuotations) => {
    try {
        const response = await api.post('/sourcing-admin/submitQuotations', {
            requestId,
            selectedQuotations
        });
        return response.data;
    } catch (error) {
        console.error('Error submitting quotations:', error);
        throw error;
    }
};

const fetchSupportTickets = async (filter, currentPage, itemsPerPage, searchQuery, startDate, endDate) => {
    try {
        const response = await api.get('user/fetchTicketsToAdmin', {
            params: {
                filter,
                currentPage,
                itemsPerPage,
                searchQuery,
                startDate,
                endDate,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user tickets:', error);
        throw error;
    }
};

const setTicketViewed = async (ticketId) => {
    try {
        const response = await api.put(`user/setTicketViewed`, { ticketId });
        return response.data;
    } catch (error) {
        console.error('Failed to set ticket as viewed:', error);
        throw error;
    }
};

const fetchMerchants = async () => {
    try {
        const response = await api.get(`user/fetchMerchants/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching users and stores:', error);
        throw error;
    }
};

const fetchMerchantStores = async (merchantId) => {
    try {
        const response = await api.get(`store/fetchMerchantStores/${merchantId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user stores:', error);
        throw error;
    }
};

const syncShopifyOrders = async (selectedStore, selectedMerchant) => {
    try {
        const response = await api.post('/orders/syncShopifyOrders', {
            selectedStore, selectedMerchant
        });
        return response.data;
    } catch (error) {
        console.error('Error syncing Shopify orders:', error);
        throw error;
    }
};

const fetchOrderItems = async (orderId, showArchived) => {
    try {
        const response = await api.get(`orders/fetchOrderItems/`, {
            params: {
                showArchived,
                orderId,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch order items:', error);
        throw error;
    }
};

const fetchMerchantUsers = async (merchantId, showArchived) => {
    try {
        const response = await api.get(`user/fetchMerchantUsers/`, {
            params: {
                showArchived,
                merchantId,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch merchant users:', error);
        throw error;
    }
};

const fetchSupplierUsers = async (supplierId, showArchived) => {
    try {
        const response = await api.get(`user/fetchSupplierUsers/`, {
            params: {
                showArchived,
                supplierId,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch supplier users:', error);
        throw error;
    }
};

const fetchInvoiceDetails = async (invoiceId, showArchived) => {
    try {
        const response = await api.get(`invoice/fetchInvoiceDetails/${invoiceId}`, {
            params: {
                showArchived
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch invoice details:', error);
        throw error;
    }
};

const cancelItem = async (itemId) => {
    try {
        const response = await api.delete(`orders/cancelItem/${itemId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to cancel item:', error);
        throw error;
    }
};

const cancelOrder = async (orderId) => {
    try {
        const response = await api.delete(`orders/cancelOrder/${orderId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to cancel order:', error);
        throw error;
    }
};

const productDeleteStatus = async (productId) => {
    try {
        const response = await api.get(`product/productDeleteStatus/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to get product delete status:', error);
        throw error;
    }
};

const storeDeleteStatus = async (storeId) => {
    try {
        const response = await api.get(`store/storeDeleteStatus/${storeId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to get store delete status:', error);
        throw error;
    }
};

const supplierDeleteStatus = async (supplierId) => {
    try {
        const response = await api.get(`suppliers/supplierDeleteStatus/${supplierId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to get supplier delete status:', error);
        throw error;
    }
};


const merchantDeleteStatus = async (merchantId) => {
    try {
        const response = await api.get(`user/merchantDeleteStatus/${merchantId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to get merchant delete status:', error);
        throw error;
    }
};

const productDelete = async (productId) => {
    try {
        const response = await api.delete(`product/productDelete/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete product:', error);
        throw error;
    }
};

const expireProduct = async (productId) => {
    try {
        const response = await api.get(`product/expireProduct/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to expire product:', error);
        throw error;
    }
};

const storeDelete = async (storeId) => {
    try {
        const response = await api.delete(`store/delete/${storeId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete store:', error);
        throw error;
    }
};

const deleteUser = async (userId) => {
    try {
        const response = await api.delete(`user/delete/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete user:', error);
        throw error;
    }
};

const deleteSupplier = async (supplierId) => {
    try {
        const response = await api.delete(`suppliers/deleteSupplier/${supplierId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete supplier:', error);
        throw error;
    }
};

const deleteMerchant = async (merchantId) => {
    try {
        const response = await api.delete(`user/deleteMerchant/${merchantId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to delete merchant:', error);
        throw error;
    }
};

const expireSupplier = async (supplierId) => {
    try {
        const response = await api.get(`suppliers/expireSupplier/${supplierId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to expire supplier:', error);
        throw error;
    }
};

const expireMerchant = async (merchantId) => {
    try {
        const response = await api.get(`user/expireMerchant/${merchantId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to expire merchant:', error);
        throw error;
    }
};

const expireStore = async (storeId) => {
    try {
        const response = await api.get(`store/expireStore/${storeId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to expire store:', error);
        throw error;
    }
};

const archiveRequest = async (requestId) => {
    try {
        const response = await api.delete(`sourcing-admin/archiveSourcing/${requestId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to archive request:', error);
        throw error;
    }
};

const fetchMerchantsAndSuppliers = async () => {
    try {
        const response = await api.get("user/fetchMerchantsAndSuppliers");
        return response.data;
    } catch (error) {
        console.error('Failed to fetch suppliers and merchants:', error);
        throw error;
    }
};

const createUser = async (formData) => {
    try {
        const response = await api.post("user/createUser", formData);
        return response.data;
    } catch (error) {
        console.error('Failed to create User:', error);
        throw error.response ? new Error(error.response.data.message) : new Error('Failed to create user');
    }
};

const generateInvoices = async () => {
    try {
        const response = await api.get("invoice/generateInvoices");
        return response.data;
    } catch (error) {
        console.error('Failed to generate invoices:', error);
        throw error.response ? new Error(error.response.data.message) : new Error('Failed to generate invoices');
    }
};

const confirmInvoiceSent = async (invoiceData, pdfFile) => {
    try {
        const formData = new FormData();
        formData.append('totalAmount', invoiceData.totalAmount);
        formData.append('invoiceAccountId', invoiceData.invoiceAccountId);
        invoiceData.orderIds.forEach(orderId => {
            formData.append('orderIds', orderId);
        });
        formData.append('pdf', pdfFile);
        const url = `${API_URL}invoice/confirmInvoiceSent`;
        const response = await axios.post(url, formData, {withCredentials: true}, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Failed to confirm invoice sent:', error);
        // Förbättrad felhantering för att returnera mer specifika felmeddelanden
        if (error.response && error.response.data && error.response.data.message) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error('Failed to confirm invoice sent');
        }
    }
};

export default {
    confirmInvoiceSent,
    generateInvoices,
    deleteSupplier,
    expireSupplier,
    supplierDeleteStatus,
    expireMerchant,
    deleteMerchant,
    createUser,
    fetchMerchantsAndSuppliers,
    expireStore,
    storeDelete,
    storeDeleteStatus,
    archiveRequest,
    expireProduct,
    productDelete,
    productDeleteStatus,
    cancelOrder,
    cancelItem,
    fetchInvoiceDetails,
    fetchSuppliersByPageAndFilters,
    fetchSupplierUsers,
    fetchMerchantUsers,
    fetchMerchantsByPageAndFilters,
    fetchOrderItems,
    fetchMerchantStores,
    fetchMerchants,
    syncShopifyOrders,
    setTicketViewed,
    fetchSupportTickets,
    setMarkupNoVariant,
    submitQuotations,
    setMarkup,
    giveFeedback,
    fetchQuotationDetails,
    updateInvoice,
    deleteInvoice,
    fetchAllInvoicesByPageAndFilters,
    confirmPayment,
    denyPayment,
    fetchInvoicesAwaitingConfirmation,
    getTransactionProof,
    fetchRequestsByPageAndFilters,
    createSupplier,
    fetchUsersByPageAndFilters,
    fetchStoresByPageAndFilters,
    fetchProductsByPageAndFilters,
    updateUser,
    deleteUser,
    updateStore,
    deleteOrder,
    updateOrder,
    updateProduct,
    fetchAllOrdersByPageAndFilters,
    merchantDeleteStatus
};
