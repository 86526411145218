import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Invoices.module.scss';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import LoadingSpinner from '../../components/LoadingSpinner'; // Importera LoadingSpinner-komponenten
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Importera LoadingButtonDots-komponenten

Modal.setAppElement('#root');

function Invoices() {
    useEffect(() => {
        document.title = 'Billing | Qiwi';
      }, []);

    const [invoices, setInvoices] = useState([]);
    const [totalDebt, setTotalDebt] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("all");
    const [dateRange, setDateRange] = useState([]);
    const [showDate, setShowDate] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [invoiceInfoExistence, setInvoiceInfoExistence] = useState(null);
    const [file, setFile] = useState(null);
    const [showFileModal, setShowFileModal] = useState(false);
    const [totalUnpayed, setTotalUnpayed] = useState(0);
    const [noActiveInvoices, setNoActiveInvoices] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [showArchived, setShowArchived] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);
    const [invoiceAccountLoaded, setInvoiceAccountLoaded] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false); // Ny state för laddning av detaljer
    const [submittingFile, setSubmittingFile] = useState(false); // Ny state för filuppladdning

    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Awaiting Payment', label: 'Awaiting Payment' },
        { value: 'Awaiting Payment Confirmation', label: 'Awaiting Payment Confirmation' },
        { value: 'Paid', label: 'Paid' },
        { value: 'Denied', label: 'Denied' }
    ];

    useEffect(() => {
        fetchInfo();
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage, showArchived]);

    useEffect(() => {
        const checkInvoiceInfoExistence = async () => {
            try {
                const response = await merchantService.checkInvoiceInfoExistance();
                setInvoiceInfoExistence(response);
                setInvoiceAccountLoaded(true);
            } catch (error) {
                console.error('Failed to check invoice info existence:', error);
            }
        };

        checkInvoiceInfoExistence();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const data = await merchantService.fetchInvoicesByPageAndFilters(statusFilter, currentPage, itemsPerPage, startDate, endDate, showArchived);
            setInvoices(data.invoices);
            setTotalPages(data.totalPages);

            if (initialLoad) {
                if (!data.invoices.length) {
                    setNoActiveInvoices(true);
                    setFiltersApplied(false);
                } else {
                    setNoActiveInvoices(false);
                    setFiltersApplied(true);
                }
                setInitialLoad(false);
            } else {
                setFiltersApplied(true);
                setNoActiveInvoices(!data.invoices.length);
            }
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
        }
    };

    const fetchInfo = async () => {
        try {
            setLoadingInitialInfo(true)
            const data = await merchantService.fetchInvoiceInfo();
            setTotalUnpayed(data.totalAmount);
            setTotalDebt(data.totalDebt);
            setLoadingInitialInfo(false)
        } catch (error) {
            console.error('Failed to fetch invoice info:', error);
        }
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedInvoices = [...invoices].sort((a, b) => {
            if (a[key] === undefined) return 0; // Safety check if key doesn't exist
            if (key === 'createdAt') {
                const dateA = new Date(a[key]);
                const dateB = new Date(b[key]);
                return direction === 'ascending' ? dateA - dateB : dateB - dateA;
            }
            if (typeof a[key] === 'string') {
                return direction === 'ascending' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            }
            return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
        });

        setInvoices(sortedInvoices);
    };

    const handleFilterChange = (filter) => {
        setStatusFilter(filter);
        setCurrentPage(1);
    };

    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setInitialLoad(true);
        setCurrentPage(1);
    };

    const handleInvoiceClick = async (invoiceId) => {
        try {
            setLoadingDetails(true); // Starta laddningsindikator
            const details = await merchantService.fetchInvoiceDetails(invoiceId, showArchived);
            setInvoiceDetails(details);
            setSelectedInvoice(invoiceId);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching invoice details:', error);
        } finally {
            setLoadingDetails(false); // Stoppa laddningsindikator
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedInvoice(null);
        setInvoiceDetails({});
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleTransactionProof = (invoiceId) => {
        setSelectedInvoice(invoiceId);
        setShowFileModal(true);
    };

    const handleFileSubmit = async () => {
        if (!file) {
            alert("Please select a file before submitting."); // Meddelande till användaren
            return; // Avbryter om ingen fil har valts
        }
    
        try {
            setSubmittingFile(true); // Starta laddningsindikator för filuppladdning
            const formData = new FormData();
            formData.append('file', file);
            formData.append('invoiceId', selectedInvoice);
    
            await merchantService.createTransactionProof(formData);
            setShowFileModal(false);
            setFile(null);
            setSelectedInvoice(null);
            fetchData();
        } catch (error) {
            console.error('Error uploading transaction proof:', error);
        } finally {
            setSubmittingFile(false); // Stoppa laddningsindikator för filuppladdning
        }
    };
    

    const handleFileModalClose = () => {
        setShowFileModal(false);
        setFile(null);
        setSelectedInvoice(null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Billing</h1>
                {!invoiceInfoExistence && invoiceAccountLoaded && (
                    <button className={styles.addInvoiceInfo}>
                        <Link to="/settings" className={styles.linkButton}>
                            <span className="material-symbols-outlined">warning</span>
                            No Billing Information Found, Add Now
                        </Link>
                    </button>
                )}
            </div>

            <div className={styles.infoContainers}>
                <div className={`${styles.infobox} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {!loadingInitialInfo && (
                        <>
                            <p>Debt</p>
                            <h2>{totalDebt}$</h2>
                        </>
                    )}
                </div>
                <div className={`${styles.infobox} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {!loadingInitialInfo && (
                        <>
                            <p>Unpaid Invoices</p>
                            <h2>{totalUnpayed}</h2>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.top}>
                <FilterComponent
                    statusFilter={statusFilter}
                    setSelectFilter={handleFilterChange}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    fetchData={fetchData}
                    statusOptions={statusOptions}
                    showDate={showDate}
                    includeSearch={false}
                    setShowDate={setShowDate}
                    selectLabel="Status"
                    showArchived={showArchived}
                    setShowArchived={handleShowArchivedToggle}
                    applyFilters={fetchData}
                    dataType="Invoices"
                    filtersDisabled={(!filtersApplied && noActiveInvoices)}
                />
            </div>
            {totalPages > 0 ? (
                <>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('totalAmount')}>
                                        <div className={styles.thHeader}>
                                            Total Amount
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'totalAmount' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'totalAmount' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Invoice</th>
                                    <th onClick={() => handleSort('createdAt')}>
                                        <div className={styles.thHeader}>
                                            Date Issued
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('status')}>
                                        <div className={styles.thHeader}>
                                            Status
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'status' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'status' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading
                                    ? Array.from({ length: 5 }).map((_, index) => (
                                        <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                            <td colSpan={5}></td>
                                        </tr>
                                    ))
                                    : invoices.map((invoice) => (
                                        <tr key={invoice.invoiceId} className={styles.rows}>
                                            <td>${invoice.totalAmount.toFixed(2)}</td>
                                            <td>
                                                {invoice.fileName && (
                                                    <a href={merchantService.getInvoiceFile(invoice.fileName)} target="_blank" rel="noopener noreferrer">
                                                        Show Invoice
                                                    </a>
                                                )}
                                            </td>
                                            <td>{new Date(invoice.createdAt).toLocaleDateString()}</td>
                                            <td>{invoice.status}</td>
                                            <td className={styles.buttons}>
                                                <button onClick={() => handleInvoiceClick(invoice.invoiceId)} className={styles.detailsButton}>Details</button>
                                                {((invoice.status === 'Awaiting Payment' && !invoice.invoiceProof) || invoice.status === 'Denied') && (
                                                    <button onClick={() => handleTransactionProof(invoice.invoiceId)} className={styles.paymentButton} title="För snabbare leverans av leverantörer, skicka transaktionsbevis.">
                                                        Transaction
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                    <Modal
                        isOpen={showModal}
                        onRequestClose={handleCloseModal}
                        className={styles.modal}
                        overlayClassName={styles.overlay}
                    >
                        <div className={`${styles.detailModalContent}`}>
                        <h4>Invoice Details for Invoice ID: {selectedInvoice}</h4>
                            {loadingDetails ? (
                                <div className={styles.spinner}><LoadingSpinner /></div>
                            ) : (
                                <>  
                                    <div className={styles.invoiceOverview}>
                                    <p><strong>Total Amount:</strong> ${invoiceDetails.totalAmount?.toFixed(2)}</p>
                                    <p><strong>Date Issued:</strong> {new Date(invoiceDetails.createdAt).toLocaleDateString()}</p>
                                    <p><strong>Status:</strong> {invoiceDetails.status}</p>
                                    </div>
                                    <div className={styles.detailsInfo}>
                                        {invoiceDetails.orders && invoiceDetails.orders.map((order, index) => (
                                            <div key={index} className={styles.invoiceDetailOrder}>
                                                <h5>Order ID: {order.orderId}</h5>
                                                <div className={styles.orderOverview}>
                                                <p>Status: {order.orderStatus}</p>
                                                <p>Store: {order.storeName}</p>
                                                </div>
                                                <div className={styles.invoiceDetailOrderItems}>
                                                    {order.items.map((item, idx) => (
                                                        <div key={idx} className={styles.orderDetails}>
                                                            <p>Product: {item.productName}</p>
                                                            {item.Variant && item.Variant.length > 0 &&
                                                                item.Variant.map((cv, catIndex) => (
                                                                    <p key={catIndex}>
                                                                        {`${cv.name}: ${cv.value}`}
                                                                        {catIndex !== item.Variant.length - 1}
                                                                    </p>
                                                                ))}
                                                            <p>Quantity: {item.quantity}</p>
                                                            <p>Shipping: ${item.shippingPrice}</p>
                                                            <p>Prodct Price: ${item.productPrice}</p>
                                                            <p>Total Price: ${item.quantity * (item.productPrice + item.shippingPrice)}</p>
                                                            <p>SKU: {item.SKU}</p>
                                                            <p>Supplier: {item.supplierName}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button onClick={handleCloseModal} className={styles.closeButton}>Close</button>
                                </>
                            )}
                        </div>
                    </Modal>
                    <Modal
                        isOpen={showFileModal}
                        onRequestClose={handleFileModalClose}
                        className={styles.modal}
                        overlayClassName={styles.overlay}
                    >
                        <div className={styles.content}>
                            <h4>Upload Transaction Proof for Invoice ID: {selectedInvoice}</h4>
                            <TextField
                                type="file"
                                inputProps={{ accept: "application/pdf" }}
                                onChange={handleFileChange}
                                fullWidth
                            />
                            <div className={styles.buttonGroup}>
                                <button onClick={handleFileSubmit} className={styles.confirmButton}>
                                    {submittingFile ? <LoadingButtonDots /> : 'Confirm'}
                                </button>
                                <button onClick={handleFileModalClose} className={styles.cancelButton}>Cancel</button>
                            </div>
                        </div>
                    </Modal>
                </>
            ) : (
                showArchived ? (
                    <p className={styles.noFound}>No archived invoices found{filtersApplied && ", check filtering"}</p>
                ) : (
                    <p className={styles.noFound}>No invoices found{filtersApplied && ", check filtering"}</p>
                )
            )}
        </div>
    );
}

export default Invoices;
