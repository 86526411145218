import React, { useEffect } from 'react';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard | Qiwi';
  }, []);
  
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
      <h1>Dashboard</h1>

      </div>
      <div className={styles.widgets}>
      <div className={styles.widgetContainer}>
        <div className={styles.widget}>
          <p>In construction.</p>
        </div>
        <div className={styles.widget}>
          <p>In construction.</p>
        </div>
        <div className={styles.widget}>
          <p>In construction.</p>
        </div>
        <div className={styles.widget}>
          <p>In construction.</p>
        </div>
      </div>
      <div>
      <h2 className={styles.bigwidget}>Cooming Soon</h2>
      </div>
      </div>
      </div>
  );
};

export default Dashboard;
